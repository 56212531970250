import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import UserGroupOptions from 'views/user-groups/UserGroupOptions';
import AddJobs from 'views/jobs/AddJobs';
import WorkerDetails from 'views/workers/workers/WorkerDetails';
import Workplaces from 'views/workplaces/workplaces/Workplaces';
import WorkplaceTypes from 'views/workplaces/workplace-types/WorkplacesTypes';
import AddWorkplace from 'views/workplaces/workplaces/AddWorkplace';
import JobsList from 'views/jobs/JobsList';
import JobDetails from 'views/jobs/JobDetails';
import MessageDetails from 'views/message-dashboard/MessageDetails';

// sample page routing
const UnderConstruction = Loadable(lazy(() => import('views/under-construction')));
const MessageDashboard = Loadable(lazy(() => import('views/message-dashboard')));
const Users = Loadable(lazy(() => import('views/users/Users')));
const UserGroups = Loadable(lazy(() => import('views/user-groups/UserGroups')));
const CandidatesList = Loadable(lazy(() => import('views/jobs/CandidatesList')));
const WorkerTypes = Loadable(lazy(() => import('views/workers/worker-types/WorkerTypes')));
const Workers = Loadable(lazy(() => import('views/workers/workers/Workers')));
const WorkerApprovals = Loadable(lazy(() => import('views/workers/approvings/WorkerApprovals')));
const Authorities = Loadable(lazy(() => import('views/authorities/Authorities')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <UnderConstruction />
        },
        {
            path: '/users',
            element: <Users />
        },
        {
            path: '/user-groups',
            element: <UserGroups />
        },
        {
            path: '/user-groups/add',
            element: <UserGroupOptions isEdit={false} />
        },
        {
            path: '/user-groups/:id',
            element: <UserGroupOptions isEdit />
        },
        {
            path: '/jobs/candidates-list',
            element: <CandidatesList />
        },
        {
            path: '/jobs/list',
            element: <JobsList />
        },
        {
            path: '/jobs/add',
            element: <AddJobs />
        },
        {
            path: '/jobs/:jobId',
            element: <JobDetails />
        },
        {
            path: '/jobs/:jobId/:rowId',
            element: <JobDetails />
        },
        {
            path: '/worker-types',
            element: <WorkerTypes />
        },
        {
            path: '/workers',
            element: <Workers />
        },
        {
            path: '/workers/:workerId',
            element: <WorkerDetails />
        },
        {
            path: '/worker-approvals',
            element: <WorkerApprovals />
        },
        {
            path: '/authorities',
            element: <Authorities />
        },
        {
            path: '/workplaces',
            element: <Workplaces />
        },
        {
            path: '/workplaces/add',
            element: <AddWorkplace />
        },
        {
            path: '/workplaces/:id',
            element: <AddWorkplace />
        },
        {
            path: '/workplace-types',
            element: <WorkplaceTypes />
        },
        {
            path: '/messages-dashboard',
            element: <MessageDashboard />
        },
        {
            path: '/messages-dashboard/:typeId',
            element: <MessageDetails />
        }
    ]
};

export default MainRoutes;
