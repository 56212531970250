import { styled, Box, Typography, Grid, TextField, Button, Autocomplete, CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import { ArrowBack, ArrowDropDown } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { UserProps, PermissionsProps } from 'types/user-group';
import { getAvailableWorkplaces, getPreview, saveJob } from 'services/JobService';
import { getAuthoritiesList, getWorkplacesByAuthority } from 'services/ListService';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/he';

export interface editProps {
    groupName: string;
    users: UserProps[] | undefined;
    permissions: PermissionsProps[];
}

const AddJobs = () => {
    const [jobDate, setJobDate] = useState<Dayjs | null>(null);
    const navigate = useNavigate();
    const [success, setSuccess] = useState<boolean>();
    // const [availableWorkplacesList, setAvailableWorkplacesList] = useState<{ id: number; name: string }[]>([]);
    const [job, setJob] = useState<{ job_date: Dayjs | null; workplaces: any[] }>({ job_date: null, workplaces: [] });
    const [preview, setPreview] = useState<{ workers_count: number }>({ workers_count: 0 });
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [availableWorkplacesListForAuthority, setAvailableWorkplacesListForAuthority] = useState<{ id: string; label: string }[]>([]);
    const [availableAuthorityList, setAvailableAuthorityList] = useState<{ id: string; label: string }[]>([]);
    const [Auth, setAuth] = useState<{ id: string; label: string } | null>(null);

    useEffect(() => {
        // getAvailableWorkplaces(setAvailableWorkplacesList);
        getAuthoritiesList(setAvailableAuthorityList);
    }, []);
    useEffect(() => {
        console.log('auth', Auth);
        if (Auth) getWorkplacesByAuthority(setAvailableWorkplacesListForAuthority, Number(Auth.id));
    }, [Auth]);

    const setSelectedAuthoFromSingleSelect = (value: { id: string; label: string } | null) => {
        setAuth({ id: value?.id ? value.id : '', label: value?.label ? value.label : '' });
    };

    const setSelectedValuesFromMultiselect = async (selectedValues: { id: string; label: string }[], field: string) => {
        const arr: any[] = [];
        selectedValues.forEach((x) => {
            arr.push({ id: x.id, name: x.label });
        });
        setJob({ ...job, [field]: arr });
    };

    const theme = useTheme();

    const H3 = styled(Typography)({
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '19px',
        letterSpacing: '0em',
        textAlign: 'left'
    });

    const ButtonIconStyle = styled(Button)({
        '&:hover': {
            border: 'none'
        },
        border: 'none',
        padding: '12px, 16px, 12px, 16px',
        fontSize: '14px',
        fontWeight: 500
    });

    const ButtonStyle = styled(Button)({
        height: '44px',
        width: '111px',
        borderRadius: '4px',
        padding: '12px, 16px, 12px, 16px',
        fontSize: '14px',
        fontWeight: 500,
        color: `${theme.palette.primary.contrastText}`
    });

    const saveJobDetails = async () => {
        setIsSaving(true);
        const response = await saveJob(job);
        if (response) {
            setSuccess(true);
        }
    };

    const CustomMainCard = styled(MainCard)({
        padding: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            width: '100% !important'
        }
    });

    useEffect(() => {
        if (success !== undefined && success === true) navigate('/jobs/list');
    }, [navigate, success]);

    useEffect(() => {
        if (job.job_date) getPreview(setPreview, { job_date: job.job_date, workplaces: job.workplaces });
    }, [job]);

    return (
        <>
            <MainCard
                border
                title={
                    <H3 variant="h3">
                        <FormattedMessage id="job-add" />
                    </H3>
                }
            >
                <CustomMainCard
                    title={
                        <Typography variant="subtitle1">
                            <FormattedMessage id="job-details" />
                        </Typography>
                    }
                    border
                    sx={{ width: '50%' }}
                    headerSX={{ padding: 1.5 }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} locale="he">
                                <DatePicker
                                    components={{
                                        OpenPickerIcon: ArrowDropDown
                                    }}
                                    label={<FormattedMessage id="job-date" />}
                                    value={jobDate}
                                    minDate={dayjs()}
                                    onChange={(newJobDate: Dayjs | null) => {
                                        setJobDate(newJobDate);
                                        setJob((j) => ({ ...j, job_date: newJobDate }));
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{
                                                input: { color: theme.palette.secondary.dark }
                                            }}
                                            {...params}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                limitTags={2}
                                id="single-autho-autho"
                                options={availableAuthorityList}
                                getOptionLabel={(option) => option.label}
                                value={Auth}
                                renderInput={(params) => <TextField {...params} label={<FormattedMessage id="authorities" />} />}
                                fullWidth
                                onChange={(e, value) => setSelectedAuthoFromSingleSelect(value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                multiple
                                limitTags={2}
                                id="multiple-usg-usg"
                                options={availableWorkplacesListForAuthority}
                                getOptionLabel={(option) => option.label}
                                value={availableWorkplacesListForAuthority
                                    .filter((t) => job.workplaces.map((x) => x.id).includes(t.id))
                                    .map((x) => x)}
                                renderInput={(params) => <TextField {...params} label={<FormattedMessage id="workplaces" />} />}
                                fullWidth
                                onChange={(e, value) => setSelectedValuesFromMultiselect(value, 'workplaces')}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Typography color={theme.palette.secondary.dark}>
                                {preview.workers_count} &nbsp;
                                <FormattedMessage id="recipients" />
                            </Typography>
                        </Grid>
                    </Grid>
                </CustomMainCard>
                <Box sx={{ marginTop: '92px', display: 'flex', justifyContent: 'space-between' }}>
                    <ButtonIconStyle
                        color="secondary"
                        onClick={() => navigate('/messages-dashboard')}
                        variant="outlined"
                        startIcon={<ArrowBack />}
                    >
                        <FormattedMessage id="back" />
                    </ButtonIconStyle>
                    <ButtonStyle
                        color="secondary"
                        variant="contained"
                        disabled={preview.workers_count === null || preview.workers_count < 1 || isSaving}
                        onClick={() => saveJobDetails()}
                    >
                        {isSaving ? <CircularProgress size={24} color="inherit" /> : <FormattedMessage id="save" />}
                    </ButtonStyle>
                </Box>
            </MainCard>
        </>
    );
};
export default AddJobs;
