import { styled, IconButton } from '@mui/material';
import { ModeEditTwoTone, BlockOutlined } from '@mui/icons-material';

export interface GlobalActionProps {
    setEdit?: (e: any) => void;
    setDisable?: (value: boolean) => void;
    marginBetween?: string;
}

const GlobalAction = (props: GlobalActionProps) => {
    const { setEdit = undefined, setDisable = undefined, marginBetween } = props;

    const EditIcon = styled(ModeEditTwoTone)({
        height: '18px',
        width: '18px'
    });

    const BlockIcon = styled(BlockOutlined)({
        height: '18px',
        width: '18px'
    });

    return (
        <>
            {setEdit !== undefined && (
                <IconButton
                    onClick={(e) => setEdit(e)}
                    color="secondary"
                    sx={{ height: 'fit-content', marginRight: marginBetween || '20px' }}
                >
                    <EditIcon />
                </IconButton>
            )}
            {setDisable !== undefined && (
                <IconButton onClick={() => setDisable(true)} color="error">
                    <BlockIcon />
                </IconButton>
            )}
        </>
    );
};

export default GlobalAction;
