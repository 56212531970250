import { lazy } from 'react';

// project imports
// import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import DataCollecting from '../views/pages/landings/DataCollecting';
import CancelSmsReceiving from 'views/pages/landings/CancelSmsReceiving';
import JobObligation from 'views/pages/landings/JobObligation';
import SignUp from 'views/pages/auth/SignUp';
import { Navigate } from 'react-router-dom';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Login3')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/ForgotPassword3')));
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/ResetPassword3')));
const ApplyJob = Loadable(lazy(() => import('views/pages/landings/ApplyJob')));
const JobStatus = Loadable(lazy(() => import('views/pages/landings/JobStatus')));
const RemoveUser = Loadable(lazy(() => import('views/pages/landings/RemoveUser')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            {/* <GuestGuard> */}
            <MinimalLayout />
            {/* </GuestGuard> */}
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/forgot-password',
            element: <AuthForgotPassword />
        },
        {
            path: '/reset-password',
            element: <ResetPassword />
        },
        {
            path: '/offer-apply',
            element: <ApplyJob />
        },
        {
            path: '/remove-user',
            element: <RemoveUser />
        },
        {
            path: '/job-status',
            element: <JobStatus />
        },
        {
            path: '/data-completion',
            element: <Navigate to="/personal-area" replace />
            /* element: <DataCollecting /> */
        },
        {
            path: '/sign-up',
            element: <Navigate to="/personal-area" replace />
            /* element: <SignUp /> */
        },
        {
            path: '/worker-sms-cancel',
            element: <Navigate to="/personal-area" replace />
            /* element: <CancelSmsReceiving /> */
        },
        {
            path: '/job-obligation',
            element: <JobObligation />
        }
    ]
};

export default LoginRoutes;
