import { useRef, useEffect } from 'react';

export const useUnload = (fn: any, changed: boolean = false) => {
    const cb = useRef(fn);

    useEffect(() => {
        cb.current = fn;
    }, [fn]);

    useEffect(() => {
        const onUnload = cb.current;

        if (changed) {
            window.addEventListener('beforeunload', onUnload);
            window.addEventListener('unload', onUnload);
        }

        return () => {
            window.removeEventListener('beforeunload', onUnload);
            window.removeEventListener('unload', onUnload);
        };
    }, [changed]);
};

export default useUnload;
