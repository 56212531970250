import axios from 'axios';
import { Worker, Comment } from 'types/worker';
import { JobReport } from 'types/job';
import { format } from 'date-fns';

export const getPersonalDetails = async (setWorker: (value: Worker) => void) => {
    try {
        const response = await axios.get(`/worker-area/details`);
        setWorker(response.data.worker);
    } catch (err) {
        console.error(`Error getting personal area details`, err);
    }
};

export interface StringID {
    name: string;
    id: number;
}

export interface DaysList {
    day: number;
    hours: StringID[];
}

export const setPersonalDetails = async (worker: Worker, selectedAuthoritiesList: StringID[], arrAvalablities: DaysList[] | undefined) => {
    try {
        const body = {
            workplace_types: worker.workplace_types,
            age_ranges: worker.age_ranges,
            address_street: worker.address_street,
            address_home_number: worker.address_home_number,
            gender: worker.gender,
            occupation: worker.occupation.id,
            worker_occupation_id: worker?.occupation.id,
            receiving_sms: worker.receiving_sms,
            birth_date: format(new Date(worker.birth_date), 'yyyy-MM-dd'),
            user: {
                first_name: worker.user.first_name,
                last_name: worker.user.last_name
            },
            email: worker.user.email,
            phone: worker.user.phone_number,
            details: {
                highschool_graduated: worker.details?.highschool_graduated,
                graduated_in_israel: worker.details?.graduated_in_israel,
                previous_experience: worker.details?.previous_experience,
                ...(worker.details?.graduated_in_israel === 1 && { highschool_name: worker.details?.highschool_name }),
                ...(worker.details?.graduated_in_israel === 1 && { highschool_city: worker.details?.highschool_city }),
                ...(worker.details?.previous_experience_story && { previous_experience_story: worker.details?.previous_experience_story }),
                kosher_phone: worker.details?.kosher_phone,
                rest_day: worker.details?.rest_day ? worker.details?.rest_day : 'שבת'
            },
            address_local_authority: worker.address_authority?.id,
            authority_region: worker.regions.map((r) => ({ id: r.id, name: r.name })),
            authorities: selectedAuthoritiesList.map((r) => ({ id: r.id })),
            availability: arrAvalablities,
            languages: worker.languages
        };
        await axios.put(`/worker-area/details`, body);
    } catch (err) {
        console.error(`Error updating personal area details`, err);
        throw err;
    }
};

export const getPersonalComments = async (setComments: (val: Comment[]) => void) => {
    try {
        const response = await axios.get(`/worker-area/comments`);
        setComments(response.data.comments);
    } catch (err) {
        setComments([]);
        console.error(`Error getting personal area comments ${err}`);
    }
};

export const getReportByFilters = async (
    month: number,
    year: number,
    approveHour: boolean | null,
    setReportData: (data: JobReport[]) => void
) => {
    try {
        const body = {
            month,
            year,
            approved: approveHour
        };
        const response = await axios.post('/report/workers', body);
        setReportData(response.data);
    } catch (err) {
        console.error(`Error getting personal area hours types ${err}`);
    }
};

export const getAuthorityReport = async (
    month: number,
    year: number,
    approveHour: boolean | null,
    setReportData: (data: JobReport[]) => void,
    authority_id: number | null
) => {
    try {
        const body = {
            month,
            year,
            approved: approveHour,
            authority_id
        };
        const response = await axios.post('/report/authorities', body);
        setReportData(response.data);
    } catch (err) {
        console.error(`Error getting personal area authority repoort info ${err}`);
    }
};

export const downloadPDFReport = async (month: number, year: number, approveHour: boolean | null) => {
    try {
        const response = await axios.get(
            `/report/workers/export?approved=${approveHour}&year=${year}&month=${month}&Access-Control-Allow-Origin=*`,
            { responseType: 'blob' }
        );
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        console.log(response);
    } catch (err) {
        console.error(`Error in downloand report ${err}`);
    }
};

export const downloadWorkerPDFReport = async (month: number, year: number, approveHour: boolean | null, authority_id: number) => {
    try {
        const response = await axios.get(
            `/report/worker-export?approved=${approveHour}&year=${year}&month=${month}&authority_id=${authority_id}&Access-Control-Allow-Origin=*`,
            { responseType: 'blob' }
        );
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        console.log(response);
    } catch (err) {
        console.error(`Error in downloand report ${err}`);
    }
};

export default getPersonalDetails;
