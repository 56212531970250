// types
import { FormatShapes } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [
        // {
        //     id: 'job-history',
        //     title: <FormattedMessage id="my-jobs" />,
        //     type: 'item',
        //     url: '/personal-area/job-history'
        // },
        {
            id: 'update-details',
            title: <FormattedMessage id="update-details" />,
            type: 'item',
            url: '/personal-area/update-details'
        }
        // {
        //     id: '',
        //     title: <FormattedMessage id="hours-report" />,
        //     type: 'item',
        //     url: '/personal-area/hours-report'
        // },
        // {
        //     id: 'workers-report',
        //     title: <FormattedMessage id="workers-report" />,
        //     type: 'item',
        //     url: '/personal-area/workers-report'
        // },
        // {
        //     id: 'my-documents',
        //     title: <FormattedMessage id="my-documents" />,
        //     type: 'item',
        //     url: '/personal-area/my-documents'
        // }
    ]
};

export default menuItems;
