import { useNavigate } from 'react-router-dom';

// project imports
// import useAuth from 'hooks/useAuth';
import { GuardProps, KeyedObject } from 'types';
import { useEffect, useState } from 'react';
import { dispatch, useSelector } from 'store';
import axios from 'axios';
import { LOGOUT, SET_LOGIN } from 'store/actions';
import jwtDecode from 'jwt-decode';
import Loader from 'ui-component/Loader';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const PersonalAreaAuthGuard = ({ children }: GuardProps) => {
    const navigate = useNavigate();
    const sessionToken = localStorage.getItem('token');
    const { isLoggedIn }: any = useSelector((state) => state.auth);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (sessionToken === null) navigate('/login-personal-area');
        if (sessionToken !== null && !isLoggedIn) {
            const init = async () => {
                try {
                    if (sessionToken && verifyToken(sessionToken)) {
                        setSession(sessionToken);
                        const response = await axios.get('/profile');
                        const { user, token } = response.data;
                        dispatch({ type: SET_LOGIN, payload: { user, token } });
                        if (user) {
                            setInitialized(true);
                        }
                    } else {
                        dispatch({
                            type: LOGOUT
                        });
                    }
                } catch (err) {
                    console.error(err);
                    dispatch({
                        type: LOGOUT
                    });
                }
            };
            init();
        }
        if (isLoggedIn && !initialized) {
            setInitialized(true);
        }
    }, [sessionToken, navigate]);

    if (!initialized) {
        return <Loader />;
    }

    return children;
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('token', serviceToken);
        axios.defaults.headers.common.authorization = `Bearer ${localStorage.getItem('token')}`;
    } else {
        localStorage.removeItem('token');
        delete axios.defaults.headers.common.authorization;
    }
};

export default PersonalAreaAuthGuard;
