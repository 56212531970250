import axios from 'axios';
import { MessagesByType } from 'types/messages';
import { PaginationInfo, RequestParams } from 'types/paginationInfo';

export const getMessagesByType = async (setMessagesByType: (value: MessagesByType[]) => void, authId?: Number) => {
    try {
        let response;
        if (authId) {
            const params: any = {
                authority_id: authId
            };

            response = await axios.get('/messages-by-type', { params });
            setMessagesByType(response.data.messages);
        } else {
            response = await axios.get('/messages-by-type');
            setMessagesByType(response.data.messages);
        }
    } catch (err) {
        console.error(`error in getting users ${err}`);
    }
};

export const approveMessage: any = async (id: number) => {
    try {
        const body = {
            id
        };
        await axios.post('/message/approve', body);
    } catch (err) {
        console.error(`error in getting users ${err}`);
    }
};

export const declineMessage: any = async (id: number) => {
    try {
        const body = {
            id
        };
        await axios.post('/message/decline', body);
    } catch (err) {
        console.error(`error in getting users ${err}`);
    }
};

export const getMessages = async (
    setMessages: (value: any[]) => void,
    requestParams: RequestParams,
    setPaginationInfo: (val: PaginationInfo) => void
) => {
    try {
        const params: any = {
            page: requestParams.page,
            per_page: requestParams.per_page,
            sort: `${requestParams.sort[0].sort === 'desc' ? '-' : ''}${requestParams.sort[0].field}`
        };
        requestParams.filters?.forEach((filter) => {
            params[`filter[${filter.name}]`] = filter.value;
        });

        const response = await axios.get(`/messages`, { params });

        let availableFilters: any = [];
        response.data.messages.filters.forEach((filter: any) => {
            availableFilters = [...availableFilters, ...filter.items.options];
        });

        setPaginationInfo({
            total: response.data.messages.total,
            last_page: response.data.messages.last_page,
            per_page: response.data.messages.per_page,
            sortable_fields: availableFilters.map((a: any) => a.name),
            available_filters: response.data.messages.filters
        });

        setMessages(response.data.messages.data);
    } catch (err) {
        console.error(`Error getting message details ${err}`);
    }
};

export const getMessageTypeList = async (setTypes: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get('/messages/types/list');
        const typesData: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.message_types)) {
            typesData.push({ id: key, label: value as string });
        }
        setTypes(typesData);
    } catch (err) {
        console.error(`Error getting message types list ${err}`);
    }
};

export const saveEditedHours = async (id: number, start_hour: any, finish_hour: any) => {
    try {
        const params = {
            id,
            start_hour,
            finish_hour
        };
        await axios.put('/job/save-edited-hours', params);
        // return response;
    } catch (error) {
        console.error(`Error updating the reported hours ${error}`);
    }
};

export default getMessagesByType;
