import { Typography } from '@mui/material';

export interface TextSectionProps {
    label: string | React.ReactElement;
    content: string | React.ReactNode;
    fontSize?: string;
}

const TextSection = (props: TextSectionProps) => {
    const { label, content, fontSize = '0.771rem' } = props;

    return (
        <>
            <Typography sx={{ fontSize: { fontSize } }} variant="subtitle1">
                {label}
            </Typography>
            <Typography sx={{ fontSize: { fontSize } }} component="span" variant="body2">
                {content}
            </Typography>
        </>
    );
};
export default TextSection;
