import { useState, useEffect } from 'react';
import { Box, MenuItem, Button, Typography, styled, TextField, IconButton } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { getAuthoritiesList, getWorkplaceTypeList } from 'services/ListService';
import { updateWorkplace, getWorkplace } from 'services/WorkplaceService';
import { useUnload } from 'hooks/useUnload';
import { DeleteTwoTone } from '@mui/icons-material';
import { Workplace, WorkplaceContact } from 'types/workplace';

export interface editWorkplaceProps {
    id?: number;
    edit: boolean;
    onClose?: () => void;
    setOpenDisable?: (val: boolean) => void;
}

const EditWorkplace = (props: editWorkplaceProps) => {
    const { onClose, edit, id, setOpenDisable } = props;
    const [workplace, setWorkplace] = useState<Workplace>({
        id: 0,
        authority_id: 0,
        name: '',
        symbol: '',
        neighborhood: '',
        address: '',
        number_of_children: 0,
        amount_of_workers: '',
        workplace_type_id: 1,
        working_hour_id: 1,
        age_range_id: 1,
        workplace_schedule: '7:00-13:30',
        contacts: [],
        waze_url: '',
        map_url: '',
        notes: '',
        workplace_contact_id: 0,
        workplace_phone: ''
    });
    const [valueStatus, setValueStatus] = useState<number>(0); // 1: Data received, 2: Data changed in local, 0: None received
    const [contacts, setContacts] = useState<WorkplaceContact[]>([]);
    const [workplaceTypeList, setWorkplaceTypeList] = useState<{ id: string; label: string }[]>([]);
    const [authoritiesList, setAuthoritiesList] = useState<{ id: string; label: string }[]>([]);

    useEffect(() => {
        getWorkplaceTypeList(setWorkplaceTypeList);
        getAuthoritiesList(setAuthoritiesList);
    }, []);

    useUnload((e: any) => {
        const confirmationMessage = 'Are you sure?/';
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
    }, valueStatus === 2);

    const TitleText = styled(Typography)({
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textAlign: 'left',
        margin: 0,
        marginTop: '20px',
        marginBottom: '6.5px'
    });

    const ButtonStyle = styled(Button)(({ theme }) => ({
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        width: '390px',
        height: '44px',
        color: `${theme.palette.primary.light} !important`
    }));

    const handleSave = async () => {
        await updateWorkplace(workplace, contacts);
        onClose?.();
    };

    useEffect(() => {
        if (edit && id) getWorkplace(id.toString(), setWorkplace, setContacts);
    }, [edit, id]);

    useEffect(() => {
        if (workplace.id && valueStatus < 1) setValueStatus(1);
        if (valueStatus === 1) setValueStatus(2);
    }, [workplace, valueStatus]);

    return (
        <Box>
            <Box display="flex" justifyContent="space-between">
                <TitleText variant="h3">
                    <FormattedMessage id={edit ? 'edit-workplace' : 'add-workplace-type'} />
                </TitleText>
                {edit && (
                    <IconButton sx={{ mt: 2 }} onClick={() => setOpenDisable?.(true)}>
                        <DeleteTwoTone fontSize="small" />
                    </IconButton>
                )}
            </Box>
            <TextField
                sx={{ my: 2, pr: 2 }}
                select
                fullWidth
                label={<FormattedMessage id="local-authority" />}
                value={workplace.authority_id}
                onChange={(e) => setWorkplace({ ...workplace, authority_id: Number(e.target.value) })}
            >
                {authoritiesList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.label}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                sx={{ marginTop: '45px' }}
                id="outlined-select-currency"
                fullWidth
                label={<FormattedMessage id="workplace-name" />}
                value={workplace.name}
                onChange={(e) => setWorkplace({ ...workplace, name: e.target.value })}
            />
            <TextField
                sx={{ marginTop: '45px' }}
                id="outlined-select-currency"
                fullWidth
                label={<FormattedMessage id="address" />}
                value={workplace.address}
                onChange={(e) => setWorkplace({ ...workplace, address: e.target.value })}
            />
            {/* <TextField
                sx={{ marginTop: '45px' }}
                id="outlined-basic"
                fullWidth
                label={<FormattedMessage id="map-url" />}
                value={workplace.map_url}
                onChange={(e) => setWorkplace({ ...workplace, map_url: e.target.value })}
            />
            <TextField
                sx={{ marginTop: '45px' }}
                id="outlined-select-currency"
                fullWidth
                label={<FormattedMessage id="waze-url" />}
                value={workplace.waze_url}
                onChange={(e) => setWorkplace({ ...workplace, waze_url: e.target.value })}
            /> */}
            <TextField
                sx={{ marginTop: '45px' }}
                id="outlined-select-currency"
                fullWidth
                label={<FormattedMessage id="neighborhood" />}
                value={workplace.neighborhood}
                onChange={(e) => setWorkplace({ ...workplace, neighborhood: e.target.value })}
            />
            <TextField
                sx={{ marginTop: '45px', marginBottom: '50px' }}
                label={<FormattedMessage id="workplace-type" />}
                id="subtype-select"
                fullWidth
                InputLabelProps={{ shrink: !!workplace.workplace_type_id }}
                select
                value={workplace.workplace_type_id}
                onChange={(e) => setWorkplace({ ...workplace, workplace_type_id: Number(e.target.value) })}
            >
                {workplaceTypeList.map((p: any, index) => (
                    <MenuItem key={index} value={p.id}>
                        {p.label}
                    </MenuItem>
                ))}
            </TextField>

            <ButtonStyle onClick={handleSave} variant="contained">
                <FormattedMessage id="save" />
            </ButtonStyle>
        </Box>
    );
};
export default EditWorkplace;
