import { useState } from 'react';
import { Box, Typography, Button, styled, InputAdornment, OutlinedInput, IconButton, Checkbox } from '@mui/material';
import Table from '../common/GlobalTable';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconSearch } from '@tabler/icons';
import barcode from '../../assets/images/icons/barcode.svg';
import { GridColDef } from '@mui/x-data-grid';
import { saveJobOffers } from 'services/JobService';
import { format } from 'date-fns';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'store/index';

export interface approveJobProps {
    onClose: () => void;
    label: string;
    refreshData: () => void;
    data: any;
}

const ApproveJob = (props: approveJobProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { label, onClose, refreshData, data } = props;

    const [selectedJobOffers, setSelectedJobOffers] = useState<number[]>([]);

    const jobs: any[] = [];

    data.jobs.forEach((item: any) => {
        jobs.push({
            ...item,
            workplace_name: item.workplace.name
        });
    });

    const TitleText = styled(Typography)({
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textAlign: 'left',
        margin: 0,
        marginTop: '20px',
        marginBottom: '6.5px'
    });

    const ButtonStyle = styled(Button)(({ theme }) => ({
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        width: '390px',
        height: '44px',
        color: `${theme.palette.primary.light} !important`
    }));

    const WrapperIcon = styled(IconButton)({
        background: 'rgba(0, 0, 0, 0.18)',
        height: '32px',
        width: '32px',
        borderRadius: '12px'
    });

    const Text = styled('p')({
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center'
    });

    const saveWorkplaces = async () => {
        const response = await saveJobOffers(selectedJobOffers, data.id);
        if (response === 200) {
            onClose();
            refreshData();
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'workplace_name',
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="workplace-name" />
                </Text>
            )
        },
        {
            field: 'job_date',
            flex: 1,
            sortable: false,
            valueFormatter: (params) => (params.value ? format(new Date(params.value as string), 'dd.MM.yyyy') : '-'),
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="job-date" />
                </Text>
            )
        },
        {
            field: 'workplace',
            headerName: intl.formatMessage({ id: 'mark' }),
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <div>
                    <Checkbox
                        sx={{
                            color: '#36BDAA',
                            '&.Mui-checked': {
                                color: '#36BDAA'
                            }
                        }}
                        disabled={
                            jobs.find((element) => element.id === params.id).status >= 3 ||
                            (jobs.filter((element) => element.job_date === params.row.job_date).length > 1 &&
                                jobs
                                    .filter((element) => element.job_date === params.row.job_date)
                                    .some((element) => element.accepted_at !== null)) ||
                            // there is job summary with same date and accepted_at is not null
                            data.job_worker_summaries?.some((element: any) => element.job_date === params.row.job_date)
                        }
                        checked={selectedJobOffers?.filter((element) => element == params.id).length > 0}
                        onChange={() => {
                            if (selectedJobOffers?.filter((element) => element == params.id).length > 0) {
                                setSelectedJobOffers(selectedJobOffers?.filter((element) => element != params.id));
                            } else {
                                const foundJob = jobs.find((element) => element.id == params.id);
                                const sameDateJobOffer = jobs.filter((element) => element.job_date == foundJob.job_date);

                                let duplicatedOfferFlag = false;
                                if (sameDateJobOffer.length > 1) {
                                    selectedJobOffers.forEach((e) => {
                                        const matchSameDateJobOffer = sameDateJobOffer.filter((element) => element.id == e);
                                        if (matchSameDateJobOffer.length > 0) {
                                            duplicatedOfferFlag = true;
                                        }
                                    });
                                }

                                if (duplicatedOfferFlag) {
                                    console.error(`You can't select multiple jobs on the same date`);
                                    const snackbarContent = (
                                        <p style={{ padding: 0, margin: 0 }}>You can not select multiple jobs on the same date</p>
                                    );
                                    dispatch(
                                        openSnackbar({
                                            open: true,
                                            variant: 'alert',
                                            alert: {
                                                color: 'error'
                                            },
                                            actionButton: false,
                                            alertSeverity: 'error',
                                            children: snackbarContent
                                        })
                                    );
                                } else {
                                    const jobOffers = selectedJobOffers;
                                    jobOffers.push(Number(params.id));
                                    setSelectedJobOffers(jobOffers);
                                }
                            }
                        }}
                    />
                </div>
            )
        }
    ];

    return (
        <Box>
            <TitleText variant="h3">
                <FormattedMessage id={label} />
            </TitleText>

            <OutlinedInput
                sx={{ width: '257px', height: '48px', marginBottom: '16px' }}
                id="input-search-profile"
                placeholder={intl.formatMessage({ id: 'search' })}
                startAdornment={
                    <InputAdornment position="start">
                        <IconSearch stroke={1.5} size="1rem" />
                    </InputAdornment>
                }
                // onChange={(e) => searchResults(e)}
                size="small"
                endAdornment={
                    (label === 'my-tests' || label === 'reception-desk') && (
                        <InputAdornment position="end">
                            <WrapperIcon>
                                <img src={barcode} alt="barcode" />
                            </WrapperIcon>
                        </InputAdornment>
                    )
                }
            />

            <Table rows={jobs} columns={columns} />
            <br />
            <br />
            <br />
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <ButtonStyle onClick={onClose} variant="outlined">
                    <FormattedMessage id="cancel" />
                </ButtonStyle>
                <ButtonStyle
                    onClick={saveWorkplaces}
                    variant="contained"
                    sx={{
                        mx: 0.5
                    }}
                >
                    <FormattedMessage id="approve" />
                </ButtonStyle>
            </Box>
        </Box>
    );
};

export default ApproveJob;
