// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Typography } from '@mui/material';

// project imports
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import LogoSection from '../LogoSection';
import PartnerLogoSection from '../../MainLayout/PartnerLogoSection';
import MobileSection from '../../MainLayout/Header/MobileSection';
import ProfileSection from '../../MainLayout/Header/ProfileSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import { NavItemTypeObject } from 'types';
import { BASE_PATH } from 'config';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

interface headerProps {
    navigation?: NavItemTypeObject;
}
const Header = (props: headerProps) => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { navigation } = props;
    const currentItem = navigation?.items?.find((item) => document.location.pathname === BASE_PATH + item.url);
    return (
        <Box display="flex" width="100%">
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.common.white,
                        color: theme.palette.common.black,
                        '&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                        }
                    }}
                    onClick={() => dispatch(openDrawer(!drawerOpen))}
                    color="inherit"
                >
                    <IconMenu2 stroke={1.5} size="1.3rem" />
                </Avatar>
            </Box>
            <Typography fontSize="18px" sx={{ fontWeight: 700, lineHeight: '34px' }} width="100%" textAlign="center" mr={3}>
                {currentItem?.title}
            </Typography>
            {/* partner-logo */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <PartnerLogoSection />
            </Box>

            {/* profile */}
            <ProfileSection />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </Box>
    );
};

export default Header;
