import { Box, Stack, styled, Typography, Button, Skeleton, Grid } from '@mui/material';
import searchNotFound from 'assets/images/icons/not-found.svg';
import { FormattedMessage } from 'react-intl';

export interface NotFoundProps {
    status: number;
    tryAgain?: () => void;
    columnsLoaded: number;
}

const NotFound = (props: NotFoundProps) => {
    const { status, tryAgain, columnsLoaded = 5 } = props;

    const TextMessage = styled(Typography)(({ theme }) => ({
        fontWeight: 900,
        fontSize: '34px',
        lineHeight: '42px',
        color: theme.palette.grey[500],
        marginTop: '24.1px'
    }));

    const ImageSearch = styled('img')({
        width: '164.8px',
        height: '164.9px'
    });

    const ButtonStyled = styled(Button)(({ theme }) => ({
        color: theme.palette.background.paper,
        width: '92px',
        height: '44px',
        fontSize: '14px',
        marginTop: '40px'
    }));

    const WrapperNotFound = styled(Box)({
        display: 'flex',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 322px)',
        alignItems: 'center',
        marginTop: '80px'
    });

    return status === 2 ? (
        <WrapperNotFound>
            <Stack direction="column" justifyContent="center" alignItems="center">
                <ImageSearch src={searchNotFound} alt="not found" />
                <TextMessage sx={{ textAlign: 'center' }}>
                    <FormattedMessage id="not-found-message" />
                </TextMessage>
                <ButtonStyled onClick={tryAgain} variant="contained">
                    <FormattedMessage id="try-again" />
                </ButtonStyled>
            </Stack>
        </WrapperNotFound>
    ) : (
        <Grid container direction="row">
            <Grid item xs={12}>
                <Skeleton variant="rectangular" sx={{ m: '17px', ml: '6px' }} height={40} width={`${217 * columnsLoaded - 34}px`} />
            </Grid>
            {[...Array(5)].map((item, index) => (
                <Grid item key={`skeleton-grid-${index}`} xs={12}>
                    <Grid item key={`skeleton-grid-item-${index}`} sx={{ display: 'flex', justifyContent: 'start' }}>
                        {[...Array(columnsLoaded)].map((item2, i) => (
                            <Skeleton
                                key={`skeleton-item-${index}-${i}`}
                                variant="rectangular"
                                sx={{ m: '17px', mr: '6px', ml: '6px' }}
                                height={20}
                                width={200}
                            />
                        ))}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

export default NotFound;
