import { LOGIN, SET_LOGIN } from 'store/actions';
import { MiddlewareAPI, Dispatch, Middleware, AnyAction } from 'redux';
import axios from 'axios';

export const login: Middleware<Dispatch> =
    ({ dispatch }: MiddlewareAPI) =>
    (next) =>
    async (action: AnyAction | any) => {
        if (action.type === LOGIN) {
            const { email, password, setErrors } = action.payload;
            const body = { email, password };
            try {
                const response = await axios.post('/login', body);
                const { user, token, worker_statuses_info } = response.data;
                const { permissions } = response.data.user;
                axios.defaults.headers.common.authorization = `Bearer ${token}`;
                await localStorage.setItem('token', token);
                await localStorage.setItem('permissions', JSON.stringify(permissions));
                await localStorage.setItem('worker_statuses_info', JSON.stringify(worker_statuses_info));
                await dispatch({ type: SET_LOGIN, payload: { user, token } });
            } catch (err: any) {
                setErrors({ submit: err.message });
                console.error(`error in login user ${err}`);
            }
        }
        return next(action);
    };

export const forgotPassword = async (
    email: string,
    setResponse: (value: { status: number; message: string }) => void,
    setErrors: (value: any) => void
) => {
    try {
        const response = await axios.post('/forgot-password', { email });
        setResponse({ status: response.status, message: response.data.message });
    } catch (err: any) {
        setErrors({ submit: err.message });
        console.error(`error in forgot password ${err}`);
    }
};

export const resetPassword = async (
    token: string,
    password: string,
    password_confirmation: string,
    setResponse: (value: { status: number; message: string }) => void,
    setErrors: (value: any) => void
) => {
    const body = { password, password_confirmation, token };
    try {
        const response = await axios.post('/reset-password', body);
        setResponse({ status: response.status, message: response.data.message });
    } catch (err: any) {
        setErrors({ submit: err.message });
        console.error(`Error in reset password ${err}`);
    }
};
