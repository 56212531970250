import { ArrowBack, CheckBox } from '@mui/icons-material';
import { Box, Button, Divider, Grid, Typography, TextField, InputLabel, MenuItem, Autocomplete } from '@mui/material';
import { createRef, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { addWorkerComment, disableWorker, updateWorkerStatus } from 'services/WorkerService';
import { getPersonalDetails, getPersonalComments, setPersonalDetails } from 'services/PersonalAreaService';
import { Comment, Worker, WorkerAvailability } from 'types/worker';
import MainCard from 'ui-component/cards/MainCard';
import TextSection from 'views/common/TextSection';
import Action from 'views/common/GlobalAction';
import { useTheme } from '@mui/styles';
import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import DisableWithReasonDialog from 'views/common/GlobalDisableReasonDialog';
import ApprovalStepper from 'views/workers/workers/ApprovalStepper';
import {
    getStatusesList,
    getOccupationsList,
    getWorkerTypesList,
    getRegionAuthoritiesList,
    getWorkplaceTypeList,
    getWorkingHoursList,
    getLanguagesListForCompletion
} from 'services/ListService';
import GlobalDisableDialog from 'views/common/GlobalDisableDialog';
import { useDispatch, useSelector } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

interface StringID {
    name: string;
    id: number;
}

interface DaysList {
    day: number;
    name: string;
    hours: StringID[];
}

interface OptionListItem {
    name: string;
    id: number;
    region_id: number;
    languages?: [];
}

const UpdateDetails = () => {
    const { user }: any = useSelector((state) => state.auth);
    const [workerIdProp, setWorkerIdProp] = useState<string>();
    const [worker, setWorker] = useState<Worker>();
    const [edit, setEdit] = useState<boolean>(false);
    const [comments, setComments] = useState<Comment[]>([]);
    const [openDisable, setOpenDisable] = useState<boolean>(false);
    const [statuses, setStatuses] = useState<{ id: string; label: string }[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<{ id: string; label: string }>();
    const [openConfirmStatusChange, setOpenConfirmStatusChange] = useState<boolean>(false);
    const [xs, setXs] = useState<boolean>(false);
    const theme = useTheme();
    const commentRef = createRef<HTMLInputElement>();
    const gendersMap: { [key: number]: string } = { 1: 'male', 2: 'female', 3: 'other' };
    const [occupationList, setOccupationList] = useState<any[]>([]);
    const [workerTypesList, setWorkerTypesList] = useState<{ id: number; name: string }[]>([]);
    const [authoritiesList, setAuthoritiesList] = useState<Array<OptionListItem>>([]);
    const [filterAuthoritiesList, setFilterAuthoritiesList] = useState<Array<OptionListItem>>([]);
    const [selectedAuthoritiesList, setSelectedAuthoritiesList] = useState<Array<StringID>>([]);
    const [regionList, setRegionList] = useState<Array<StringID>>([]);
    const [selectedRegionList, setSelectedRegionList] = useState<Array<StringID>>([]);
    const [languagesList, setLanguagesList] = useState<Array<{ id: string; name: string }>>([]);
    const [workingHours, setWorkingHoursList] = useState<Array<DaysList>>([]);
    const [preferredHours, setPreferredHours] = useState<Map<number, any>>();
    const [restDayList, setRestDayList] = useState<Array<StringID>>([
        {
            id: 0,
            name: 'שישי'
        },
        {
            id: 1,
            name: 'שבת'
        },
        {
            id: 2,
            name: 'ראשון'
        }
    ]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (authoritiesList.length > 0 && worker !== undefined && Object.keys(worker).length > 0 && regionList.length > 0) {
            const regionsIDS = selectedRegionList.map((selectedRegion: { id: number }) => selectedRegion.id);
            const regionsFilter = regionList.filter((region) => regionsIDS.includes(region.id)).map((r) => ({ ...r, authorities: [] }));
            setWorker({ ...worker, regions: regionsFilter });
        }
    }, [selectedRegionList]);

    useEffect(() => {
        if (worker) {
            setSelectedRegionList(worker.regions);
        }
    }, []);

    useEffect(() => {
        if (workerIdProp) {
            getPersonalDetails(setWorker);
            getPersonalComments(setComments);
            getStatusesList(setStatuses);
            if (window.innerWidth <= 600) setXs(true);
        }
    }, [workerIdProp]);

    useEffect(() => {
        if (user && user.id) setWorkerIdProp(user.id);
    }, [user]);

    useEffect(() => {
        setFilterAuthoritiesList(authoritiesList);
    }, [authoritiesList]);

    useEffect(() => {
        const hoursMap = new Map<number, any>();
        Object.values(workingHours).map((dayItem, key) => hoursMap.set(dayItem.day, []));
        if (worker)
            worker.availability.map((item) =>
                hoursMap.set(
                    item.day,
                    item.hours.map((h) => ({ id: h.id, name: h.hour_interval }))
                )
            );
        setPreferredHours(hoursMap);
    }, [workingHours, worker]);

    useEffect(() => {
        getOccupationsList(setOccupationList);
        getRegionAuthoritiesList(setRegionList, setAuthoritiesList);
        getWorkplaceTypeList((arr: { id: string; label: string }[]) => {
            setWorkerTypesList(arr.map((w) => ({ id: Number(w.id), name: w.label })));
        });
        getLanguagesListForCompletion((languages: StringID[]) => {
            setLanguagesList(languages.map((w) => ({ id: w.id.toString(), name: w.name })));
        });
        getWorkingHoursList(setWorkingHoursList);
    }, []);

    const handleSaveComment = async () => {
        if (commentRef.current !== null && worker) {
            await addWorkerComment(worker.id, commentRef.current.value);
            await getPersonalComments(setComments);
        }
    };

    const handleDisable = async (reason: string) => {
        if (worker) {
            await disableWorker(worker.id.toString(), reason);
            getPersonalDetails(setWorker);
        }
        setOpenDisable(false);
    };

    const handleSave = async () => {
        if (worker) {
            setEdit(false);
            let arrAvalablities;
            if (preferredHours)
                arrAvalablities = Array.from(preferredHours.keys()).map((key) => ({ day: key, hours: preferredHours.get(key) }));
            try {
                await setPersonalDetails(worker, selectedAuthoritiesList, arrAvalablities);
                await getPersonalDetails(setWorker);
                setEdit(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'השינויים נשמרו בהצלחה',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        }
                    })
                );
            } catch (err: any) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: `${err.message} שגיאה`,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };

    const getPreferredWorkingHours = (availability: WorkerAvailability[]) => {
        const hours: { id: number; name: string; hour_interval: string }[] = [];
        availability.forEach((a) => hours.push(...a.hours));

        const ids = hours.map((h) => h.id);
        const filtered = hours.filter((hour, index) => !ids.includes(hour.id, index + 1));

        return filtered;
    };

    const handleUpdateStatus = async () => {
        if (workerIdProp) {
            await updateWorkerStatus(workerIdProp, Number(selectedStatus?.id));
            setOpenConfirmStatusChange(false);
            getPersonalDetails(setWorker);
        }
    };

    const setFilteredAuthoritiesOptions = (selectedRegions: any = selectedRegionList, selectedLanguages: any = worker?.languages) => {
        const selectedRegionsIds = selectedRegions.map((item: any) => item.id);
        const filteredAuths = authoritiesList.filter((item) => selectedRegionsIds.includes(item.region_id));
        const selectedLanguagesSet = new Set(selectedLanguages.map((lng: { id: string }) => Number(lng.id)));
        const filteredByLanguage = filteredAuths.filter((item) => {
            if (item.languages === undefined) return false;
            return item.languages.some((lng) => selectedLanguagesSet.has(lng));
        });
        setFilterAuthoritiesList(filteredByLanguage);
    };

    const isDateValid = () => {
        if (worker) return !(new Date(worker.birth_date) >= new Date(new Date().setFullYear(new Date().getFullYear() - 17)));
        return false;
    };

    const validateForm = () => {
        if (!isDateValid()) return false;
        if (worker?.regions.length === 0) return false;
        if (selectedAuthoritiesList.length === 0) return false;
        if (worker?.details?.graduated_in_israel && !worker?.details?.highschool_name) return false;
        if (worker?.details?.graduated_in_israel && !worker?.details?.highschool_city) return false;
        return true;
    };

    useEffect(() => {
        if (worker) {
            const defAuthoritiesList: any = [];
            worker.regions.forEach((item) => {
                defAuthoritiesList.push(item.authorities);
            });
            setSelectedAuthoritiesList(defAuthoritiesList.flat());
        }
    }, [worker]);

    useEffect(() => {
        if (filterAuthoritiesList.length) {
            const authoritiesIds = filterAuthoritiesList.map((s) => s.id);
            const filterSelectedAuthorities = selectedAuthoritiesList.filter((s) => authoritiesIds.includes(s.id));
            setSelectedAuthoritiesList(filterSelectedAuthorities);
        }
    }, [filterAuthoritiesList]);

    return (
        <MainCard
            sx={{ marginTop: '30px' }}
            title={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '32px', marginTop: '8px', ml: 2 }}>
                    <Box>
                        <Typography variant="subtitle1" sx={{ marginBottom: '30px' }}>
                            {worker?.id ? `${worker?.user.first_name} ${worker?.user.last_name}` : 'Loading...'} &nbsp;
                        </Typography>
                    </Box>
                    <Box mr={1} sx={{ display: workerIdProp || worker?.deleted_at ? 'none' : 'block' }}>
                        <Action
                            marginBetween="0px"
                            setEdit={(e) => {
                                console.log('will edit');
                            }}
                            setDisable={(e) => setOpenDisable(true)}
                        />
                    </Box>
                </Box>
            }
            headerSX={{ padding: '8px !important' }}
        >
            {worker && (
                <>
                    <Grid component="form" container rowSpacing={4}>
                        <Grid item xs={12}>
                            <Grid container rowSpacing={2}>
                                <Grid item xs={6} md={3}>
                                    <TextSection label={<FormattedMessage id="personal-number" />} content={worker.personal_id} />
                                </Grid>
                                <Grid item xs={6} md={3} mt={xs ? 2 : 0}>
                                    <TextSection label={<FormattedMessage id="phone-number" />} content={worker.user.phone_number} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextSection label={<FormattedMessage id="email" />} content={worker.user.email} />
                                </Grid>
                                <Grid item xs={12} md={3} sx={{ pr: 2 }} display="flex" justifyContent="end" alignItems="center">
                                    <Button onClick={() => setEdit(true)} variant="outlined" sx={{ width: { xs: '100%', md: '100px' } }}>
                                        <FormattedMessage id="edit" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} md={3}>
                                    <InputLabel htmlFor="first-name">
                                        <Typography fontSize="0.771rem" variant="subtitle1">
                                            <FormattedMessage id="first-name" />
                                        </Typography>
                                    </InputLabel>
                                    <TextField
                                        disabled={!edit}
                                        id="first-name"
                                        value={worker.user.first_name}
                                        onChange={(e) => setWorker({ ...worker, user: { ...worker.user, first_name: e.target.value } })}
                                        fullWidth
                                        size="small"
                                        sx={{ pr: 2 }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <InputLabel htmlFor="last-name">
                                        <Typography fontSize="0.771rem" variant="subtitle1">
                                            <FormattedMessage id="last-name" />
                                        </Typography>
                                    </InputLabel>
                                    <TextField
                                        disabled={!edit}
                                        id="last-name"
                                        value={worker.user.last_name}
                                        onChange={(e) => setWorker({ ...worker, user: { ...worker.user, last_name: e.target.value } })}
                                        fullWidth
                                        size="small"
                                        sx={{ pr: 2 }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <InputLabel htmlFor="birth-date">
                                        <Typography fontSize="0.771rem" variant="subtitle1">
                                            <FormattedMessage id="birth-date" />
                                        </Typography>
                                    </InputLabel>
                                    <TextField
                                        error={!isDateValid()}
                                        helperText={!isDateValid() && 'גיל מינימלי להשתתפות במיזם הוא17'}
                                        type="date"
                                        disabled={!edit}
                                        id="birth-date"
                                        defaultValue={worker.birth_date}
                                        onChange={(e) => setWorker({ ...worker, birth_date: new Date(e.target.value) })}
                                        fullWidth
                                        size="small"
                                        sx={{ pr: 2 }}
                                        InputProps={{
                                            inputProps: {
                                                max: format(new Date(new Date().setFullYear(new Date().getFullYear() - 17)), 'yyyy-MM-dd')
                                            }
                                        }}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} md={2}>
                                    <InputLabel htmlFor="employment">
                                        <Typography fontSize="0.771rem" variant="subtitle1">
                                            <FormattedMessage id="employment" />
                                        </Typography>
                                    </InputLabel>
                                    <TextField
                                        select
                                        disabled={!edit}
                                        id="employment"
                                        value={worker.type.id}
                                        onChange={(e) => setWorker({ ...worker, type: { ...worker.type, id: Number(e.target.value) } })}
                                        fullWidth
                                        size="small"
                                        sx={{ pr: 2 }}
                                    >
                                        {workerTypesList.map((item) => (
                                            <MenuItem key={item.id} value={item.label} />
                                        ))}
                                    </TextField> 
                                </Grid> */}
                                <Grid item xs={12} md={3}>
                                    <InputLabel htmlFor="gender">
                                        <Typography fontSize="0.771rem" variant="subtitle1">
                                            <FormattedMessage id="gender" />
                                        </Typography>
                                    </InputLabel>
                                    <TextField
                                        select
                                        disabled={!edit}
                                        id="gender"
                                        value={worker.gender}
                                        onChange={(e) => setWorker({ ...worker, gender: Number(e.target.value) })}
                                        fullWidth
                                        size="small"
                                        sx={{ pr: 2 }}
                                    >
                                        {Object.keys(gendersMap).map((key) => (
                                            <MenuItem key={key} value={key}>
                                                <FormattedMessage id={`gender.${gendersMap[Number(key)]}`} />
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>

                        {worker.deleted_at && (
                            <Grid item xs={2}>
                                <TextSection
                                    label={<FormattedMessage id="worker-status" />}
                                    content={
                                        <Box bgcolor={theme.palette.error[100]} maxWidth="200px" p={1} borderRadius={2}>
                                            <Typography color={theme.palette.error.dark} fontSize="12px">
                                                <FormattedMessage id="worker-removed-from-system" />
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} md={3}>
                                    <InputLabel htmlFor="address">
                                        <Typography fontSize="0.771rem" variant="subtitle1">
                                            <FormattedMessage id="address" />
                                        </Typography>
                                    </InputLabel>
                                    <Autocomplete
                                        disabled={!edit}
                                        disablePortal
                                        id="address"
                                        value={{ id: worker?.address_authority?.id, label: worker?.address_authority?.name }}
                                        onChange={(e, value) =>
                                            worker.address_authority &&
                                            setWorker({
                                                ...worker,
                                                address_authority: {
                                                    ...worker.address_authority,
                                                    id: Number(value?.id),
                                                    name: value?.label || ''
                                                }
                                            })
                                        }
                                        options={authoritiesList.map((field) => ({ label: field.name, id: field.id }))}
                                        sx={{ pr: 2 }}
                                        size="small"
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <InputLabel htmlFor="street">
                                        <Typography fontSize="0.771rem" variant="subtitle1">
                                            <FormattedMessage id="street" />
                                        </Typography>
                                    </InputLabel>
                                    <TextField
                                        disabled={!edit}
                                        id="street"
                                        value={worker.address_street}
                                        onChange={(e) => setWorker({ ...worker, address_street: e.target.value })}
                                        fullWidth
                                        size="small"
                                        sx={{ pr: 2 }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <InputLabel htmlFor="address_home_number">
                                        <Typography fontSize="0.771rem" variant="subtitle1">
                                            <FormattedMessage id="address_home_number" />
                                        </Typography>
                                    </InputLabel>
                                    <TextField
                                        type="number"
                                        disabled={!edit}
                                        id="address_home_number"
                                        value={worker.address_home_number}
                                        onChange={(e) => setWorker({ ...worker, address_home_number: e.target.value })}
                                        fullWidth
                                        size="small"
                                        sx={{ pr: 2 }}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} md={2}>
                                    <InputLabel htmlFor="notes">
                                        <Typography fontSize="0.771rem" variant="subtitle1">
                                            <FormattedMessage id="notes" />
                                        </Typography>
                                    </InputLabel>
                                    <TextField
                                        disabled={!edit}
                                        id="notes"
                                        value={worker.notes}
                                        fullWidth
                                        size="small"
                                        sx={{ pr: 2 }}
                                        onChange={(e) => setWorker({ ...worker, notes: e.target.value })}
                                    />
                                </Grid> */}
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container sx={{ ...(xs && { width: '100% !important' }), display: 'flex', alignItems: 'end' }}>
                                <Grid item xs={12} md={3}>
                                    <InputLabel htmlFor="receiving-sms">
                                        <Typography
                                            fontSize="0.771rem"
                                            variant="subtitle1"
                                            sx={{ lineBreak: 'normal', whiteSpace: 'normal' }}
                                        >
                                            <FormattedMessage id="receiving-sms-update" />
                                        </Typography>
                                    </InputLabel>
                                    <TextField
                                        select
                                        disabled={!edit}
                                        id="receiving-sms"
                                        value={worker.receiving_sms}
                                        fullWidth
                                        size="small"
                                        sx={{ pr: 2 }}
                                        onChange={(e) => setWorker({ ...worker, receiving_sms: Number(e.target.value) === 1 ? 1 : 0 })}
                                    >
                                        <MenuItem key={1} value={1}>
                                            <FormattedMessage id="yes" />
                                        </MenuItem>
                                        <MenuItem key={0} value={0}>
                                            <FormattedMessage id="no" />
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                {!worker?.receiving_sms && (
                                    <Grid item xs={12} md={3}>
                                        <InputLabel htmlFor="kosher-phone">
                                            <Typography fontSize="0.771rem" variant="subtitle1">
                                                <FormattedMessage id="kosher-phone-question" />
                                            </Typography>
                                        </InputLabel>
                                        <TextField
                                            select
                                            disabled={!edit}
                                            id="kosher-phone"
                                            value={worker.details?.kosher_phone}
                                            fullWidth
                                            size="small"
                                            sx={{ pr: 2 }}
                                            onChange={(e) =>
                                                worker.details &&
                                                setWorker({
                                                    ...worker,
                                                    details: { ...worker.details, kosher_phone: Number(e.target.value) === 1 ? 1 : 0 }
                                                })
                                            }
                                        >
                                            <MenuItem key={1} value={1}>
                                                <FormattedMessage id="yes" />
                                            </MenuItem>
                                            <MenuItem key={0} value={0}>
                                                <FormattedMessage id="no" />
                                            </MenuItem>
                                        </TextField>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                        {worker.deleted_at && (
                            <Grid item xs={12}>
                                <Grid item xs={worker.deleted_at ? 2 : 0}>
                                    <TextSection
                                        label={
                                            <Typography color={theme.palette.error.dark} fontSize="12px" variant="body2">
                                                <FormattedMessage id="removal-reason" />
                                            </Typography>
                                        }
                                        content={worker.delete_reason}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>

                    <Box mt={4}>
                        <Grid
                            container
                            sx={{ justifyContent: 'space-between', ...(xs && { width: '100% !important' }) }}
                            width={worker.deleted_at ? '50%' : '62%'}
                        >
                            {worker.details?.how_did_you_heard_about_us && (
                                <Grid item xs={4}>
                                    <TextSection
                                        label={<FormattedMessage id="how_did_you_heard_about_us" />}
                                        content={worker.details?.how_did_you_heard_about_us}
                                    />
                                </Grid>
                            )}
                            {worker.details?.referred_by && (
                                <Grid item xs={4}>
                                    <TextSection label={<FormattedMessage id="referred_by" />} content={worker.details?.referred_by} />
                                </Grid>
                            )}
                        </Grid>
                    </Box>

                    {/* <Divider sx={{ mt: 3, borderColor: theme.palette.secondary.light }} />
                    <Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
                        <Grid item xs={12} md={6}>
                            <Box
                                bgcolor={theme.palette.secondary.light}
                                maxWidth="200px"
                                p={1}
                                borderRadius={2}
                                display="inline-block"
                                mt={3}
                            >
                                <Typography color={theme.palette.secondary.dark}>
                                    <FormattedMessage id="approvals" />
                                </Typography>
                            </Box>
                            <Box mt={3} ml={xs ? 0 : -4}>
                                <ApprovalStepper
                                    status={worker.status}
                                    statuses={statuses}
                                    onStatusClick={(clickedId) => {
                                        setSelectedStatus(statuses.find((s) => s.id === clickedId));
                                        setOpenConfirmStatusChange(true);
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid> */}
                    <Divider sx={{ mt: 3, borderColor: theme.palette.secondary.light }} />
                    <Grid container spacing={2} sx={{ justifyContent: 'space-between', mt: 1 }}>
                        <Grid item xs={12} md={6} pr={2}>
                            <Box bgcolor={theme.palette.secondary.light} maxWidth="200px" p={1} borderRadius={2} display="inline-block">
                                <Typography color={theme.palette.secondary.dark}>
                                    <FormattedMessage id="work-preferences" />
                                </Typography>
                            </Box>
                            <Grid
                                container
                                sx={{ justifyContent: 'space-between' }}
                                borderRadius={2}
                                p={1}
                                bgcolor={theme.palette.grey[100]}
                                mt={1}
                            >
                                <Grid item xs={12} md={4}>
                                    <InputLabel htmlFor="languages">
                                        <Typography fontSize="0.771rem" variant="subtitle1">
                                            <FormattedMessage id="languages" />
                                        </Typography>
                                    </InputLabel>
                                    <Autocomplete
                                        disabled={!edit}
                                        id="languages"
                                        multiple
                                        value={worker.languages}
                                        options={languagesList}
                                        onChange={(e, value) => {
                                            setWorker({ ...worker, languages: value });
                                            setFilteredAuthoritiesOptions(selectedRegionList, value);
                                        }}
                                        getOptionLabel={(option) => option?.name}
                                        size="small"
                                        sx={{ pr: 2 }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <InputLabel htmlFor="preferred-working-area">
                                        <Typography fontSize="0.771rem" variant="subtitle1">
                                            <FormattedMessage id="preferred-working-area" />
                                        </Typography>
                                    </InputLabel>
                                    <Autocomplete
                                        disabled={!edit}
                                        id="preferred-working-area"
                                        multiple
                                        value={worker.regions}
                                        onChange={(e, value) => {
                                            setSelectedRegionList(value);
                                            setFilteredAuthoritiesOptions(value, worker?.languages);
                                        }}
                                        options={regionList}
                                        getOptionLabel={(option) => option?.name}
                                        size="small"
                                        sx={{ pr: 2 }}
                                        renderInput={(params) => (
                                            <TextField
                                                error={worker.regions.length === 0}
                                                helperText={worker.regions.length === 0 && 'שדה חובה'}
                                                {...params}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <InputLabel htmlFor="preferred-local-authority">
                                        <Typography fontSize="0.771rem" variant="subtitle1">
                                            <FormattedMessage id="preferred-local-authority" />
                                        </Typography>
                                    </InputLabel>
                                    <Autocomplete
                                        disabled={!edit}
                                        id="preferred-local-authority"
                                        multiple
                                        value={selectedAuthoritiesList}
                                        options={filterAuthoritiesList}
                                        onChange={(e, value) => setSelectedAuthoritiesList(value)}
                                        getOptionLabel={(option) => option?.name}
                                        size="small"
                                        sx={{ pr: 2 }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={selectedAuthoritiesList.length === 0}
                                                helperText={selectedAuthoritiesList.length === 0 && 'שדה חובה'}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} mt={2}>
                                    <InputLabel htmlFor="preferred_days">
                                        <Typography fontSize="0.771rem" variant="subtitle1">
                                            <FormattedMessage id="preferred_days" />
                                        </Typography>
                                    </InputLabel>
                                    <Grid container>
                                        {Object.values(workingHours).map((dayItem, key) => (
                                            <Grid item xs={12} md={4}>
                                                <InputLabel htmlFor="preferred_days">
                                                    <Typography fontSize="0.771rem" variant="subtitle1">
                                                        <FormattedMessage id={dayItem.name} />
                                                    </Typography>
                                                </InputLabel>
                                                <Autocomplete
                                                    disabled={!edit}
                                                    id="preferred_days"
                                                    multiple
                                                    value={preferredHours?.get(dayItem.day)}
                                                    options={dayItem.hours}
                                                    onChange={(e, value) => {
                                                        const customMap = new Map(preferredHours);
                                                        customMap?.set(dayItem.day, value);
                                                        setPreferredHours(customMap);
                                                    }}
                                                    getOptionLabel={(option) => option?.name}
                                                    size="small"
                                                    sx={{ pr: 2 }}
                                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={4}>
                                    <InputLabel htmlFor="workplace-types">
                                        <Typography fontSize="0.771rem" variant="subtitle1">
                                            <FormattedMessage id="workplace-types" />
                                        </Typography>
                                    </InputLabel>
                                    <Autocomplete
                                        disabled={!edit}
                                        id="workplace-types"
                                        multiple
                                        value={worker.workplace_types}
                                        onChange={(e, value) => setWorker({ ...worker, workplace_types: value })}
                                        options={workerTypesList}
                                        getOptionLabel={(option) => option?.name}
                                        size="small"
                                        sx={{ pr: 2 }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid> */}
                                {/* <Grid item xs={12} md={4} mt={1}>
                                    <TextSection
                                        label={<FormattedMessage id="preferred-working-days" />}
                                        content={
                                            <Box display="flex" sx={{ flexWrap: 'wrap' }}>
                                                {worker.availability
                                                    .filter((av) => av.hours.length > 0)
                                                    .map((av) => (
                                                        <Typography fontSize="12px" key={`day-${av.day}`} mr="4px">
                                                            <CheckBox
                                                                color="secondary"
                                                                sx={{ width: '10.5px', height: '10.5px', mb: '-1px', mr: '2px' }}
                                                            />
                                                            <FormattedMessage id={av.name} />
                                                        </Typography>
                                                    ))}
                                            </Box>
                                        }
                                    />
                                </Grid> */}

                                {/* <Grid item xs={4} mt={1}>
                                    <TextSection
                                        label={<FormattedMessage id="preferred-age-ranges" />}
                                        content={
                                            <Box display="flex" sx={{ flexWrap: 'wrap' }}>
                                                {worker.age_ranges.map((range) => (
                                                    <Typography fontSize="12px" key={`age-range-${range.id}`} mr="4px">
                                                        <CheckBox
                                                            color="secondary"
                                                            sx={{ width: '10.5px', height: '10.5px', mb: '-1px', mr: '2px' }}
                                                        />
                                                        {range.interval_label}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        }
                                    />
                                </Grid> */}
                                {/* <Grid item xs={12} md={4} mt={1}>
                                    <TextSection
                                        label={<FormattedMessage id="preferred-working-hours" />}
                                        content={
                                            <Box display="flex" sx={{ flexWrap: 'wrap' }}>
                                                {getPreferredWorkingHours(worker.availability).map((hour) => (
                                                    <Typography fontSize="12px" key={`age-range-${hour.id}`} mr="4px">
                                                        <CheckBox
                                                            color="secondary"
                                                            sx={{ width: '10.5px', height: '10.5px', mb: '-1px', mr: '2px' }}
                                                        />
                                                        {hour.name} &nbsp;{hour.hour_interval}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        }
                                    />
                                </Grid> */}
                                <Grid item xs={12} mt={1}>
                                    <Grid item container>
                                        <Grid item xs={4} md={4}>
                                            <InputLabel htmlFor="weekend-preference">
                                                <Typography fontSize="0.771rem" variant="subtitle1">
                                                    <FormattedMessage id="rest_day_select_question" />
                                                </Typography>
                                            </InputLabel>
                                            <Autocomplete
                                                disabled={!edit}
                                                id="weekend-preference"
                                                value={restDayList.find((r) => r.name === worker.details?.rest_day) ?? restDayList[1]}
                                                onChange={(e, value) =>
                                                    worker.details &&
                                                    setWorker({ ...worker, details: { ...worker.details, rest_day: value?.name || null } })
                                                }
                                                options={restDayList}
                                                getOptionLabel={(option) => option?.name}
                                                size="small"
                                                sx={{ pr: 2 }}
                                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel htmlFor="occupation">
                                                <Typography fontSize="0.771rem" variant="subtitle1">
                                                    <FormattedMessage id="occupation" />
                                                </Typography>
                                            </InputLabel>
                                            <TextField
                                                select
                                                disabled={!edit}
                                                id="occupation"
                                                value={worker.occupation.id}
                                                onChange={async (e) =>
                                                    setWorker({
                                                        ...worker,
                                                        occupation: {
                                                            id: Number(e.target.value),
                                                            name: await occupationList.find((i) => i.id === Number(e.target.value))?.label
                                                        }
                                                    })
                                                }
                                                fullWidth
                                                size="small"
                                                sx={{ pr: 2 }}
                                            >
                                                {occupationList.map((key: any) => (
                                                    <MenuItem key={key.id} value={key.id}>
                                                        <FormattedMessage id={`${key.label}`} />
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box bgcolor={theme.palette.secondary.light} maxWidth="400px" p={1} borderRadius={2} display="inline-block">
                                <Typography color={theme.palette.secondary.dark}>
                                    <FormattedMessage id="education-and-working-experience" />
                                </Typography>
                            </Box>
                            <Box mt={1}>
                                <Grid container sx={{ justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={6}>
                                        <Grid item xs={12}>
                                            <Typography fontSize="12px" variant="subtitle1">
                                                <FormattedMessage id="education" />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel htmlFor="graduated-highschool">
                                                <Typography fontSize="0.771rem" variant="subtitle1">
                                                    <FormattedMessage id="graduated-highschool" />
                                                </Typography>
                                            </InputLabel>
                                            <TextField
                                                select
                                                disabled
                                                id="graduated-highschool"
                                                value={worker.details?.highschool_graduated}
                                                fullWidth
                                                size="small"
                                                sx={{ pr: 2 }}
                                                onChange={(e) =>
                                                    worker.details &&
                                                    setWorker({
                                                        ...worker,
                                                        details: {
                                                            ...worker.details,
                                                            highschool_graduated: Number(e.target.value) === 1 ? 1 : 0
                                                        }
                                                    })
                                                }
                                            >
                                                <MenuItem key={1} value={1}>
                                                    <FormattedMessage id="yes" />
                                                </MenuItem>
                                                <MenuItem key={0} value={0}>
                                                    <FormattedMessage id="no" />
                                                </MenuItem>
                                            </TextField>
                                        </Grid>
                                        {worker.details?.highschool_graduated ? (
                                            <>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="graduated-in-israel">
                                                        <Typography fontSize="0.771rem" variant="subtitle1">
                                                            <FormattedMessage id="graduated-in-israel" />
                                                        </Typography>
                                                    </InputLabel>
                                                    <TextField
                                                        select
                                                        disabled={!edit}
                                                        id="graduated-in-israel"
                                                        value={worker.details.graduated_in_israel}
                                                        fullWidth
                                                        size="small"
                                                        sx={{ pr: 2 }}
                                                        onChange={(e) =>
                                                            worker.details &&
                                                            setWorker({
                                                                ...worker,
                                                                details: {
                                                                    ...worker.details,
                                                                    graduated_in_israel: Number(e.target.value) === 1 ? 1 : 0
                                                                }
                                                            })
                                                        }
                                                    >
                                                        <MenuItem key={1} value={1}>
                                                            <FormattedMessage id="details.graduated_in_israel" />
                                                        </MenuItem>
                                                        <MenuItem key={0} value={0}>
                                                            <FormattedMessage id="abroad" />
                                                        </MenuItem>
                                                    </TextField>
                                                </Grid>
                                                {worker.details.graduated_in_israel ? (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <InputLabel htmlFor="highschool-name">
                                                                <Typography fontSize="0.771rem" variant="subtitle1">
                                                                    <FormattedMessage id="highschool-name" />
                                                                </Typography>
                                                            </InputLabel>
                                                            <TextField
                                                                error={!worker.details.highschool_name}
                                                                helperText={!worker.details.highschool_name && 'שדה חובה'}
                                                                disabled={!edit}
                                                                id="highschool-name"
                                                                value={worker.details.highschool_name}
                                                                fullWidth
                                                                size="small"
                                                                sx={{ pr: 2 }}
                                                                onChange={(e) =>
                                                                    worker.details &&
                                                                    setWorker({
                                                                        ...worker,
                                                                        details: { ...worker.details, highschool_name: e.target.value }
                                                                    })
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <InputLabel htmlFor="highschool-city">
                                                                <Typography fontSize="0.771rem" variant="subtitle1">
                                                                    <FormattedMessage id="highschool-city" />
                                                                </Typography>
                                                            </InputLabel>
                                                            <TextField
                                                                error={!worker.details.highschool_city}
                                                                helperText={!worker.details.highschool_city && 'שדה חובה'}
                                                                disabled={!edit}
                                                                id="highschool-city"
                                                                value={worker.details.highschool_city}
                                                                fullWidth
                                                                size="small"
                                                                sx={{ pr: 2 }}
                                                                onChange={(e) =>
                                                                    worker.details &&
                                                                    setWorker({
                                                                        ...worker,
                                                                        details: { ...worker.details, highschool_city: e.target.value }
                                                                    })
                                                                }
                                                            />
                                                        </Grid>
                                                    </>
                                                ) : null}
                                            </>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Grid item xs={12}>
                                            <Typography fontSize="12px" variant="subtitle1">
                                                <FormattedMessage id="experience" />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel htmlFor="working-experience">
                                                <Typography fontSize="0.771rem" variant="subtitle1">
                                                    <FormattedMessage id="working-experience" />
                                                </Typography>
                                            </InputLabel>
                                            <TextField
                                                select
                                                disabled={!edit}
                                                id="working-experience"
                                                value={worker.details?.previous_experience}
                                                fullWidth
                                                size="small"
                                                sx={{ pr: 2 }}
                                                onChange={(e) =>
                                                    worker.details &&
                                                    setWorker({
                                                        ...worker,
                                                        details: {
                                                            ...worker.details,
                                                            previous_experience: Number(e.target.value) === 1 ? 1 : 0
                                                        }
                                                    })
                                                }
                                            >
                                                <MenuItem key={1} value={1}>
                                                    <FormattedMessage id="yes" />
                                                </MenuItem>
                                                <MenuItem key={0} value={0}>
                                                    <FormattedMessage id="no" />
                                                </MenuItem>
                                            </TextField>
                                        </Grid>
                                        {worker.details?.previous_experience ? (
                                            <Grid item xs={12}>
                                                <InputLabel htmlFor="previous-experience-with-children">
                                                    <Typography fontSize="0.771rem" variant="subtitle1">
                                                        <FormattedMessage id="previous-experience-with-children" />
                                                    </Typography>
                                                </InputLabel>
                                                <TextField
                                                    disabled={!edit}
                                                    id="previous-experience-with-children"
                                                    value={worker.details.previous_experience_story}
                                                    fullWidth
                                                    size="small"
                                                    sx={{ pr: 2 }}
                                                    onChange={(e) =>
                                                        worker.details &&
                                                        setWorker({
                                                            ...worker,
                                                            details: { ...worker.details, previous_experience_story: e.target.value }
                                                        })
                                                    }
                                                />
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item xs={12} display="flex" justifyContent="end" sx={{ direction: 'ltr' }}>
                            <Box width={{ xs: '100%', md: 'fit-content' }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Button
                                            disabled={!validateForm() || !edit}
                                            type="submit"
                                            onClick={handleSave}
                                            variant="contained"
                                            sx={{ width: { xs: '100%', md: '100px' } }}
                                        >
                                            <FormattedMessage id="save" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            )}
            <DisableWithReasonDialog
                open={openDisable}
                onClose={() => setOpenDisable(false)}
                onDisable={handleDisable}
                coloredText={` ${worker?.user.first_name} ${worker?.user.last_name}`}
                title1="Are you sure you want to disable"
                title2="Worker?"
            />
            <GlobalDisableDialog
                open={openConfirmStatusChange}
                onClose={() => setOpenConfirmStatusChange(false)}
                onDisable={handleUpdateStatus}
                coloredText={`${selectedStatus?.label}`}
                title1={`Are you sure you want to update the Worker ${workerIdProp} to`}
                title2="Status?"
                labelButton="confirm"
            />
        </MainCard>
    );
};

export default UpdateDetails;
