import { styled, Box, Typography, MenuItem, Grid, TextField, Avatar, Switch, Button, AvatarGroup } from '@mui/material';
import { useState, useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import { ScienceOutlined, ArrowBack } from '@mui/icons-material';
import EditUserListDialog from './EditUserListDialog';
import { FormattedMessage } from 'react-intl';
import { addUserGroup, editUserGroup, getUserGroup, getUserGroupsList } from 'services/UserGroupService';
import { UserGroupDetails, UserProps, PermissionsProps } from 'types/user-group';
import { getUsers } from 'services/UserService';
import { User } from 'types/auth';
import { useUnload } from 'hooks/useUnload';
import { useNavigate, useParams } from 'react-router-dom';
import { getPermissionsList } from 'services/ListService';

export interface editProps {
    groupName: string;
    users: UserProps[] | undefined;
    permissions: PermissionsProps[];
}

export interface UserGroupProps {
    isEdit: boolean;
}

const UserGroupOptions = (props: UserGroupProps) => {
    const { isEdit } = props;
    const { id } = useParams();
    const [openUsers, setOpenUsers] = useState<boolean>(false);
    const [avatarUsers, setAvatarUsers] = useState<any[]>([]);
    const [userGroupInfo, setUserGroupInfo] = useState<UserGroupDetails>();
    const [editGroup, setEditGroup] = useState<editProps>({ groupName: '', users: undefined, permissions: [] });
    const [permissionsArr, setPermissionsArr] = useState<{ permission_id: number; name: string; icon: string; permission_level: number }[]>(
        []
    );
    const [selectedPermissions, setSelectedPermissions] = useState<number>(0);
    const [userGroups, setUserGroups] = useState<{ [key: string]: string }>({});
    const [userList, setUserList] = useState<User[]>([]);
    const [valueStatus, setValueStatus] = useState<number>(0); // 1: Data received, 2: Data changed in local, 0: None received
    const navigate = useNavigate();
    const [permissionsList, setPermissionsList] = useState<{ id: number; name: string; icon: string }[]>([]);
    const [success, setSuccess] = useState<boolean>();

    useUnload((e: any) => {
        const confirmationMessage = 'Are you sure?/';
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
    }, valueStatus === 2);

    const theme = useTheme();

    const H3 = styled(Typography)({
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '19px',
        letterSpacing: '0em',
        textAlign: 'left',
        paddingLeft: '16px',
        marginBottom: '55px'
    });

    const TextFieldStyled = styled(TextField)({
        width: '354.5px',
        height: '52px'
    });

    const Text = styled('div')({
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: 500,
        marginBottom: '45px',
        width: '123px'
    });

    const Permissionitem = styled(MainCard)({
        width: '195px',
        height: '133px',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10.5px',
        border: `solid 2px ${theme.palette.secondary.light}`,
        backgroundColor: theme.palette.grey[50]
    });

    const UsersBox = styled(Box)({
        borderRadius: '12px',
        height: '101px',
        marginRight: '22px',
        paddingTop: '10px',
        paddingLeft: '12px',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.light
    });

    const AvatarGroupStyle = styled(AvatarGroup)({
        '& .MuiAvatarGroup-avatar': {
            fontSize: '12px !important'
        },
        display: 'flex',
        justifyContent: 'start'
    });

    const AvatarStyle = styled(Avatar)({
        fontSize: '12px',
        height: '40px',
        width: '40px'
    });

    const ButtonAvatar = styled(Button)({
        '&:hover': {
            color: theme.palette.secondary.light,
            borderColor: theme.palette.secondary.light
        },
        color: theme.palette.secondary.light,
        height: '44px',
        borderColor: theme.palette.secondary.light,
        marginTop: '30px',
        marginRight: '14.85px'
    });

    const SwitchCustomize = styled(Switch)({
        marginRight: '34px'
    });

    const ButtonIconStyle = styled(Button)({
        '&:hover': {
            border: 'none'
        },
        border: 'none',
        padding: '12px, 16px, 12px, 16px',
        fontSize: '14px',
        fontWeight: 500
    });

    const ButtonStyle = styled(Button)({
        height: '44px',
        width: '111px',
        borderRadius: '4px',
        padding: '12px, 16px, 12px, 16px',
        fontSize: '14px',
        fontWeight: 500,
        color: `${theme.palette.primary.light}`
    });

    const checkSelected = () => {
        const selectedArr = permissionsArr.filter((el) => el.permission_level === 1);
        setSelectedPermissions(selectedArr.length);
    };

    const handleSwitch = (permissionId: number, level: boolean) => {
        const permissionArrayIndex = permissionsArr.findIndex((element) => element.permission_id === permissionId);
        let permissions: { permission_id: number; name: string; icon: string; permission_level: number }[];
        if (permissionArrayIndex > -1) {
            permissions = permissionsArr.map((el) => (el.permission_id === permissionId ? { ...el, permission_level: level ? 1 : 0 } : el));
        } else {
            const selectedPermission = permissionsList.filter((per) => per.id === permissionId)[0];
            permissions = [
                ...permissionsArr,
                {
                    permission_id: selectedPermission.id,
                    permission_level: level ? 1 : 0,
                    name: selectedPermission.name,
                    icon: selectedPermission.icon
                }
            ];
        }
        setPermissionsArr(permissions);
    };

    const saveGroup = async () => {
        const permissions: { permission_id: number; permission_level: number }[] = await permissionsArr.map((el) => ({
            permission_id: el.permission_id,
            permission_level: el.permission_level
        }));

        const permissionsToMap = await new Map(
            await permissions.map((object) => [object.permission_id.toString(), object.permission_level])
        );

        if (isEdit && id) {
            let addedUsers: string[] = avatarUsers
                .filter((x) => !userGroupInfo?.users.map((u) => u.id).includes(x.id))
                .map((el: any) => el.id);
            if (addedUsers.length === 1 && addedUsers[0] === undefined) addedUsers = [];
            let deletedUsers: any[] = [];
            const usersId = await avatarUsers.map((x) => x.id);
            if (userGroupInfo) deletedUsers = userGroupInfo.users.map((u) => u.id).filter((x) => !usersId.includes(x));
            if (deletedUsers?.length === 1 && deletedUsers[0] === undefined) deletedUsers = [];
            await editUserGroup(id, editGroup.groupName, permissionsToMap, addedUsers, deletedUsers, setSuccess);
        }
        if (!isEdit) {
            let selectedUsers: string[] = await avatarUsers.map((el: any) => el.id);
            if (selectedUsers.length === 1 && selectedUsers[0] === undefined) selectedUsers = [];
            await addUserGroup(editGroup.groupName, permissionsToMap, selectedUsers, setSuccess);
        }
    };

    const stringAvatar = (name: string) => ({ children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` });

    useEffect(() => {
        if (success !== undefined && success === true) navigate('/user-groups');
    }, [navigate, success]);

    useEffect(() => {
        if (userList.length > 0 && userGroupInfo) {
            setAvatarUsers(userGroupInfo.users);
        }
    }, [userGroupInfo, userList]);

    useEffect(() => {
        if (userGroupInfo) {
            setEditGroup({
                groupName: userGroupInfo.name,
                users: userGroupInfo.users,
                permissions: userGroupInfo.permissions
            });
            if (userGroupInfo.permissions) {
                const arr = userGroupInfo.permissions.map((p: any) => ({
                    permission_id: p.permission_id,
                    name: p.name,
                    icon: p.icon,
                    permission_level: p.permission_level
                }));
                setPermissionsArr(arr);
            }
        }
    }, [userGroupInfo]);

    useEffect(() => {
        if (isEdit && id) {
            getUserGroup(id, setUserGroupInfo);
        }
    }, [isEdit, id]);

    useEffect(() => {
        checkSelected();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissionsArr]);

    useEffect(() => {
        getUsers(setUserList, { page: 1, per_page: 50, filters: [], sort: [{ field: 'id', sort: 'asc' }] });
        getPermissionsList(setPermissionsList);
        getUserGroupsList(setUserGroups);
        // setPermissions();
    }, []);

    useEffect(() => {
        if (editGroup.groupName && valueStatus < 1) setValueStatus(1);
        if (valueStatus === 1) setValueStatus(2);
    }, [editGroup, valueStatus]);

    return (
        <>
            <MainCard>
                <H3 variant="h3">{isEdit ? editGroup.groupName : 'New User Group'}</H3>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Text sx={{ marginLeft: '43px' }}>
                                <FormattedMessage id="group-details" />
                            </Text>
                            <Box sx={{ display: 'flex', marginLeft: '70px' }}>
                                <TextField
                                    fullWidth
                                    sx={{ marginRight: '24px', width: '354.5px', height: '52px' }}
                                    id="outlined-group-name"
                                    label={<FormattedMessage id="group-name" />}
                                    value={editGroup.groupName}
                                    onChange={(e) => setEditGroup({ ...editGroup, groupName: e.target.value })}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Text sx={{ marginBottom: '36px' }}>
                                <FormattedMessage id="group-settings" />
                            </Text>
                            <UsersBox>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography>
                                            <FormattedMessage id="selected-users" />
                                        </Typography>
                                        <Typography sx={{ fontSize: '12px' }}>
                                            {avatarUsers.length} <FormattedMessage id="users" />
                                        </Typography>
                                        <AvatarGroupStyle max={4}>
                                            {avatarUsers.length > 0 &&
                                                avatarUsers.map((user, index) => (
                                                    <AvatarStyle
                                                        key={`avatar-user${index}`}
                                                        {...stringAvatar(`${user.first_name} ${user.last_name}`)}
                                                    />
                                                ))}
                                        </AvatarGroupStyle>
                                    </Grid>
                                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                                        <ButtonAvatar onClick={() => setOpenUsers(true)} variant="outlined">
                                            {isEdit ? 'Change' : 'Add'}
                                        </ButtonAvatar>
                                    </Grid>
                                </Grid>
                                <EditUserListDialog
                                    avatarUsers={avatarUsers}
                                    setAvatarUsers={(value: any[]) => setAvatarUsers(value)}
                                    open={openUsers}
                                    onClose={() => setOpenUsers(false)}
                                />
                            </UsersBox>
                        </Grid>
                        <Grid item>
                            <Box sx={{ display: 'flex', marginBottom: '66px' }}>
                                <Text sx={{ marginLeft: '41px', marginBottom: '66px' }}>
                                    <FormattedMessage id="permissions" />
                                </Text>
                                <Text sx={{ fontWeight: 100 }}>
                                    : {selectedPermissions} <FormattedMessage id="selected" />
                                </Text>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1, marginLeft: '17px' }}>
                    <Grid container>
                        {permissionsList.length > 0 &&
                            permissionsList.map((item, index) => (
                                <Grid
                                    item
                                    sx={{ display: 'flex', justifyContent: 'start', marginBottom: '34px', marginRight: '90px' }}
                                    key={`PermissionsItem${index}`}
                                    xs={3}
                                >
                                    <SwitchCustomize
                                        checked={
                                            permissionsArr.filter((per) => per.permission_id === item.id)[0] &&
                                            permissionsArr.filter((per) => per.permission_id === item.id)[0].permission_level === 1
                                        }
                                        onChange={(e) => {
                                            handleSwitch(item.id, e.target.checked);
                                            checkSelected();
                                        }}
                                        color="secondary"
                                    />
                                    <Permissionitem
                                        sx={{
                                            backgroundColor:
                                                permissionsArr.filter((per) => per.permission_id === item.id)[0] &&
                                                permissionsArr.filter((per) => per.permission_id === item.id)[0].permission_level &&
                                                isEdit
                                                    ? theme.palette.secondary.light
                                                    : ''
                                        }}
                                    >
                                        <Avatar
                                            sx={{ margin: 'auto', backgroundColor: theme.palette.secondary.main, color: 'white' }}
                                            variant="rounded"
                                        >
                                            <ScienceOutlined />
                                        </Avatar>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                marginTop: '14.5px',
                                                color: theme.palette.secondary.main,
                                                fontWeight: 500,
                                                fontSize: '16px'
                                            }}
                                        >
                                            {item.name}
                                        </Typography>
                                    </Permissionitem>
                                </Grid>
                            ))}
                    </Grid>
                </Box>
                <Box sx={{ marginTop: '92px', display: 'flex', justifyContent: 'space-between' }}>
                    <ButtonIconStyle
                        color="secondary"
                        onClick={() => navigate('/user-groups')}
                        variant="outlined"
                        startIcon={<ArrowBack />}
                    >
                        Back
                    </ButtonIconStyle>
                    <ButtonStyle color="secondary" variant="contained" onClick={() => saveGroup()}>
                        Save
                    </ButtonStyle>
                </Box>
            </MainCard>
        </>
    );
};
export default UserGroupOptions;
