// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Link, Stack, Typography } from '@mui/material';
import LogoSection from '../LogoSection';
import creditLogo from 'assets/images/credit-new.png';

// project imports

import PartnerLogoSection from '../PartnerLogoSection';
import MerkazShiltonSection from '../MerkazShiltonLogo';

import { useDispatch, useSelector } from 'store';
import { FormattedMessage } from 'react-intl';

// ==============================|| MAIN NAVBAR / Footer ||============================== //

const Footer = () => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            // position: 'fixed',
            // bottom: '0',
            // left: 0,
            // right: 0,
            height: '100px',
            marginTop: '12px',
            zIndex: 1399
        }}
    >
        {/* logos */}
        <Box
            sx={{
                backgroundColor: '#FFFFFF',
                width: '100% !important',
                display: 'flex',
                flexDirection: 'row',
                height: '75%',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '5em'
            }}
        >
            <Box sx={{ display: { xs: 'none', md: 'flex', gap: '5em' } }}>
                <PartnerLogoSection color="black" />
                <MerkazShiltonSection height={37} color="black" />
            </Box>
            <LogoSection color="black" />
        </Box>
        {/* {purple line} */}
        <Box
            sx={{
                backgroundColor: '#4C43B2',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '25%',
                width: '100%',
                justifyContent: 'center'
            }}
        >
            <Box
                sx={{
                    // width: '73px',
                    // height: '16px',
                    // fontFamily: 'Noto Sans Hebrew',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'right',
                    color: '#FFFFFF'
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="subtitle2" component={Link} href="https://wmg.co.il/" target="_blank" sx={{ ml: 2 }}>
                        <Box component="span" display="flex" alignItems="center">
                            <img src={creditLogo} alt="WMG logo" />
                        </Box>
                    </Typography>
                </Box>
            </Box>
        </Box>
    </Box>
);

export default Footer;
