import { ExpandMore } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, Divider, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { approveJobObligation, getJobSummaryStatus } from 'services/JobService';
import { JobLandingDetails } from 'types/job';
import TextSection from 'views/common/TextSection';

const JobObligation = () => {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();

    const [token, setToken] = useState<string>('');
    const [jobDetails, setJobDetails] = useState<JobLandingDetails>({
        id: -1,
        job_date: new Date(),
        workplace: {
            id: -1,
            name: '',
            map_url: '',
            address: '',
            authority: { id: -1, name: 'Authority Name' },
            type: { id: -1, name: 'Workplace Type Name' },
            working_hour: { id: -1, hour_interval: '', name: '' },
            contacts: []
        },
        created_at: new Date(),
        updated_at: new Date(),
        salary: 45,
        summary: {
            created_at: new Date(),
            started_at: new Date(),
            finished_at: new Date()
        }
    });
    const [status, setStatus] = useState<number>(1); // Loading, Details loaded, Error, Finished

    console.log(jobDetails);

    useEffect(() => {
        getToken();
    }, [query]);

    useEffect(() => {
        if (token !== '') {
            getJobSummaryStatus(token, setJobDetails, setStatus);
        }
    }, [token]);

    const getToken = async () => {
        const tokenParam = query.get('token') as string;
        setToken(tokenParam);
    };

    const handleApproveJob = async () => {
        setStatus(0);
        approveJobObligation(token, setStatus, true);
    };

    const handleDeclineJob = async () => {
        setStatus(0);
        approveJobObligation(token, setStatus, false);
    };

    const handleChangeJob = async () => {
        setStatus(0);
        approveJobObligation(token, setStatus, false);
    };

    return (
        <Dialog PaperProps={{ sx: { p: 0 } }} maxWidth="sm" open>
            <DialogContent sx={{ padding: 2 }}>
                {status !== 1 ? (
                    <Box sx={{ height: '350px', textAlign: 'center', minWidth: '30vw' }} position="relative">
                        {
                            {
                                0: (
                                    <Typography lineHeight="200px">
                                        <FormattedMessage id="loading" />
                                    </Typography>
                                ),
                                2: (
                                    <Typography lineHeight="200px">
                                        <FormattedMessage id="error-ocurred" />
                                    </Typography>
                                ),
                                3: (
                                    <Typography lineHeight="200px">
                                        <FormattedMessage id="you-approved-the-job" />
                                    </Typography>
                                ),
                                4: (
                                    <Typography lineHeight="200px">
                                        <FormattedMessage id="you-declined-the-job" />
                                    </Typography>
                                )
                            }[status]
                        }
                    </Box>
                ) : (
                    <Box sx={{ height: '350px' }} position="relative">
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mb={1}>
                            <Box>
                                <Typography variant="subtitle1" fontSize="14px" fontWeight={700}>
                                    {jobDetails.workplace.authority.name}
                                </Typography>
                                <Typography fontSize="12px">{jobDetails.workplace.name}</Typography>
                            </Box>
                            <ExpandMore color="primary" />
                        </Box>
                        <Divider />
                        <Grid container mt={1}>
                            <Grid item xs={6} mt={1}>
                                <TextSection label={<FormattedMessage id="workplace-name" />} content={jobDetails.workplace.name} />
                            </Grid>
                            <Grid item xs={6} mt={1}>
                                <TextSection label={<FormattedMessage id="workplace-type" />} content={jobDetails.workplace.type.name} />
                            </Grid>
                            <Grid item xs={6} mt={1}>
                                <TextSection
                                    label={<FormattedMessage id="start-date" />}
                                    content={format(new Date(jobDetails.job_date || ''), 'dd.MM.yyyy')}
                                />
                            </Grid>

                            <Grid item xs={6} mt={1}>
                                <TextSection label={<FormattedMessage id="salary" />} content={`₪ ${jobDetails.salary}/ hour`} />
                            </Grid>
                            <Grid item xs={6} mt={1}>
                                <TextSection
                                    label={<FormattedMessage id="working-hours" />}
                                    content={
                                        <Box>
                                            {jobDetails.workplace.working_hour.beginning_time}-
                                            {jobDetails.workplace.working_hour.finishing_time}
                                        </Box>
                                    }
                                />
                            </Grid>

                            <Grid item xs={6} mt={1}>
                                <TextSection label={<FormattedMessage id="institution-address" />} content={jobDetails.workplace.address} />
                            </Grid>
                            <Grid item xs={6} mt={1}>
                                <TextSection
                                    label={<FormattedMessage id="contact_person" />}
                                    content={
                                        jobDetails.workplace.contacts.length > 0 ? (
                                            <>
                                                <Typography fontSize="12px">{jobDetails.workplace.contacts[0].full_name}</Typography>
                                                <Typography fontSize="12px">{jobDetails.workplace.contacts[0].phone_number}</Typography>
                                            </>
                                        ) : (
                                            '-'
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} mt={1}>
                                <TextSection
                                    label={<FormattedMessage id="map-url" />}
                                    content={
                                        <a target="_blank" href={jobDetails.workplace.map_url} rel="noreferrer">
                                            <FormattedMessage id="follow-the-link" />
                                        </a>
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Box
                            mb={1}
                            sx={{ display: 'flex', justifyContent: 'space-between' }}
                            // position="absolute"
                            width="100%"
                            bottom={1}
                        >
                            <Typography lineHeight="40px" fontSize="10px" sx={{ color: (t) => t.palette.success.dark }}>
                                <FormattedMessage id="approved-date" />
                                {jobDetails.summary && format(new Date(jobDetails.summary.created_at), 'dd.MM.yyyy')}
                            </Typography>
                            <Box display="flex">
                                <Button color="error" variant="outlined" onClick={handleDeclineJob} sx={{ mr: 2 }}>
                                    <FormattedMessage id="to decline" />
                                </Button>
                                <Button color="success" variant="outlined" onClick={handleApproveJob} sx={{ mr: 2 }}>
                                    <FormattedMessage id="to approve" />
                                </Button>
                                {/* <Button color="secondary" variant="outlined" onClick={handleChangeJob}>
                                    <FormattedMessage id="to change job" />
                                </Button> */}
                            </Box>
                        </Box>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default JobObligation;
