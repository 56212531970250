export const ERROR_COLOR_MSG_TYPE_IDS = [3, 4, 7, 8, 9];
export const SECONDARY_COLOR_MSG_TYPE_IDS = [2, 5, 6];
export const SUCCESS_COLOR_MSG_TYPE_IDS = [1];

export const getDashboardColor = (message_type_id: number): { dark: string; light: string } => {
    if (ERROR_COLOR_MSG_TYPE_IDS.includes(message_type_id)) return { light: '#FEEBEE', dark: '#F44336' };
    if (SECONDARY_COLOR_MSG_TYPE_IDS.includes(message_type_id)) return { dark: '#673AB7', light: '#EDE7F6' };
    return { dark: '#2B9788', light: '#D7F2EE' };
};

export default getDashboardColor;
