import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import PersonalAreaAuthGuard from 'views/pages/auth/PersonalAreaAuthGuard';
import WorkerAreaLayout from 'layout/WorkerAreaLayout';
import UpdateDetails from 'views/pages/PersonalArea/UpdateDetails/UpdateDetails';
import ReportHours from 'views/pages/PersonalArea/report-hours/ReportHours';
import MyDocuments from 'views/my-documents/MyDocuments';
import WorkerReport from 'views/pages/PersonalArea/WorkersReport/WorkersReport';
// import Header from '../layout/MainLayout/Header';

// sample page routing
const PersonalArea = Loadable(lazy(() => import('views/pages/PersonalArea/PersonalArea')));
const JobHistory = Loadable(lazy(() => import('views/pages/PersonalArea/JobHistory/JobHistory')));
// ==============================|| MAIN ROUTING ||============================== //

const PersonalAreaRoutes = {
    path: '/',
    element: (
        <>
            <PersonalAreaAuthGuard>
                <WorkerAreaLayout />
            </PersonalAreaAuthGuard>
        </>
    ),
    children: [
        {
            path: '/personal-area',
            element: <UpdateDetails />
        },
        // {
        //     path: '/personal-area/job-history',
        //     element: <JobHistory />
        // },
        {
            path: '/personal-area/update-details',
            element: <UpdateDetails />
        }
        // {
        //     path: '/personal-area/hours-report',
        //     element: <ReportHours />
        // },
        // {
        //     path: '/personal-area/workers-report',
        //     element: <WorkerReport />
        // },
        // {
        //     path: '/personal-area/my-documents',
        //     element: <MyDocuments />
        // }
    ]
};

export default PersonalAreaRoutes;
