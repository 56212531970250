import {
    OutlinedInput,
    InputAdornment,
    Box,
    IconButton,
    styled,
    Typography,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { PrintTwoTone, FilterList, AddSharp, SvgIconComponent } from '@mui/icons-material';
import { IconSearch } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import GlobalDrawer from './GlobalDrawer';
import GlobalFilter from './GlobalFilter';
import barcode from '../../assets/images/icons/barcode.svg';
import { Filter } from 'types/paginationInfo';
import { useTheme } from '@mui/styles';
import { display } from '@mui/system';

export interface editProps {
    setAdd?: (value: boolean) => void;
    label?: string;
    printRef: React.RefObject<HTMLElement>;
    setFilter?: (value: boolean) => void;
    filter?: boolean;
    setSearchText?: (value: string) => void;
    disabled?: boolean;
    setFilters?: (val: Filter[]) => void;
    filters?: Filter[];
    availableFilters?: any;
    columnCount?: number;
    dividerHeight?: string;
    extraButtonIcon?: React.ReactNode;
    uploadIdsIcon?: React.ReactNode;
    selectAuthorityIcon?: React.ReactNode;
    onExtraButtonClick?: () => void;
    onUploadIdsClick?: () => void;
    onSelectAuthorityClick?: () => void;
    setFilterBy?: any;
    filterBy?: string;
}

const SubSearchSection = (props: editProps) => {
    const {
        availableFilters,
        filters,
        setFilters,
        disabled,
        setAdd = undefined,
        setFilter = undefined,
        label,
        printRef,
        filter = undefined,
        setSearchText = undefined,
        columnCount = 0,
        dividerHeight = '1px',
        extraButtonIcon,
        uploadIdsIcon,
        selectAuthorityIcon,
        onExtraButtonClick,
        onUploadIdsClick,
        onSelectAuthorityClick,
        setFilterBy,
        filterBy
    } = props;
    const theme = useTheme();

    const searchResults = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (setSearchText !== undefined) setSearchText(event.currentTarget.value);
    };

    const H3 = styled(Typography)({
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: '0em',
        textAlign: 'left',
        paddingLeft: '16px'
    });

    const WrapperIcon = styled(IconButton)({
        background: 'rgba(0, 0, 0, 0.18)',
        height: '32px',
        width: '32px',
        borderRadius: '12px'
    });

    const AddIconButton = styled(IconButton)(() => ({
        height: 'fit-content',
        marginRight: '14px',
        padding: '8px',
        backgroundColor: theme.palette.secondary.dark,
        '&:hover': {
            background: theme.palette.secondary.main
        }
    }));
    const pageStyle = `
        @media print {
            .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeader--sortable, .MuiDataGrid-columnHeader--sorted,
            .MuiDataGrid-cell, .MuiDataGrid-cell--textLeft {
                width: ${columnCount > 0 ? 100 / columnCount : 15}% !important;
                max-width: ${columnCount > 0 ? 100 / columnCount : 15}% !important;
                min-width: ${columnCount > 0 ? 100 / columnCount : 15}% !important;
                font-size: ${columnCount > 4 ? 11 : 12}px;
            }
            .MuiDataGrid-columnHeaderWrapper, .MuiDataGrid-dataContainer, .MuiDataGrid-viewport, .MuiDataGrid-renderingZone, .MuiDataGrid-row {
                min-width: 100% !important;
                width: 100% !important;
                margin-left: 0px;
            }
            div[data-field="action"] *,  div[data-field="action"],
            div[data-field="forms"] *, div[data-field="forms"] {
                display: none;
            }
            #print-area {
                overflow: visible !important; 
                width: 100%;
                height: 100%;
                position: fixed !important;
                top: 0;
                right:0;
                margin: 0px auto;
            }
        }
    `;
    return (
        <>
            <Box
                sx={{
                    pt: 1,
                    pr: 1,
                    width: '100%',
                    display: 'flex',
                    marginBottom: '24px',
                    justifyContent: setSearchText !== undefined ? 'space-between' : 'end',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'center'
                }}
            >
                {label && (
                    <H3 variant="h3" lineHeight="40px" sx={{ paddingInlineStart: { xs: '0', sm: '16px' } }}>
                        <FormattedMessage id={label} />
                    </H3>
                )}

                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center' }}>
                    {!!setFilterBy && (
                        <FormControl sx={{ mr: 2, mb: 1, mt: '3px', display: { xs: 'none', sm: 'block' } }}>
                            <InputLabel id="_select">סינון לפי</InputLabel>
                            <Select
                                sx={{ height: 35 }}
                                labelId="_select"
                                label="Filter By"
                                size="small"
                                displayEmpty
                                value={filterBy}
                                onChange={(e) => setFilterBy(e.target.value)}
                                autoWidth
                            >
                                <MenuItem value="byPersonalId">ת״ז</MenuItem>
                                {/* <MenuItem value="id">מס״ד</MenuItem> */}
                                <MenuItem value="byFirstName">שם פרטי</MenuItem>
                                <MenuItem value="byLastName">שם משפחה</MenuItem>
                                <MenuItem value="byEmail">דוא״ל</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    {/* search box */}
                    {setSearchText !== undefined && (
                        <OutlinedInput
                            sx={{ width: '235px', height: '35px', mr: { xs: 0, sm: 5 }, mb: { xs: 2, sm: 1 } }}
                            id="input-search-profile"
                            placeholder="חיפוש"
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconSearch stroke={1.5} size="1rem" />
                                </InputAdornment>
                            }
                            onChange={(e) => searchResults(e)}
                            size="small"
                            disabled={disabled}
                            endAdornment={
                                (label === 'my-tests' || label === 'reception-desk') && (
                                    <InputAdornment position="end">
                                        <WrapperIcon>
                                            <img src={barcode} alt="barcode" />
                                        </WrapperIcon>
                                    </InputAdornment>
                                )
                            }
                        />
                    )}
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        {/* plus icon */}
                        {setAdd !== undefined && (
                            <AddIconButton onClick={() => setAdd(true)}>
                                <AddSharp
                                    htmlColor={theme.palette.common.white}
                                    sx={{ margin: '0', height: '20px', width: '20px' }}
                                    fontSize="inherit"
                                />
                            </AddIconButton>
                        )}
                        {/* plus icon description */}
                        {setAdd !== undefined && label === 'job-list' && (
                            <H3
                                variant="h3"
                                lineHeight="40px"
                                sx={{
                                    fontFamily: 'Noto Sans Hebrew',
                                    sizeFont: '500',
                                    padding: '0',
                                    marginInlineEnd: { xs: '0', sm: '16px' }
                                }}
                            >
                                <FormattedMessage id="add new requset" />
                            </H3>
                        )}
                    </Box>
                    {setAdd !== undefined && label === 'job-list' && (
                        <Divider
                            orientation="vertical"
                            variant="fullWidth"
                            flexItem
                            sx={{
                                width: 1.5,
                                height: '40px',
                                backgroundColor: '#E7E7E7',
                                display: { xs: 'none', md: 'flex' }
                            }}
                        />
                    )}
                    {selectAuthorityIcon && onSelectAuthorityClick && (
                        <IconButton onClick={() => onSelectAuthorityClick()}>{selectAuthorityIcon}</IconButton>
                    )}
                    {uploadIdsIcon && onUploadIdsClick && <IconButton onClick={() => onUploadIdsClick()}>{uploadIdsIcon}</IconButton>}
                    {extraButtonIcon && onExtraButtonClick && (
                        <IconButton onClick={() => onExtraButtonClick()}>{extraButtonIcon}</IconButton>
                    )}
                    {/* hamburger icon */}
                    {setFilter !== undefined && (
                        <>
                            <IconButton
                                sx={{ height: '36px', width: '36px', marginRight: '8px', display: { xs: 'none', sm: 'block' } }}
                                id="demo-customized-button"
                                disabled={disabled}
                                onClick={() => setFilter(true)}
                            >
                                <FilterList sx={{ height: '20px', width: '20px' }} />
                            </IconButton>
                        </>
                    )}
                    {/* printer icon */}
                    {label !== 'reception-desk' && label !== 'reports-list' && (
                        <ReactToPrint
                            trigger={() => (
                                <IconButton
                                    sx={{
                                        height: '36px',
                                        width: '36px',
                                        display: { xs: 'none', sm: 'block' }
                                    }}
                                    disabled={disabled}
                                >
                                    <PrintTwoTone sx={{ height: '20px', width: '20px' }} />
                                </IconButton>
                            )}
                            content={() => printRef.current}
                            pageStyle={pageStyle}
                        />
                    )}
                </Box>
            </Box>
            <Divider sx={{ borderBottomWidth: dividerHeight, borderColor: theme.palette.primary.light }} />
            <GlobalDrawer
                width="320px"
                padding="16px"
                open={filter !== undefined && filter}
                onClose={() => setFilter && setFilter(false)}
                Component={
                    <GlobalFilter
                        availabeFilters={availableFilters}
                        filters={filters}
                        setFilters={setFilters}
                        onClose={() => setFilter && setFilter(false)}
                    />
                }
            />
        </>
    );
};

export default SubSearchSection;
