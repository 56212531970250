import { useState } from 'react';
import {
    Dialog,
    styled,
    Button,
    DialogActions,
    Typography,
    DialogContent,
    DialogContentText,
    IconButton,
    TextField,
    InputLabel
} from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import { ClearOutlined } from '@mui/icons-material';

export interface DialogProps {
    open: boolean;
    onClose: () => void;
    onDisable?: any;
    coloredText?: string;
    title1: string;
    title2?: string;
    subText?: string;
    labelButton?: string;
    cancelButton?: string;
    onCancel?: () => void;
    noteField?: boolean;
}

const DisableMethod = (props: DialogProps) => {
    const {
        labelButton,
        cancelButton,
        noteField,
        onCancel,
        onClose,
        onDisable = onClose,
        open,
        coloredText,
        title1,
        title2,
        subText = ''
    } = props;
    const [note, setNote] = useState<string>('');

    const ButtonStyle = styled(Button)(({ theme }) => ({
        height: '44px',
        width: '111px',
        borderRadius: '4px',
        padding: '12px, 16px, 12px, 16px',
        fontSize: '14px',
        lineHeight: '24px',
        color: `${theme.palette.primary.light}`,
        marginLeft: '25px !important'
    }));

    const TitleStyle = styled(Typography)(({ theme }) => ({
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'left',
        padding: 0
    }));

    const TextColor = styled('span')(({ theme }) => ({
        color: `${theme.palette.secondary.main} !important`
    }));

    return (
        <Dialog
            PaperProps={{ style: { padding: '40px' } }}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                <IconButton onClick={onClose} size="small" sx={{ margin: '-20px -10px 10px !important' }}>
                    <ClearOutlined />
                </IconButton>
            </Box>
            <TitleStyle variant="h3" sx={{ paddingLeft: 0, width: '411px' }} id="alert-dialog-title">
                <FormattedMessage id={title1} />
                <TextColor>
                    &nbsp;
                    {coloredText}
                    &nbsp;
                </TextColor>
                {title2}

                {/* <TextColor>&nbsp;{coloredText}&nbsp;</TextColor>
                <FormattedMessage id={title1} />

                {title2} */}
            </TitleStyle>
            <DialogContent sx={{ padding: 0, marginTop: '20px' }}>
                <DialogContentText id="alert-dialog-description">{subText}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ marginTop: '25px' }}>
                <Button
                    disabled={noteField && note === ''}
                    onClick={() => {
                        noteField ? onDisable(note) && setNote('') : onClose();
                    }}
                    color="error"
                >
                    <FormattedMessage id={cancelButton || 'cancel'} />
                </Button>
                <ButtonStyle variant="contained" onClick={onDisable}>
                    <FormattedMessage id={labelButton || 'disable'} />
                </ButtonStyle>
            </DialogActions>
            {noteField && (
                <>
                    <InputLabel htmlFor="note">אם לא ציין מדוע</InputLabel>
                    <TextField onChange={(e) => setNote(e.target.value)} id="note" size="small" fullWidth />
                </>
            )}
        </Dialog>
    );
};
export default DisableMethod;
