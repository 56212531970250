import React, { useEffect, useRef } from 'react';
import { storeUser } from './AuthActions';
import { UserData } from '../types/auth';

export default function AuthProvider(props: any) {
    const { userManager: manager, store, children } = props;
    const userManager: any = useRef();

    useEffect(() => {
        userManager.current = manager;
        const onUserLoaded = (user: UserData) => {
            store.dispatch(storeUser(user));
        };
        userManager.current.events.addUserLoaded(onUserLoaded);

        return () => {
            userManager.current.events.removeUserLoaded(onUserLoaded);
        };
    }, [manager, store]);

    return React.Children.only(children);
}
