import { Box, Rating, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { JobSummary } from 'types/worker';
import GlobalTable from 'views/common/GlobalTable';

export interface WorkerHistoryProps {
    jobHistory: JobSummary[];
    reducedVersion?: boolean;
}

const WorkerHistory = (props: WorkerHistoryProps) => {
    const { jobHistory, reducedVersion = false } = props;
    const theme = useTheme();
    const columns: GridColDef[] = [
        {
            field: 'id',
            sortable: false,
            renderHeader: () => (
                <Typography fontWeight={500} fontSize="12px">
                    <FormattedMessage id="job" />
                </Typography>
            ),
            renderCell: (params) => <Typography fontSize="10px">{params.value}</Typography>,
            hide: reducedVersion
        },
        {
            field: 'workplace',
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <Typography fontWeight={500} fontSize="12px">
                    <FormattedMessage id="workplace" />
                </Typography>
            ),
            renderCell: (params) => <Typography fontSize="10px">{params.row.workplace?.name}</Typography>
        },
        {
            field: 'rating',
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <Typography fontWeight={500} fontSize="12px">
                    <FormattedMessage id="rating" />
                </Typography>
            ),
            renderCell: (params) => (
                <Rating
                    sx={{
                        '& .MuiRating-iconFilled, .MuiRating-iconEmpty': {
                            color: '#36BDAA'
                        },
                        fontSize: '12px'
                    }}
                    defaultValue={Number(params?.row?.rating || params?.row?.pivot?.rating)}
                    precision={0.1}
                    readOnly
                />
            )
        },
        {
            field: 'job_date',
            sortable: false,
            flex: 1,
            renderHeader: () => (
                <Typography fontWeight={500} fontSize="12px">
                    <FormattedMessage id="date" />
                </Typography>
            ),
            renderCell: (params) => <Typography fontSize="10px">{format(new Date(params.value as string), 'dd.MM.yyyy')}</Typography>,
            hide: reducedVersion
        },
        {
            field: 'comment',
            sortable: false,
            flex: 1,
            renderHeader: () => (
                <Typography fontWeight={500} fontSize="12px">
                    <FormattedMessage id="comment" />
                </Typography>
            ),
            renderCell: (params) => (
                <Typography
                    fontSize="10px"
                    sx={{
                        overflow: 'hidden',
                        whiteSpace: reducedVersion ? 'nowrap' : 'wrap',
                        height: reducedVersion ? 'auto' : '4em',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {params?.value || params?.row?.pivot?.comment}
                </Typography>
            )
        }
    ];
    return (
        <>
            <Box>
                <GlobalTable
                    borderColor={theme.palette.secondary.light}
                    rows={jobHistory}
                    columns={columns}
                    checkbox={false}
                    heightRow={reducedVersion ? 30 : 45}
                />
            </Box>
        </>
    );
};

export default WorkerHistory;
