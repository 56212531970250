// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUser, IconUsers } from '@tabler/icons';

import { PeopleAltOutlined, AccountBalance, MailOutline } from '@mui/icons-material';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';

import { ReactComponent as Users } from '../assets/images/icons/Users.svg';
import { ReactComponent as Calendar } from '../assets/images/icons/Calendar.svg';
import { ReactComponent as Case } from '../assets/images/icons/Case.svg';
import { ReactComponent as Chart } from '../assets/images/icons/Chart.svg';
import { ReactComponent as Settings } from '../assets/images/icons/Settings.svg';

// constant
const icons = {
    IconUser,
    IconUsers,
    WorkTwoToneIcon,
    PeopleAltOutlined,
    AccountBalance,
    InsertInvitationOutlinedIcon,
    MailOutline,
    NotificationsIcon,
    SettingsIcon,
    Users,
    Calendar,
    Case,
    Chart,
    Settings
};

const systemManagement = {
    id: 'system-management',
    type: 'group',
    title: null,
    children: [
        {
            id: 'settings',
            title: <FormattedMessage id="settings" />,
            type: 'collapse',
            icon: icons.Settings,
            breadcrumbs: false,
            children: [
                {
                    id: 'users',
                    permission: 'User',
                    title: <FormattedMessage id="user-management" />,
                    type: 'item',
                    url: '/users'
                },
                {
                    id: 'user-groups',
                    permission: 'UserGroup',
                    title: <FormattedMessage id="user-groups" />,
                    type: 'item',
                    url: '/user-groups'
                },
                {
                    id: 'workplaces',
                    permission: 'Workplace',
                    title: <FormattedMessage id="workplaces" />,
                    type: 'item',
                    url: '/workplaces'
                },
                {
                    id: 'workplace-types',
                    permission: 'WorkplaceType',
                    title: <FormattedMessage id="workplace-types" />,
                    type: 'item',
                    url: '/workplace-types'
                },
                {
                    id: 'authorities',
                    permission: 'Authority',
                    title: <FormattedMessage id="authorities" />,
                    type: 'item',
                    url: '/authorities'
                },
                {
                    id: 'worker-types',
                    permission: 'WorkerType',
                    title: <FormattedMessage id="worker-types" />,
                    type: 'item',
                    url: '/worker-types'
                },
                {
                    id: 'worker-approvals',
                    permission: 'Worker',
                    title: <FormattedMessage id="worker-approval-system" />,
                    type: 'item',
                    url: '/worker-approvals'
                }
            ]
        }

        // {
        //     id: 'workers',
        //     title: <FormattedMessage id="workers" />,
        //     type: 'collapse',
        //     icon: icons.PeopleAltOutlined,
        //     breadcrumbs: false,
        //     children: [
        //         {
        //             id: 'worker-types',
        //             permission: 'WorkerType',
        //             title: <FormattedMessage id="worker-types" />,
        //             type: 'item',
        //             url: '/worker-types'
        //         },
        //         {
        //             id: 'workers',
        //             permission: 'Worker',
        //             title: <FormattedMessage id="workers" />,
        //             type: 'item',
        //             url: '/workers'
        //         },
        //         {
        //             id: 'worker-approvals',
        //             permission: 'Worker',
        //             title: <FormattedMessage id="worker-approval-system" />,
        //             type: 'item',
        //             url: '/worker-approvals'
        //         }
        //     ]
        // },
        // {
        //     id: 'workplaces',
        //     title: <FormattedMessage id="Workplaces" />,
        //     type: 'collapse',
        //     icon: icons.WorkTwoToneIcon,
        //     breadcrumbs: false,
        //     children: [
        //         {
        //             id: 'workplaces',
        //             permission: 'Workplace',
        //             title: <FormattedMessage id="workplaces" />,
        //             type: 'item',
        //             url: '/workplaces'
        //         },
        //         {
        //             id: 'workplace-types',
        //             permission: 'WorkplaceType',
        //             title: <FormattedMessage id="workplace-types" />,
        //             type: 'item',
        //             url: '/workplace-types'
        //         }
        //     ]
        // },
        // {
        //     id: 'user-management',
        //     title: <FormattedMessage id="user-management" />,
        //     type: 'collapse',
        //     icon: icons.IconUser,
        //     breadcrumbs: false,
        //     children: [
        //         {
        //             id: 'users',
        //             permission: 'User',
        //             title: <FormattedMessage id="user-list" />,
        //             type: 'item',
        //             url: '/users'
        //         },
        //         {
        //             id: 'user-groups',
        //             permission: 'UserGroup',
        //             title: <FormattedMessage id="user-groups" />,
        //             type: 'item',
        //             url: '/user-groups'
        //         }
        //     ]
        // },

        // {
        //     id: 'authorities',
        //     icon: icons.AccountBalance,
        //     breadcrumbs: false,
        //     permission: 'Authority',
        //     title: <FormattedMessage id="authorities" />,
        //     type: 'item',
        //     url: '/authorities'
        // }
    ]
};

export default systemManagement;
