import { useEffect, useState } from 'react';
// material-ui
import { Typography, Box } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
// import menuItem from 'menu-items';
// import menuSettings from 'menu-settings';

import { NavItemType } from 'types';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList: React.FC<{ menuItems: { items: NavItemType[] } }> = ({ menuItems }) => {
    const [allowedMenuItems, setAllowedItems] = useState<NavItemType[]>();
    const [permissions, setPermissions] = useState<string[]>();

    const filterAllowedItems = (menuArr: any[]) => {
        const allowedArr: Array<any> = [];

        menuArr.forEach((item: any) => {
            const newItem = item;
            if (item.children) {
                newItem.children = filterAllowedItems(item.children);
                if (newItem.children.length > 0) {
                    allowedArr.push(newItem);
                }
            } else if (permissions && permissions.includes(item.permission)) {
                allowedArr.push(newItem);
            }
        });
        return allowedArr;
    };

    useEffect(() => {
        if (permissions) setAllowedItems(filterAllowedItems(menuItems.items));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions]);

    useEffect(() => {
        const arr = JSON.parse(localStorage.getItem('permissions') || '[]');
        const permissionsArr: string[] = arr.map((x: any) => x.permission_name);
        setPermissions(permissionsArr);
    }, []);

    const navItems =
        allowedMenuItems &&
        allowedMenuItems.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu List Error
                        </Typography>
                    );
            }
        });

    return <>{navItems}</>;
};

export default MenuList;
