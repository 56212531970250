/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { Box, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { toIntl } from 'utils/helpers';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 2px)',
        right: 'calc(50% + 2px)'
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.secondary.main
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.secondary.main
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1
    }
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: theme.palette.secondary.main
    }),
    '& .QontoStepIcon-completedIcon': {
        color: theme.palette.secondary.main,
        zIndex: 1,
        fontSize: 12
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
    }
}));

function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? <div className="QontoStepIcon-circle QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
        </QontoStepIconRoot>
    );
}

export interface ApprovalStepperProps {
    status: number;
    statuses: { id: string; label: string }[];
    onStatusClick?: (selected: string) => void;
    showOptions?: boolean;
    deactivateStatus?: any;
}

export default function ApprovalStepper(props: ApprovalStepperProps) {
    const { status, statuses, onStatusClick, showOptions, deactivateStatus } = props;
    const statusesInfo: { approved_status: number; disapproved_statuses: [number] } = JSON.parse(
        localStorage.getItem('worker_statuses_info') || '{}'
    );

    return (
        <>
            <Stepper
                alternativeLabel
                activeStep={statusesInfo?.disapproved_statuses?.includes(status) ? 0 : status - 1}
                connector={<QontoConnector />}
            >
                {statuses
                    .filter((s) => !statusesInfo?.disapproved_statuses?.includes(Number(s.id)))
                    .map((s, key) => (
                        <Step key={s.id}>
                            <StepLabel
                                StepIconComponent={QontoStepIcon}
                                componentsProps={{
                                    label: { style: { fontSize: '12px', fontWeight: 400 } }
                                }}
                                sx={{ cursor: onStatusClick && status + 1 === Number(s.id) ? 'pointer !important' : 'auto' }}
                                onClick={() => status + 1 === Number(s.id) && onStatusClick?.(s.id)}
                            >
                                <FormattedMessage id={s.label} />
                            </StepLabel>
                        </Step>
                    ))}
            </Stepper>
            {showOptions === false ? (
                ''
            ) : (
                <Box sx={{ marginTop: { xs: 2, md: 0.5 } }}>
                    <FormGroup aria-label="position" row>
                        {statuses
                            .filter((s) => statusesInfo?.disapproved_statuses?.includes(Number(s.id)))
                            .map((s, key) => (
                                <FormControlLabel
                                    value={s.id}
                                    sx={{ fontSize: '16px !important' }}
                                    control={
                                        <Switch
                                            onChange={(e) => {
                                                if (Number(s.id) === status) {
                                                    deactivateStatus(e.target);
                                                }
                                                if (e.target.checked) onStatusClick?.(s.id);
                                            }}
                                            checked={status === Number(s.id)}
                                            size="small"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Box sx={{ fontSize: 12 }}>
                                            <FormattedMessage id={s.label} />
                                        </Box>
                                        // <Typography fontSize={12} id="hi">
                                        //     {s.label}
                                        // </Typography>
                                    }
                                    labelPlacement="start"
                                />
                            ))}
                    </FormGroup>
                </Box>
            )}
        </>
    );
}
