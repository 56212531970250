import { PictureAsPdfTwoTone } from '@mui/icons-material';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { openFile } from 'services/WorkerService';
import { Worker } from 'types/worker';
import MainCard from 'ui-component/cards/MainCard';

export interface WorkerFileProps {
    worker: Worker;
    type: 'optional' | 'required';
}
const WorkerFile = (props: WorkerFileProps) => {
    const { worker, type } = props;
    const theme = useTheme();
    return (
        <MainCard
            headerSX={{ padding: '4px !important' }}
            border
            sx={{ border: `1px solid ${theme.palette.secondary.light} !important`, p: 0, width: '100%' }}
            contentSX={{ p: 0, paddingBottom: '0px !important', minWidth: '250px' }}
            title={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '25px', marginTop: '8px', ml: 2 }}>
                    <Typography variant="subtitle1" fontSize="12px" sx={{ marginBottom: '30px' }}>
                        <FormattedMessage id={type === 'required' ? 'required-files' : 'other-files'} />
                    </Typography>
                </Box>
            }
        >
            {worker.files && worker.files.length > 0 ? (
                worker?.files.map((attachment, index) => (
                    <Box key={`worker-file-${index}`} sx={{ height: '60px' }}>
                        <Divider orientation="horizontal" sx={{ borderColor: theme.palette.secondary.light }} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <IconButton key={`file${index}`} onClick={() => openFile(attachment.id)} sx={{ ml: 2, mt: 1 }}>
                                    <PictureAsPdfTwoTone fontSize="medium" />
                                </IconButton>
                                <Box sx={{ mt: 2 }}>
                                    <Typography fontSize="12px" fontWeight="bold">
                                        {attachment.file_name}
                                    </Typography>
                                    <Typography variant="caption">1.23 MB</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                ))
            ) : (
                <Box sx={{ height: '100px', mt: 6, textAlign: 'center', width: '100%', minWidth: '270px' }}>
                    <Typography variant="caption" sx={{ color: theme.palette.grey[500] }}>
                        <FormattedMessage id="empty-attached-files-title" />
                    </Typography>
                </Box>
            )}
        </MainCard>
    );
};

export default WorkerFile;
