import { styled, Pagination, Box, MenuItem, Select } from '@mui/material';
import { useTheme } from '@mui/styles';
import { DataGrid, GridColDef, GridSelectionModel, GridSortModel, MuiEvent } from '@mui/x-data-grid';
// import { useState } from 'react';
import { PaginationInfo } from 'types/paginationInfo';

export interface TableProps {
    columns: GridColDef[];
    rows: any[];
    selectionModel?: GridSelectionModel;
    onSelectionModelChange?: (value: GridSelectionModel) => void;
    // height?: number,
    checkbox?: boolean;
    heightRow?: number;
    onPageChange?: (page: number) => void;
    paginationInfo?: PaginationInfo;
    page?: number;
    onPageSizeChange?: (value: number) => void;
    onCellClick?: (params: any, event: MuiEvent<React.MouseEvent>) => void;
    disableSelection?: boolean;
    sortModel?: GridSortModel;
    onSortModelChange?: (model: GridSortModel) => void;
    onCellEditCommit?: (params: any, event: MuiEvent) => void;
    borderColor?: string;
    expandedId?: number;
}

const DataTable = (props: TableProps) => {
    const theme1 = useTheme();
    const {
        page,
        paginationInfo,
        onPageChange,
        rows,
        columns,
        selectionModel,
        onSelectionModelChange,
        checkbox = false,
        heightRow = 52,
        onPageSizeChange,
        onCellClick,
        disableSelection = true,
        sortModel,
        onSortModelChange,
        onCellEditCommit,
        borderColor = theme1.palette.primary.light,
        expandedId
    } = props;
    // const [pageSize, setPageSize] = useState<number>(5)
    const CustomPagination = () => {
        const PaginationStyle = styled(Pagination)(({ theme }) => ({
            '& .Mui-selected': {
                color: `${theme.palette.primary.light} !important`
            },
            '@media print': {
                display: 'none'
            }
        }));

        const SelectStyled = styled(Select)({
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        });

        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <PaginationStyle
                    count={paginationInfo?.last_page}
                    color="primary"
                    page={page}
                    onChange={(event, newPage) => {
                        onPageChange?.(newPage);
                    }}
                />
                <SelectStyled
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={paginationInfo?.per_page}
                    onChange={(e: any) => onPageSizeChange?.(e.target.value)}
                >
                    <MenuItem value={50}>50 שורות</MenuItem>
                    <MenuItem value={80}>80 שורות</MenuItem>
                    <MenuItem value={100}>100 שורות</MenuItem>
                </SelectStyled>
            </Box>
        );
    };

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        '&.MuiDataGrid-root  .MuiDataGrid-cell:focus': {
            outline: 'none'
        },
        '&.MuiDataGrid-root  .MuiDataGrid-columnHeader:focus': {
            outline: 'none'
        },
        '& .MuiDataGrid-columnHeader': {
            color: theme.palette.text.dark,
            height: '50px'
        },
        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            whiteSpace: 'break-spaces !important',
            lineHeight: 1.2
        },
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-root': {
            border: 'none',
            outline: 'none'
        },
        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
            border: 'none'
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
            '& .MuiDataGrid - root.MuiDataGrid - cell: focus': {
                outline: 'none',
                border: 'none'
            }
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none'
        },
        '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
            justifyContent: 'start',
            '& .MuiDataGrid-selectedRowCount': {
                width: '120px'
            }
        },
        '& .MuiDataGrid-row': {
            display: 'flex !important',
            alignItems: 'center !important'
        },
        '& .MuiDataGrid-cell': {
            display: 'flex !important',
            alignItems: expandedId ? 'flex-start !important' : 'center !important',
            lineHeight: '15px !important',
            whiteSpace: 'break-spaces !important',
            borderBottom: `1px solid ${borderColor} !important`,
            overflow: 'visible',
            paddingTop: expandedId ? '15px' : 'auto'
        },
        '& .MuiDataGrid-renderingZone': {
            maxHeight: '110% !important'
        },
        '& .MuiDataGrid-columnHeaders': {
            lineHeight: 1.2,
            borderBottom: `1px solid ${borderColor} !important`
        }
    }));

    return (
        <StyledDataGrid
            rows={rows}
            columns={columns}
            pageSize={paginationInfo?.per_page}
            components={{
                Pagination: paginationInfo && paginationInfo?.total > paginationInfo?.per_page ? CustomPagination : null
            }}
            checkboxSelection={checkbox}
            onSelectionModelChange={(ids) => {
                onSelectionModelChange?.(ids);
            }}
            selectionModel={selectionModel && rows.filter((r) => selectionModel.includes(r.id)).map((el) => el.id)}
            disableSelectionOnClick={disableSelection}
            disableColumnFilter
            disableColumnMenu
            onCellClick={onCellClick}
            onSortModelChange={onSortModelChange}
            sortingOrder={['asc', 'desc']}
            sortModel={sortModel}
            autoHeight
            onCellEditCommit={onCellEditCommit}
            rowHeight={heightRow}
            hideFooter={paginationInfo === undefined || paginationInfo?.total < Number(paginationInfo?.per_page)}
            getRowHeight={(params) => (expandedId && Number(params.id) === expandedId ? 275 : heightRow)}
            headerHeight={heightRow}
        />
    );
};
export default DataTable;
