import { KeyboardArrowUp, PictureAsPdfTwoTone } from '@mui/icons-material';
import { Box, Collapse, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { GridRowId } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import { openFile } from 'services/WorkplaceService';
import TextSection from 'views/common/TextSection';
import { format } from 'date-fns';

export interface JobDetailExpansionProps {
    cellId: GridRowId;
    clickedIndex: number;
    row: any;
    setClickedIndex: (value: number) => void;
}

const JobDetailExpansion = (props: JobDetailExpansionProps) => {
    const { cellId, clickedIndex, row, setClickedIndex } = props;
    const theme = useTheme();

    return (
        <Collapse
            in={cellId === clickedIndex}
            sx={{
                width: '78vw',
                position: 'relative',
                mt: 2,
                height: '210px'
            }}
        >
            <Divider sx={{ borderColor: theme.palette.primary.light, ml: '-15px', mr: '-18px', mt: '-10px' }} />
            <Box display="flex" justifyContent="space-between" mt={2}>
                <Box flex={2}>
                    <Typography variant="subtitle1">
                        <FormattedMessage id="additional-data" />
                    </Typography>
                    <Divider sx={{ borderColor: theme.palette.secondary.light, mt: 0.5, width: '90%' }} />
                    <Grid container mt={1.5} columns={14}>
                        <Grid item xs={2}>
                            <TextSection label={<FormattedMessage id="workplace-id" />} content={row.workplace.id} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextSection label={<FormattedMessage id="workplace-address" />} content={row.workplace.address} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextSection label={<FormattedMessage id="workplace-type" />} content={row.workplace.type.name} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextSection
                                label={<FormattedMessage id="job-offer-date" />}
                                content={row.job_date ? format(new Date(row.job_date as string), 'dd.MM.yyyy') : '-'}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextSection
                                label={<FormattedMessage id="working-hours" />}
                                content={row.workplace.working_hour.hour_interval}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextSection label={<FormattedMessage id="number-of-children" />} content={row.workplace.number_of_children} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextSection
                                label={<FormattedMessage id="contact-person-#1" />}
                                content={
                                    row.workplace.contacts.length > 0
                                        ? `${row.workplace.contacts[0].full_name} ${row.workplace.contacts[0].phone_number} `
                                        : '-'
                                }
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextSection
                                label={<FormattedMessage id="contact-person-#2" />}
                                content={
                                    row.workplace.contacts.length > 1
                                        ? `${row.workplace.contacts[1].full_name} ${row.workplace.contacts[1].phone_number} `
                                        : '-'
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box flex={0.6} display="flex">
                    <Divider sx={{ borderColor: theme.palette.secondary.light }} orientation="vertical" />
                    <Box ml={1}>
                        <Typography variant="subtitle1">
                            <FormattedMessage id="attached-files" />
                        </Typography>
                        <Grid container mt={1}>
                            {/* {row.files.map((file: any, index: any) => (
                                <Grid item xs={4}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <IconButton key={`file${index}`} onClick={() => openFile(file.id)}>
                                            <PictureAsPdfTwoTone fontSize="small" />
                                        </IconButton>
                                        <Box sx={{ mt: 1 }}>
                                            <Typography fontSize="12px" fontWeight="bold">
                                                {file.file_name}
                                            </Typography>
                                            <Typography fontSize="10px" variant="caption">
                                                1.23 MB
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))} */}
                        </Grid>
                    </Box>
                </Box>
            </Box>
            <IconButton
                color="secondary"
                sx={{ padding: 0, position: 'absolute', bottom: 5, right: 0 }}
                onClick={() => setClickedIndex(-1)}
            >
                <KeyboardArrowUp />
            </IconButton>
        </Collapse>
    );
};

export default JobDetailExpansion;
