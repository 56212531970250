import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import Logo from 'assets/images/logo.svg';

// ==============================|| MAIN LOGO ||============================== //
interface LogoParams {
    height?: number;
    color?: 'black' | 'color' | undefined;
}
const LogoSection = ({ height, color }: LogoParams) => (
    <Link component={RouterLink} to="/messages-dashboard" sx={{ display: 'inline-block' }}>
        <img src={Logo} style={color === 'black' ? { filter: 'grayscale(100%)' } : undefined} alt="Berry" height={height || '37'} />
    </Link>
);

export default LogoSection;
