import { STORE_USER, USER_SIGNED_OUT, USER_EXPIRED, STORE_USER_ERROR, LOADING_USER } from '../store/actions';
import setAuthHeader from './AxiosHeaders';
import { UserData } from '../types/auth';

export const storeUser = (user: UserData) => {
    setAuthHeader(user.access_token);
    return {
        type: STORE_USER,
        payload: user
    };
};

export const loadingUser = () => ({ type: LOADING_USER });

export const storeUserError = () => ({ type: STORE_USER_ERROR });

export const userExpired = () => ({ type: USER_EXPIRED });

export const userSignedOut = () => ({ type: USER_SIGNED_OUT });
