import { useState, useEffect } from 'react';
import { styled, Typography, Box, IconButton, Divider, TextField, MenuItem, Button } from '@mui/material';
import { IconX } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';
import { Filter } from 'types/paginationInfo';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import 'dayjs/locale/en-gb';

export interface globalFilterProps {
    onClose: () => void;
    filters?: Filter[];
    setFilters?: (val: Filter[]) => void;
    availabeFilters?: any;
}

export interface labelProps {
    label: string;
    icon: string;
    options: Array<{ type: string; name: string; label: string }>;
}

export interface numberProps {
    label: string;
    icon: string;
    options: Array<{
        type: string;
        name: string;
        label: string;
    }>;
}

export interface selectProps {
    label: string;
    icon: string;
    options: Array<{
        type: string;
        name: string;
        label: string;
        values: { [key: string]: string };
    }>;
}

export interface datetimeProps {
    label: string;
    icon: string;
    options: Array<{
        type: string;
        name: string;
        label: string;
    }>;
}

const GlobalFilter = (props: globalFilterProps) => {
    const { onClose, filters, setFilters, availabeFilters } = props;
    const theme = useTheme();
    const [labelSection, setLabelSection] = useState<labelProps>();
    const [numberSection, setNumberSection] = useState<numberProps>();
    const [selectSection, setSelectSection] = useState<selectProps>();
    const [datetimeSection, setDatetimeSection] = useState<datetimeProps>();
    const [newFilters, setNewFilters] = useState<Filter[]>([]);

    useEffect(() => {
        availabeFilters.forEach((element: any) => {
            const data: any = element.items;
            switch (element.title) {
                case 'label':
                    setLabelSection(data);
                    break;
                case 'number':
                    setNumberSection(data);
                    break;
                case 'select':
                    setSelectSection(data);
                    break;
                case 'datetime':
                    setDatetimeSection(data);
                    break;
            }
        });
    }, [availabeFilters]);

    const TitleText = styled(Typography)({
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textAlign: 'left',
        margin: 0
    });

    const SubText = styled(Typography)({
        fontSize: '16px'
    });

    const ButtonStyled = styled(Button)({
        height: '44px',
        width: '100px',
        fontSize: '12px'
    });

    useEffect(() => {
        setNewFilters(filters || []);
    }, [filters]);

    return (
        <>
            <Box mt="12px" display="flex" justifyContent="space-between" alignItems="center">
                <TitleText variant="h3">
                    <FormattedMessage id="filters" />
                </TitleText>
                <IconButton onClick={onClose}>
                    <IconX height="14px" width="14px" />
                </IconButton>
            </Box>
            {labelSection && (
                <>
                    <Box mt="28px" mb="24px">
                        <SubText variant="body1">
                            <FormattedMessage id="label" />
                        </SubText>
                        {labelSection?.options.map((item, index) => (
                            <TextField
                                key={`text-field-${index}`}
                                sx={{ mt: 2 }}
                                fullWidth
                                label={<FormattedMessage id={item.label} />}
                                value={newFilters.find((f) => f.name === item.name)?.value || ''}
                                onChange={(e) => {
                                    const removedKeyFilter = newFilters.filter((obj) => obj.name !== item.name);
                                    setNewFilters([...removedKeyFilter, { name: item.name, value: e.target.value }]);
                                }}
                            />
                        ))}
                    </Box>
                    <Divider />
                </>
            )}
            {numberSection && (
                <>
                    <Box mt="28px" mb="24px">
                        <SubText variant="body1">
                            <FormattedMessage id="number-type" />
                        </SubText>
                        {numberSection?.options.map((item, index) => (
                            <TextField
                                key={`text-field-number${index}`}
                                sx={{ mt: 2 }}
                                fullWidth
                                label={<FormattedMessage id={item.label} />}
                                type="number"
                                value={newFilters.find((f) => f.name === item.name)?.value || ''}
                                onChange={(e) => {
                                    const removedKeyFilter = newFilters.filter((obj) => obj.name !== item.name);
                                    setNewFilters([...removedKeyFilter, { name: item.name, value: e.target.value }]);
                                }}
                            />
                        ))}
                    </Box>
                    <Divider />
                </>
            )}
            {selectSection && selectSection?.options.filter((e) => e.values).length === selectSection?.options.length && (
                <>
                    <Box mt="28px" mb="24px">
                        <SubText variant="body1">
                            <FormattedMessage id="select" />
                        </SubText>
                        {selectSection?.options.map((item, index) => (
                            <TextField
                                key={`text-field-select${index}`}
                                sx={{ mt: 2 }}
                                label={<FormattedMessage id={item.label} />}
                                fullWidth
                                select
                                value={newFilters.find((f) => f.name === item.name)?.value || null}
                                onChange={(e) => {
                                    const removedKeyFilter = newFilters.filter((obj) => obj.name !== item.name);
                                    setNewFilters([...removedKeyFilter, { name: item.name, value: e.target.value }]);
                                }}
                            >
                                {Object.keys(item.values).map((key: string) => (
                                    <MenuItem key={key} value={key}>
                                        {item.label === 'Status' ? <FormattedMessage id={item.values[key]} /> : item.values[key]}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ))}
                    </Box>
                    <Divider />
                </>
            )}
            {datetimeSection && (
                <>
                    <Box mt="28px" mb="23px">
                        <SubText variant="body1">
                            <FormattedMessage id="date-type" />
                        </SubText>
                        {datetimeSection?.options.map((item, index) => (
                            <Box sx={{ mt: 2 }} key={`date-field-${index}`}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label={<FormattedMessage id={item.label} />}
                                        value={newFilters.find((f) => f.name === item.name)?.value || null}
                                        onChange={(date) => {
                                            if (date) {
                                                const removedKeyFilter = newFilters.filter((obj) => obj.name !== item.name);
                                                setNewFilters([
                                                    ...removedKeyFilter,
                                                    { name: item.name, value: format(new Date(date), 'yyyy-MM-dd') }
                                                ]);
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Box>
                        ))}
                    </Box>
                    <Divider />
                </>
            )}
            <Box mt="65px" mb="37px" display="flex" justifyContent="space-between">
                <ButtonStyled onClick={() => setNewFilters([])} variant="outlined">
                    <FormattedMessage id="reset-btn" />
                </ButtonStyled>
                <ButtonStyled
                    onClick={() => {
                        onClose();
                        if (setFilters) {
                            setFilters(newFilters);
                        }
                    }}
                    variant="contained"
                    sx={{ color: theme.palette.background.paper }}
                >
                    <FormattedMessage id="apply-btn" />
                </ButtonStyled>
            </Box>
        </>
    );
};
export default GlobalFilter;
