import axios from 'axios';
import { Dayjs } from 'dayjs';
import { PaginationInfo, RequestParams } from 'types/paginationInfo';
import { Candidates, JobLandingDetails, Job } from 'types/job';

export const getAvailableWorkplaces = async (setAvailableWorkplaces: (value: { id: number; name: string }[]) => void) => {
    try {
        const response = await axios.get('/job/available-workplaces');
        setAvailableWorkplaces(response.data.data);
    } catch (err) {
        console.error(`error in getting workplaces ${err}`);
    }
};

export const disableJob = async (id: string) => {
    try {
        await axios.delete(`/job/${id}`);
    } catch (err) {
        console.error(`Error disabling job ${err}`);
    }
};

export const getPreview = async (setPreview: (value: { workers_count: number }) => void, job: { job_date: Dayjs; workplaces: any[] }) => {
    try {
        const response = await axios.get('/job/preview', {
            params: {
                workplaces: job.workplaces.map((item) => item.id),
                job_date: job.job_date.format('DD-MM-YYYY')
            }
        });
        setPreview(response.data);
    } catch (err) {
        console.error(`error getting preview ${err}`);
    }
};

export const saveJob = async (job: { job_date: Dayjs | null; workplaces: any[] }) => {
    try {
        job.workplaces = job.workplaces.map((item) => item.id);
        await axios.post('/job', job);
        return true;
    } catch (err) {
        console.error(`error in saving job ${err}`);
        return false;
    }
};

export const getCandidates = async (
    setCandidates: (val: Candidates[]) => void,
    requestParams: RequestParams,
    setPaginationInfo?: (val: PaginationInfo) => void
) => {
    try {
        const params: any = {
            page: requestParams.page,
            per_page: requestParams.per_page,
            sort: `${requestParams.sort[0].sort === 'desc' ? '-' : ''}${requestParams.sort[0].field}`
        };

        requestParams.filters?.forEach((filter) => {
            params[`filter[${filter.name}]`] = filter.value;
        });

        // Make request
        const response = await axios.get('/job/candidates', { params });

        let availableFilters: any = [];
        response.data.workers.filters.forEach((filter: any) => {
            availableFilters = [...availableFilters, ...filter.items.options];
        });

        setPaginationInfo?.({
            total: response.data.workers.total,
            last_page: response.data.workers.last_page,
            per_page: response.data.workers.per_page,
            sortable_fields: availableFilters.map((a: any) => a.name),
            available_filters: response.data.workers.filters
        });

        // Set Candidates
        const candidatesData: Candidates[] = [];

        response.data.workers.data.forEach((item: Candidates) => {
            if (item.jobs.length > 0) {
                candidatesData.push({
                    ...item,
                    user_name: `${item.user.first_name} ${item.user.last_name}`,
                    applying_at: item.jobs[0]?.applying_at || null,
                    employment: item.type.name
                });
            }
        });

        setCandidates(candidatesData);
    } catch (err) {
        console.error(`error in getting candidates ${err}`);
    }
};

export const getJobList = async (
    setJobs: (val: any[]) => void,
    requestParams: RequestParams,
    setPaginationInfo?: (val: PaginationInfo) => void
) => {
    try {
        const params: any = {
            page: requestParams.page,
            per_page: requestParams.per_page,
            sort: `${requestParams.sort[0].sort === 'desc' ? '-' : ''}${requestParams.sort[0].field}`
        };

        requestParams.filters?.forEach((filter) => {
            params[`filter[${filter.name}]`] = filter.value;
        });

        // Make request
        const response = await axios.get('/job/list', { params });

        let availableFilters: any = [];
        response.data.jobs.filters.forEach((filter: any) => {
            availableFilters = [...availableFilters, ...filter.items.options];
        });

        setPaginationInfo?.({
            total: response.data.jobs.total,
            last_page: response.data.jobs.last_page,
            per_page: response.data.jobs.per_page,
            sortable_fields: availableFilters.map((a: any) => a.name),
            available_filters: response.data.jobs.filters
        });

        // Set Jobs
        const jobsData: any[] = [];

        response.data.jobs.data.forEach((item: any) => {
            jobsData.push({
                ...item,
                name: item.workplace.name,
                region: item.workplace.authority.region.name,
                status: item.status.name,
                status_updated_at: item.status_updated_at,
                candidates: { accepted: item.candidates, no_response: item.waiting_apply_workers },
                rating: 3
            });
        });

        setJobs(jobsData);
    } catch (err) {
        console.error(`error in getting jobs ${err}`);
    }
};

export const getJobDetails = async (
    id: string,
    setJob: (value: any) => void,
    setStatus: (value: number) => void,
    setRatingValue: (value: number) => void,
    setProblematic: (value: boolean) => void,
    setJobStatus: (value: number) => void
) => {
    try {
        const response = await axios.get(`job/${id}`);
        if (response.data.job.summary?.canceled_workers.length > 0) {
            response.data.job.summary.canceled_workers.forEach((x: any) => {
                x.name = `${x.user.first_name} ${x.user.last_name}`;
                x.phone_number = x.user.phone_number;
                x.position = 3;
            });
            setStatus(0);
        }
        if (response.data.job.summary?.refused_workers.length > 0) {
            response.data.job.summary.refused_workers.forEach((x: any) => {
                x.name = `${x.user.first_name} ${x.user.last_name}`;
                x.phone_number = x.user.phone_number;
                x.position = 2;
            });
            setStatus(0);
        }
        if (response.data.job.summary?.worker) {
            response.data.job.summary.worker.name = `${response.data.job.summary.worker.user.first_name} ${response.data.job.summary.worker.user.last_name}`;
            response.data.job.summary.worker.phone_number = response.data.job.summary.worker.user.phone_number;
            response.data.job.summary.worker.position = 1;
            setRatingValue(response.data.job.summary.rating);
            setProblematic(response.data.job.summary.problematic);
            setStatus(0);
        }

        if (!response.data.job.summary) setStatus(2);
        setJob(response.data.job);
        setJobStatus(response.data.job?.status.id);
        console.log('response.data.job?.status.id: ', response.data.job.status.id);
    } catch (err) {
        setStatus(2);
        console.error(`error getting job details ${err}`);
    }
};

export const saveWorkerComment = async (params: { id: number; comment: string }, setComment: (value: string) => void) => {
    const response = await axios.put(`job/save-comment`, params);
    if (response.status === 200) {
        setComment(response.data.comment);
    }
    return response.status;
};

export const markAsProblematic = async (params: any, setProblematic: (value: boolean) => void) => {
    try {
        const response = await axios.put(`job/mark-as-problematic`, params);
        if (response.status == 200) {
            setProblematic(true);
        }
    } catch (err) {
        console.error(`error updating job ${err}`);
    }
};

export const updateRating = async (params: any, setRatingValue: (value: number) => void, setJobStatus: (value: number) => void) => {
    try {
        const response = await axios.put(`job/update-rating`, params);
        if (response.status == 200) {
            setRatingValue(response.data.rating);
            setJobStatus(response.data.status);
        }
    } catch (err) {
        console.error(`error updating job ${err}`);
    }
};

export const cancelWorker = async (params: any, setJobStatus: (value: number) => void) => {
    try {
        const response = await axios.put(`job/cancel-worker`, params);
        if (response.status == 200) {
            setJobStatus(response.data.status);
        }
    } catch (err) {
        console.error(`error updating job ${err}`);
    }
};

export const getJobDetailsForApply = async (
    token: string,
    setJob: (value: JobLandingDetails) => void,
    setStatus: (value: number) => void
) => {
    try {
        const response = await axios.get(`job-offer?token=${token}`);
        setJob(response.data.job);
        setStatus(1);
    } catch (err) {
        setStatus(2);
        console.error(`error getting job details for apply ${err}`);
    }
};

export const applyToJob = async (token: string, setStatus: (value: number) => void) => {
    try {
        await axios.post(`job-offer/apply?token=${token}`);
        setStatus(3);
    } catch (err) {
        console.error(`Error applying for the job ${err}`);
        setStatus(2);
    }
};

export const refuseToJob = async (token: string, setStatus: (value: number) => void) => {
    try {
        await axios.post(`job-offer/reject?token=${token}`);
        setStatus(4);
    } catch (err) {
        console.error(`Error rejecting from the job ${err}`);
        setStatus(2);
    }
};

export const getJobSummaryStatus = async (
    token: string,
    setJob: (value: JobLandingDetails) => void,
    setStatus: (value: number) => void
) => {
    try {
        const response = await axios.get(`job-status?token=${token}`);
        setJob(response.data.job);
        if (response.data.job.summary.finished_at) setStatus(3);
        else setStatus(1);
    } catch (err) {
        setStatus(2);
        console.error(`error getting job summary status details ${err}`);
    }
};

export const startWorkDay = async (token: string, setStatus: (value: number) => void) => {
    try {
        await axios.post(`job-status/start?token=${token}`);
        setStatus(1);
    } catch (err) {
        console.error(`Error starting work day ${err}`);
        setStatus(2);
    }
};

export const finishWorkDay = async (token: string, setStatus: (value: number) => void, unusual_events: string | null) => {
    try {
        await axios.post(`job-status/finish?token=${token}`, {
            unusual_events: unusual_events || null
        });
        setStatus(4);
    } catch (err) {
        console.error(`Error finishing work day ${err}`);
        setStatus(2);
    }
};

export const sendPostJobReport = async (
    token: string,
    setStatus: (value: number) => void,
    data: {
        reported_start_hour: Dayjs | null;
        reported_finish_hour: Dayjs | null;
        unusual_events: string;
    }
) => {
    try {
        await axios.put(`job-status/post-job-report?token=${token}`, {
            reported_start_hour: data.reported_start_hour ? data.reported_start_hour?.format('HH:mm:ss') : null,
            reported_finish_hour: data.reported_finish_hour ? data.reported_finish_hour?.format('HH:mm:ss') : null,
            unusual_events: data.unusual_events
        });
        setStatus(4);
    } catch (err) {
        console.error(`Error sending job report ${err}`);
        setStatus(2);
    }
};

export const saveJobOffers = async (job_candidates_ids: number[], worker_id: number) => {
    const response = await axios.post(`job/save-job-candidates`, { job_candidates_ids, worker_id });
    return response.status;
};

export const approveJobObligation = async (token: string, setStatus: (value: number) => void, approved: boolean) => {
    try {
        const response = await axios.post(`job-status/approve-obligation?token=${token}`, {
            approved: approved ? 1 : 0
        });
        response.status === 200 ? setStatus(approved ? 3 : 4) : setStatus(2);
    } catch (err) {
        console.error(`Error approving job obligation ${err}`);
        setStatus(2);
    }
};

export default getAvailableWorkplaces;
