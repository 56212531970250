import { useEffect, useMemo, useState } from 'react';
import { Dialog, DialogContent, Box, OutlinedInput, IconButton, Typography, InputAdornment, styled, Button, debounce } from '@mui/material';
import { IconSearch } from '@tabler/icons';
import { CancelTwoTone } from '@mui/icons-material';
import Table from 'views/common/GlobalTable';
import { GridColDef, GridSelectionModel, GridSortModel } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import { getUsers } from 'services/UserService';
import { User } from 'types/auth';
import { PaginationInfo, RequestParams } from 'types/paginationInfo';
import NotFound from 'views/common/NotFound';

export interface DialogProps {
    open: boolean;
    onClose: () => void;
    setAvatarUsers: (value: object[]) => void;
    avatarUsers: object[];
}

const EditUserListDialog = (props: DialogProps) => {
    const [userList, setUserList] = useState<User[]>([]);
    const { onClose, open, avatarUsers, setAvatarUsers } = props;
    const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>();
    const [requestParams, setRequestParams] = useState<RequestParams>({
        page: 1,
        per_page: 50,
        sort: [{ field: 'id', sort: 'asc' }],
        filters: []
    });
    const [status, setStatus] = useState<number>(1); // 0: Ready, 1:Loading, 2: Not found
    const [searchText, setSearchText] = useState<string>();

    const handleClose = () => {
        onClose();
    };

    const setUsers = () => {
        setAvatarUsers(selectedUsers);
        handleClose();
    };

    const Text = styled('p')({
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center'
    });

    const columns: GridColDef[] = [
        {
            field: 'id',
            width: 253,
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="employee-id" />
                </Text>
            ),
            sortable: paginationInfo?.sortable_fields?.includes('id')
        },
        {
            field: 'first_name',
            width: 275,
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="name" />
                </Text>
            ),
            sortable: paginationInfo?.sortable_fields?.includes('first_name'),
            renderCell: (params) => (
                <Typography>
                    {params.row.first_name} {params.row.last_name}
                </Typography>
            )
        },
        {
            field: 'email',
            width: 253,
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="email" />
                </Text>
            ),
            sortable: paginationInfo?.sortable_fields?.includes('email')
        },
        {
            field: 'role_id',
            width: 253,
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="role" />
                </Text>
            ),
            sortable: paginationInfo?.sortable_fields?.includes('role_id'),
            renderCell: (params) => <Typography>{params.row.role ? params.row.role.name : 'User'}</Typography>
        }
    ];

    const H3 = styled(Typography)({
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '19px',
        letterSpacing: '0em',
        textAlign: 'left',
        paddingLeft: '16px',
        marginBottom: '25px'
    });

    const ButtonStyle = styled(Button)(({ theme }) => ({
        height: '30px',
        width: '54px',
        borderRadius: '4px',
        padding: '8px, 16px, 8px, 16px',
        fontSize: '12px',
        color: `${theme.palette.primary.light}`,
        fontWeight: 500,
        position: 'absolute',
        right: '30px',
        zIndex: 1
    }));

    useEffect(() => {
        if (open) getUsers(setUserList, requestParams, setPaginationInfo);
    }, [requestParams, open]);

    useEffect(() => {
        setSelectedUsers(avatarUsers);
        setSelectionModel(avatarUsers.map((r: any) => r.id));
    }, [avatarUsers]);

    useEffect(() => {
        const currentRowIds = userList.map((r: any) => r.id);

        setSelectedUsers((current) => [
            ...current.filter((user) => !currentRowIds.includes(user.id)),
            ...userList.filter((row: any) => selectionModel.includes(row.id))
        ]);
    }, [selectionModel]);

    useEffect(() => {
        if (userList.length > 0 && paginationInfo) {
            setStatus(0);
        }
        if (userList.length === 0 && paginationInfo) {
            setStatus(2);
        }
    }, [paginationInfo, userList]);

    useEffect(() => {
        if (searchText !== undefined) {
            setRequestParams((r) => ({
                ...r,
                filters: [...(r.filters?.filter((f) => f.name !== 'id') || []), { name: 'id', value: searchText }]
            }));
        }
    }, [searchText]);

    const debouncedSearchTextChange = useMemo(() => debounce(setSearchText, 300), []);

    const onPageChange = (newPage: number) => {
        setRequestParams({ ...requestParams, page: newPage });
        setStatus(1);
        getUsers(setUserList, { ...requestParams, page: newPage }, setPaginationInfo);
    };

    const onSortModelChange = (model: GridSortModel) => {
        setRequestParams({ ...requestParams, sort: model });
    };

    return (
        <Dialog
            PaperProps={{ style: { height: '800px', padding: '20px' } }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="xl"
        >
            <DialogContent sx={{ padding: 0 }}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <H3 variant="h3">
                        <FormattedMessage id="user-list" />
                    </H3>
                    <IconButton
                        onClick={handleClose}
                        sx={{ p: 0, width: 'fit-content', marginRight: '26px', position: 'absolute', right: 0 }}
                    >
                        <CancelTwoTone />
                    </IconButton>
                </Box>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <OutlinedInput
                        sx={{ width: '257px', height: '48px', marginLeft: '10px', marginBottom: '16px' }}
                        id="input-search-profile"
                        placeholder="Search"
                        defaultValue={searchText}
                        onChange={(e) => debouncedSearchTextChange(e.currentTarget.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <IconSearch stroke={1.5} size="1rem" />
                            </InputAdornment>
                        }
                        size="small"
                    />
                    {selectedUsers.length > 0 && (
                        <ButtonStyle onClick={setUsers} variant="contained" color="primary">
                            <FormattedMessage id="update" />
                        </ButtonStyle>
                    )}
                </Box>
                {status !== 0 ? (
                    <NotFound
                        status={status}
                        tryAgain={() => {
                            setStatus(1);
                            getUsers(setUserList, { ...requestParams, filters: [] }, setPaginationInfo);
                        }}
                        columnsLoaded={columns.length}
                    />
                ) : (
                    <Box p={2}>
                        <Table
                            columns={columns}
                            rows={userList}
                            selectionModel={selectionModel}
                            onSelectionModelChange={(rowSelectedIds) => {
                                const currentRowIds = userList.map((r: any) => r.id);
                                setSelectionModel((current) => [...current.filter((id) => !currentRowIds.includes(id)), ...rowSelectedIds]);
                            }}
                            paginationInfo={paginationInfo}
                            page={requestParams.page}
                            sortModel={requestParams.sort}
                            onPageChange={onPageChange}
                            onSortModelChange={onSortModelChange}
                            checkbox
                            disableSelection={false}
                        />
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};
export default EditUserListDialog;
