import axios from 'axios';
import { PaginationInfo, RequestParams } from 'types/paginationInfo';
import { Comment, CompletionFile, Worker, WorkerApprovalSummary, WorkerSummary } from 'types/worker';
import { format } from 'date-fns';
import { DaysList, StringID } from './PersonalAreaService';

export const getWorkers = async (
    setWorkerTypes: (val: WorkerSummary[]) => void,
    requestParams: RequestParams,
    setPaginationInfo: (val: PaginationInfo) => void
) => {
    try {
        const params: any = {
            page: requestParams.page,
            per_page: requestParams.per_page,
            sort: `${requestParams.sort[0].sort === 'desc' ? '-' : ''}${requestParams.sort[0].field}`
        };
        requestParams.filters?.forEach((filter) => {
            params[`filter[${filter.name}]`] = filter.value;
        });
        const response = await axios.get('/workers', { params });

        let availableFilters: any = [];
        response.data.workers.filters.forEach((filter: any) => {
            availableFilters = [...availableFilters, ...filter.items.options];
        });

        setPaginationInfo({
            total: response.data.workers.total,
            last_page: response.data.workers.last_page,
            per_page: response.data.workers.per_page,
            sortable_fields: availableFilters.map((a: any) => a.name),
            available_filters: response.data.workers.filters
        });
        setWorkerTypes(response.data.workers.data);
    } catch (err) {
        console.error(`Error getting workers ${err}`);
    }
};

export const sendAuthorityMessages = async (requestParams: RequestParams, authority_id: Number) => {
    const params: any = {};
    params.authority_id = authority_id;
    requestParams.filters?.forEach((filter) => {
        params[`filter[${filter.name}]`] = filter.value;
    });
    await axios.get('/sms/worker-authority-sms', { params });
};

export const getWorkerDetails = async (id: string, setWorker: (value: Worker) => void) => {
    try {
        const response = await axios.get(`/workers/${id}`);
        setWorker(response.data.worker);
    } catch (err) {
        console.error(`Error getting Worker details`, err);
    }
};

export const setWorkerDetailsById = async (
    worker: Worker,
    selectedAuthoritiesList: StringID[],
    arrAvalablities: DaysList[] | undefined
) => {
    try {
        const body = {
            workplace_types: worker.workplace_types,
            age_ranges: worker.age_ranges,
            address_street: worker.address_street,
            address_home_number: worker.address_home_number,
            gender: worker.gender,
            occupation: worker.occupation?.id,
            worker_occupation_id: worker.occupation?.id,
            receiving_sms: worker.receiving_sms,
            birth_date: format(new Date(worker.birth_date), 'yyyy-MM-dd'),
            user: {
                first_name: worker.user.first_name,
                last_name: worker.user.last_name
            },
            email: worker.user.email,
            phone: worker.user.phone_number,
            details: {
                highschool_graduated: worker.details?.highschool_graduated,
                graduated_in_israel: worker.details?.graduated_in_israel,
                previous_experience: worker.details?.previous_experience,
                ...(worker.details?.graduated_in_israel === 1 && { highschool_name: worker.details?.highschool_name }),
                ...(worker.details?.graduated_in_israel === 1 && { highschool_city: worker.details?.highschool_city }),
                ...(worker.details?.previous_experience_story && { previous_experience_story: worker.details?.previous_experience_story }),
                kosher_phone: worker.details?.kosher_phone,
                rest_day: worker.details?.rest_day ? worker.details?.rest_day : 'שבת'
            },
            address_local_authority: worker.address_authority?.id,
            authority_region: worker.regions.map((r) => ({ id: r.id, name: r.name })),
            authorities: selectedAuthoritiesList.map((r) => ({ id: r.id })),
            availability: arrAvalablities,
            languages: worker.languages
        };
        await axios.put(`/workers/${worker.id}`, body);
    } catch (err) {
        console.error(`Error updating personal area details`, err);
        throw err;
    }
};

export const disableWorker = async (id: string, delete_reason: string) => {
    try {
        const body = { delete_reason };
        await axios.delete(`/workers/${id}`, { data: body });
    } catch (err) {
        console.error(`Error disabling worker ${err}`);
    }
};

export const openFile = async (id: string) => {
    try {
        const response = await axios.get(`/workers/files/${id}`, {
            responseType: 'blob'
        });
        const file = new File([response.data], `FILE-${id}`, { type: response.data.type });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    } catch (err) {
        console.error(`Error getting worker file ${err}`);
    }
};

export const openWorkerAreaFile = async (id: string) => {
    try {
        const response = await axios.get(`/worker-area/file/${id}`, {
            responseType: 'blob'
        });
        const file = new File([response.data], `FILE-${id}`, { type: response.data.type });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    } catch (err) {
        console.error(`Error getting worker file ${err}`);
    }
};

export const addWorkerComment = async (worker_id: number, comment: string) => {
    const body = { comment };
    try {
        await axios.post(`/workers/${worker_id}/comments`, body);
    } catch (err) {
        console.error(`Error adding worker comment ${err}`);
    }
};

export const getWorkerComments = async (worker_id: string, setComments: (val: Comment[]) => void) => {
    try {
        const response = await axios.get(`/workers/${worker_id}/comments`);
        setComments(response.data.comments);
    } catch (err) {
        setComments([]);
        console.error(`Error getting worker comments ${err}`);
    }
};

export const getWorkerApprovals = async (
    setWorkers: (val: WorkerApprovalSummary[]) => void,
    requestParams: RequestParams,
    setPaginationInfo: (val: PaginationInfo) => void
) => {
    try {
        const params: any = {
            page: requestParams.page,
            per_page: requestParams.per_page,
            sort: `${requestParams.sort[0].sort === 'desc' ? '-' : ''}${requestParams.sort[0].field}`
        };
        requestParams.filters?.forEach((filter) => {
            params[`filter[${filter.name}]`] = filter.value;
        });
        const response = await axios.get('/workers/approvals', { params });

        let availableFilters: any = [];
        response.data.workers.filters.forEach((filter: any) => {
            availableFilters = [...availableFilters, ...filter.items.options];
        });

        setPaginationInfo({
            total: response.data.workers.total,
            last_page: response.data.workers.last_page,
            per_page: response.data.workers.per_page,
            sortable_fields: availableFilters.map((a: any) => a.name),
            available_filters: response.data.workers.filters
        });
        setWorkers(response.data.workers.data);
    } catch (err) {
        console.error(`Error getting workers ${err}`);
    }
};

export const getWorkersExcel = async (requestParams: RequestParams) => {
    try {
        const params: any = {
            page: requestParams.page,
            per_page: requestParams.per_page,
            sort: `${requestParams.sort[0].sort === 'desc' ? '-' : ''}${requestParams.sort[0].field}`
        };
        requestParams.filters?.forEach((filter) => {
            params[`filter[${filter.name}]`] = filter.value;
        });

        const response = await axios.get('/workers/export-excel', {
            responseType: 'blob',
            params
        });
        const file = new File([response.data], `WorkersExport`, { type: response.data.type });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    } catch (err) {
        console.error(`Error getting workers excel ${err}`);
    }
};

export const updateWorkerStatus = async (id: string, status: number, notes?: string) => {
    try {
        const body = { status, ...(notes && { notes }) };
        await axios.put(`/workers/${id}/update-status`, body);
    } catch (err) {
        console.error(`Error updating worker status ${err}`);
    }
};

export const updateWorkerBackupStatus = async (id: string, backup_status: number) => {
    try {
        const body = { backup_status };
        await axios.put(`/workers/${id}/update-backup-status`, body);
        console.log('updateBackup!!', body);
    } catch (err) {
        console.error(`Error updating worker backup status ${err}`);
    }
};

export const createWorkersFromExcel = async (file: File) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const response = await axios({
        method: 'post',
        url: `/workers/upload-excel`,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response;
};

export const deleteWorkersFromExcel = async (file: File) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const response = await axios({
        method: 'post',
        url: `/workers/delete-excel`,
        data: bodyFormData,
        headers: { 'Content-type': 'multipart/form-data' }
    });
    return response;
};

export const getWorkerEditDetails = async (setWorkerDetails: (worker: any) => void, token: string) => {
    try {
        const response = await axios.get(`/workers/data-completion?token=${token}`);
        const dataWorker = response.data.worker;
        const result = {
            availability: dataWorker.availability,
            address_authority: dataWorker.address_authority,
            address_local_authority: dataWorker.address_local_authority,
            address_street: dataWorker.address_street,
            address_home_number: dataWorker.address_home_number,
            gender: dataWorker.gender,
            birth_date: dataWorker.birth_date,
            details: dataWorker.details,
            regions: dataWorker.regions,
            workplace_types: dataWorker.workplace_types,
            languages: dataWorker.languages,
            age_ranges: dataWorker.age_ranges,
            user: dataWorker.user,
            personal_id: dataWorker.personal_id,
            files: dataWorker.files,
            receiving_sms: dataWorker.receiving_sms
        };

        setWorkerDetails(result);
    } catch (err) {
        setWorkerDetails(false);
        console.error(`error in roles list -key-value-  ${err}`);
    }
};

export const openCompletionFile = async (id: string, token: string) => {
    try {
        const response = await axios.get(`/workers/data-completion/files/${id}?token=${token}`, {
            responseType: 'blob'
        });
        const file = new File([response.data], `FILE-${id}`, { type: response.data.type });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    } catch (err) {
        console.error(`Error getting worker file ${err}`);
    }
};

export const putWorkerNewDetails = async (workerDetails: any, token: string) => {
    try {
        const body = workerDetails;
        await axios.put(`/workers/data-completion?token=${token}`, body);
    } catch (err) {
        console.error(`Error updating worker status ${err}`);
    }
};

export const signUpWorker = async (
    workerDetails: any,
    signupToken: string,
    finished: boolean,
    setSignupToken?: (value: string) => void,
    setError?: any
) => {
    try {
        const body = workerDetails;
        const response = await axios.post(`/sign-up-worker?token=${signupToken}&finished=${finished}`, body);
        setSignupToken?.(response.data.signup_token);
        return true;
    } catch (err: any) {
        console.error(`Error sign up worker ${err}`);
        if (setError) {
            if (err?.response?.data?.message.includes('users.users_email_unique')) {
                setError('user.email', { type: 'duplicate' }, { shouldFocus: true });
                throw new Error('Duplicate mail');
            }
        }
        return false;
    }
};

export const putSignupFiles = async (files: CompletionFile[], signatureIMG: any, token: string) => {
    const bodyFormData = new FormData();
    const attachments: CompletionFile[] = [];
    console.log(files);
    files.forEach((element: any) => {
        bodyFormData.append(element.file_path.replace(/\.[^/.]+$/, ''), element.upload_data);
        attachments.push({ ...element, file_path: element.file_path.replace(/\.[^/.]+$/, '') });
    });

    bodyFormData.append('signature_picture', signatureIMG);
    attachments.push({ file_path: 'signature_picture', file_name: 'Signature Picture', upload_data: signatureIMG });
    bodyFormData.append('file_size', files[0].file_size);
    bodyFormData.append('files', JSON.stringify(attachments));
    bodyFormData.append('_method', 'PUT');

    const response = await axios({
        method: 'post',
        url: `/sign-up-worker/files?token=${token}`,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
    });

    return response.status;
};

export const putWorkerFiles = async (files: CompletionFile[], signatureIMG: any, token: string) => {
    const bodyFormData = new FormData();
    const attachments: CompletionFile[] = [];
    files.forEach((element: any) => {
        bodyFormData.append(element.file_path.replace(/\.[^/.]+$/, ''), element.upload_data);
        attachments.push({ ...element, file_path: element.file_path.replace(/\.[^/.]+$/, '') });
    });

    bodyFormData.append('signature_picture', signatureIMG);
    attachments.push({ file_path: 'signature_picture', file_name: 'Signature Picture', upload_data: signatureIMG });
    bodyFormData.append('files', JSON.stringify(attachments));
    bodyFormData.append('_method', 'PUT');

    const response = await axios({
        method: 'post',
        url: `/workers/data-completion/files?token=${token}`,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
    });

    return response.status;
};

export const putWorkerSignature = async (img: any, token: string) => {
    const bodyFormData = new FormData();
    bodyFormData.append('img', JSON.stringify(img));
    bodyFormData.append('_method', 'PUT');

    const response = await axios({
        method: 'post',
        url: `/workers/data-completion/files?token=${token}`,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
    });

    return response.status;
};

export const stopReceivingSms = async (token: string, phone_number: string, setStatus: (value: number) => void) => {
    try {
        await axios.post(`/workers/data-completion/cancel-sms?token=${token}`, { phone_number });
        setStatus(3);
    } catch (err) {
        console.error(`Error canceling sms sub ${err}`);
        setStatus(2);
    }
};

export const checkEmailValidity = async (email: string, token = '') => {
    const body = { email, token };
    const response = await axios.get(`/check-email`, { params: body });
    return response;
};

export const removeUser = async (token: string, setStatus: (value: number) => void) => {
    try {
        await axios.post(`/workers/data-completion/cancel-sms-link?token=${token}`);
        setStatus(3);
    } catch (err) {
        console.error(`Error remove the user ${err}`);
        setStatus(2);
    }
};
