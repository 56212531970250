import { useState, useRef, useEffect, useMemo, createRef } from 'react';
import Table from 'views/common/GlobalTable';
import SubSearchSection from 'views/common/SubSearchSection';
import MainCard from 'ui-component/cards/MainCard';
import { Typography } from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import NotFound from 'views/common/NotFound';
import { WorkplaceSummary, WorkplaceType } from 'types/workplace';
import { createWorkplacesFromExcel, disableWorkplace, getWorkplaces, getWorkplaceTypes } from 'services/WorkplaceService';
import { PaginationInfo, RequestParams } from 'types/paginationInfo';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import Action from 'views/common/GlobalAction';
import EditMethod from './EditWorkplacePopup';
import GlobalDrawer from 'views/common/GlobalDrawer';
import excel from 'assets/images/icons/excel.svg';
import GlobalDisableDialog from 'views/common/GlobalDisableDialog';

export interface editProps {
    name: string;
    parent: string;
    reference: string;
}

const Workplaces = () => {
    const [searchText, setSearchText] = useState<string>();
    const [status, setStatus] = useState<number>(1);
    const [workplaces, setWorkplaces] = useState<WorkplaceSummary[]>([]);
    const [workplaceTypes, setworkplaceTypes] = useState<WorkplaceType[]>([]);
    const [edit, setEdit] = useState<boolean>(false);
    const [openDisable, setOpenDisable] = useState<boolean>(false);
    const [currentItem, setCurrentItem] = useState<WorkplaceSummary>();
    const componentRef = useRef<HTMLDivElement>(null);
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>();
    const [requestParams, setRequestParams] = useState<RequestParams>({
        page: 1,
        per_page: 50,
        sort: [{ field: 'name', sort: 'asc' }],
        filters: []
    });
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const inputFileRef = createRef<HTMLInputElement>();
    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'authority_id',
            flex: 1,
            sortable: paginationInfo?.sortable_fields?.includes('authority_id'),
            renderHeader: () => (
                <Typography fontWeight={500}>
                    <FormattedMessage id="local-authority-name" />
                </Typography>
            ),
            renderCell: (params) => <Typography>{params.row.authority?.name}</Typography>
        },
        {
            field: 'name',
            flex: 1,
            sortable: paginationInfo?.sortable_fields?.includes('name'),
            renderHeader: () => (
                <Typography fontWeight={500}>
                    <FormattedMessage id="name" />
                </Typography>
            )
        },
        {
            field: 'address',
            flex: 1,
            sortable: paginationInfo?.sortable_fields?.includes('address'),
            renderHeader: () => (
                <Typography fontWeight={500}>
                    <FormattedMessage id="address" />
                </Typography>
            ),
            renderCell: (params) => <Typography>{params.row.address}</Typography>
        },
        {
            field: 'workplace_type_id',
            flex: 1,
            sortable: paginationInfo?.sortable_fields?.includes('workplace_type_id'),
            renderHeader: () => (
                <Typography fontWeight={500}>
                    <FormattedMessage id="workplace-type" />
                </Typography>
            ),
            renderCell: (params) => <Typography>{params.row.type?.name}</Typography>
        },
        {
            field: 'workplace_contact_id',
            flex: 1,
            sortable: paginationInfo?.sortable_fields?.includes('workplace_contact_id'),
            renderHeader: () => (
                <Typography fontWeight={500}>
                    <FormattedMessage id="contact-person" />
                </Typography>
            ),
            renderCell: (params) => (
                <div>
                    <Typography>
                        {params.row?.contacts[0]?.first_name} {params.row?.contacts[0]?.last_name}
                    </Typography>
                    <Typography>{params.row?.contacts[0]?.phone_number}</Typography>
                </div>
            )
        },
        {
            field: 'hour',
            flex: 1,
            sortable: paginationInfo?.sortable_fields?.includes('hour'),
            renderHeader: () => (
                <Typography fontWeight={500}>
                    <FormattedMessage id="working-shift" />
                </Typography>
            ),
            renderCell: (params) => (
                <Typography>
                    <FormattedMessage id={params.row.working_shift} />
                </Typography>
            )
        },
        {
            field: 'age_range_id',
            flex: 1,
            hide: true,
            sortable: paginationInfo?.sortable_fields?.includes('age_range_id'),
            renderHeader: () => (
                <Typography fontWeight={500}>
                    <FormattedMessage id="age-range" />
                </Typography>
            ),
            renderCell: (params) => <Typography>{params.row?.age_ranges?.interval_label}</Typography>
        },
        // {
        //     field: 'map_url',
        //     flex: 1,
        //     sortable: paginationInfo?.sortable_fields?.includes('map_url'),
        //     renderHeader: () => (
        //         <Typography fontWeight={500}>
        //             <FormattedMessage id="map-url" />
        //         </Typography>
        //     ),
        //     renderCell: (params) => <Typography>{params.row.map_url}</Typography>
        // },
        // {
        //     field: 'waze_url',
        //     flex: 1,
        //     sortable: paginationInfo?.sortable_fields?.includes('waze_url'),
        //     renderHeader: () => (
        //         <Typography fontWeight={500}>
        //             <FormattedMessage id="waze-url" />
        //         </Typography>
        //     ),
        //     renderCell: (params) => <Typography>{params.row.waze_url}</Typography>
        // },

        // {
        //     field: 'neighborhood',
        //     flex: 1,
        //     sortable: paginationInfo?.sortable_fields?.includes('neighborhood'),
        //     renderHeader: () => (
        //         <Typography fontWeight={500}>
        //             <FormattedMessage id="neighborhood" />
        //         </Typography>
        //     ),
        //     renderCell: (params) => <Typography>{params.row.neighborhood}</Typography>
        // },
        {
            field: 'action',
            width: 100,
            renderHeader: () => (
                <Typography fontWeight={500}>
                    <FormattedMessage id="Action" />
                </Typography>
            ),
            renderCell: (params) => (
                <Action
                    setEdit={() => {
                        setEdit(true);
                        setCurrentItem(params.row);
                        navigate(`/workplaces/${params.id}`);
                    }}
                    setDisable={(value: boolean) => {
                        setOpenDisable(value);
                        setCurrentItem(params.row);
                    }}
                />
            )
        }
    ];

    useEffect(() => {
        if (searchText !== undefined) {
            setRequestParams((r) => ({
                ...r,
                filters: [...(r.filters?.filter((f) => f.name !== 'id') || []), { name: 'name', value: searchText }]
            }));
        }
    }, [searchText]);

    const handleDisable = async () => {
        setOpenDisable(false);
        console.log('disable FE!');
        await disableWorkplace(currentItem?.id.toString()!);
        setStatus(1);
        getWorkplaces(setWorkplaces, requestParams, setPaginationInfo);
    };

    useEffect(() => {
        if (workplaces.length > 0) {
            setStatus(0);
        }
        if (workplaces.length === 0 && paginationInfo) {
            setStatus(2);
        }
    }, [workplaces, paginationInfo]);

    useEffect(() => {
        setStatus(1);
        getWorkplaces(setWorkplaces, requestParams, setPaginationInfo);
    }, [requestParams]);

    const onPageChange = (newPage: number) => {
        setRequestParams({ ...requestParams, page: newPage });
        setStatus(1);
        getWorkplaces(setWorkplaces, { ...requestParams, page: newPage }, setPaginationInfo);
    };

    const onPageSizeChange = (per_page: number) => {
        setRequestParams({ ...requestParams, per_page, page: 1 });
        setStatus(1);
        getWorkplaces(setWorkplaces, { ...requestParams, per_page, page: 1 }, setPaginationInfo);
    };

    const debouncedSearchTextChange = useMemo(() => debounce(setSearchText, 300), []);

    const onSortModelChange = (model: GridSortModel) => {
        setRequestParams({ ...requestParams, sort: model });
    };

    const refresh = () => {
        setStatus(1);
        getWorkplaces(setWorkplaces, { ...requestParams, filters: [] }, setPaginationInfo);
    };

    const handleSelectFile = async (e: any) => {
        // setStatus(1);
        const [selectedFile] = e.target.files;
        const response = await createWorkplacesFromExcel(selectedFile);
        if (response) getWorkplaces(setWorkplaces, { ...requestParams, filters: [] }, setPaginationInfo);
    };

    return (
        <MainCard>
            <SubSearchSection
                filters={requestParams.filters}
                setFilters={(filters) => setRequestParams({ ...requestParams, filters })}
                filter={openFilter}
                setFilter={setOpenFilter}
                availableFilters={paginationInfo?.available_filters}
                setSearchText={debouncedSearchTextChange}
                printRef={componentRef}
                columnCount={columns.length - 1}
                label="Workplaces"
                setAdd={(value: boolean) => navigate(`/workplaces/add`)}
                onExtraButtonClick={() => inputFileRef.current && inputFileRef.current.click()}
                // onExtraButtonClick={() => console.log('hello!')}
                extraButtonIcon={<img alt="excel-export-icon" src={excel} />}
            />
            {status !== 0 ? (
                <NotFound status={status} columnsLoaded={columns.length} tryAgain={refresh} />
            ) : (
                <>
                    <div ref={componentRef}>
                        <Table
                            onCellClick={(params) => {
                                setCurrentItem(params.row);

                                // navigate(`/workplaces/${params.id}`);
                            }}
                            sortModel={requestParams.sort}
                            onSortModelChange={onSortModelChange}
                            onPageSizeChange={onPageSizeChange}
                            onPageChange={onPageChange}
                            paginationInfo={paginationInfo}
                            page={requestParams.page}
                            rows={workplaces}
                            columns={columns}
                        />
                    </div>
                </>
            )}
            <GlobalDrawer
                open={edit}
                onClose={() => {
                    setEdit(false);
                    refresh();
                }}
                Component={
                    <EditMethod
                        edit
                        onClose={() => {
                            setEdit(false);
                            refresh();
                        }}
                        id={currentItem?.id}
                        setOpenDisable={setOpenDisable}
                    />
                }
            />
            <input
                onChange={handleSelectFile}
                ref={inputFileRef}
                style={{ display: 'none' }}
                type="file"
                id="excelWorkplaceFileInput"
                accept=".xlsx,.xls"
            />
            <GlobalDisableDialog
                open={openDisable}
                onClose={() => setOpenDisable(false)}
                onDisable={handleDisable}
                coloredText={currentItem?.name!}
                // coloredText="hey"
                title1="Are you sure you want to disable "
                title2=" workplace?"
                subText="By disabling this workplace it will be deleted from the database"
            />
        </MainCard>
    );
};

export default Workplaces;
