import axios from 'axios';
import { PaginationInfo, RequestParams } from 'types/paginationInfo';
import { Attachment, Workplace, WorkplaceContact, WorkplaceSummary, WorkplaceType } from 'types/workplace';

export const getWorkplaces = async (
    setWorkplaces: (val: WorkplaceSummary[]) => void,
    requestParams: RequestParams,
    setPaginationInfo: (val: PaginationInfo) => void
) => {
    try {
        const params: any = {
            page: requestParams.page,
            per_page: requestParams.per_page,
            sort: `${requestParams.sort[0].sort === 'desc' ? '-' : ''}${requestParams.sort[0].field}`
        };
        requestParams.filters?.forEach((filter) => {
            params[`filter[${filter.name}]`] = filter.value;
        });
        const response = await axios.get('/workplaces', { params });

        let availableFilters: any = [];
        response.data.workplaces.filters.forEach((filter: any) => {
            availableFilters = [...availableFilters, ...filter.items.options];
        });

        setPaginationInfo({
            total: response.data.workplaces.total,
            last_page: response.data.workplaces.last_page,
            per_page: response.data.workplaces.per_page,
            sortable_fields: availableFilters.map((a: any) => a.name),
            available_filters: response.data.workplaces.filters
        });
        setWorkplaces(response.data.workplaces.data);
    } catch (err) {
        console.error(`Error getting workplaces ${err}`);
    }
};

export const getWorkplaceDetails = async (id: string, setWorkplaces: (value: Workplace) => void) => {
    try {
        const response = await axios.get(`/workplaces/${id}`);
        setWorkplaces(response.data.workplace);
    } catch (err) {
        console.error(`Error getting Workplace details`, err);
    }
};

export const disableWorkplace = async (id: string) => {
    try {
        await axios.delete(`/workplaces/${id}`);
    } catch (err) {
        console.error(`Error disabling workplace ${err}`);
    }
};

export const openFile = async (id: string) => {
    try {
        const response = await axios.get(`/workplaces/files/${id}`, {
            responseType: 'blob'
        });
        const file = new File([response.data], `FILE-${id}`, { type: response.data.type });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    } catch (err) {
        console.error(`Error getting workplace file ${err}`);
    }
};

export const addWorkplaceComment = async (workplace_id: number, comment: string) => {
    const body = { comment };
    try {
        await axios.post(`/workplaces/${workplace_id}/comments`, body);
    } catch (err) {
        console.error(`Error adding workplace comment ${err}`);
    }
};

export const getWorkplaceTypes = async (
    setWorkplaceTypes: (val: WorkplaceType[]) => void,
    requestParams: RequestParams,
    setPaginationInfo: (val: PaginationInfo) => void
) => {
    try {
        const params: any = {
            page: requestParams.page,
            per_page: requestParams.per_page,
            sort: `${requestParams.sort[0].sort === 'desc' ? '-' : ''}${requestParams.sort[0].field}`
        };
        requestParams.filters?.forEach((filter) => {
            params[`filter[${filter.name}]`] = filter.value;
        });
        const response = await axios.get('/workplace-types', { params });

        let availableFilters: any = [];
        response.data.workplace_types.filters.forEach((filter: any) => {
            availableFilters = [...availableFilters, ...filter.items.options];
        });

        setPaginationInfo({
            total: response.data.workplace_types.total,
            last_page: response.data.workplace_types.last_page,
            per_page: response.data.workplace_types.per_page,
            sortable_fields: availableFilters.map((a: any) => a.name),
            available_filters: response.data.workplace_types.filters
        });
        setWorkplaceTypes(response.data.workplace_types.data);
    } catch (err) {
        console.error(`Error getting workplace types ${err}`);
    }
};

export const createWorkplaceType = async (workplaceType: WorkplaceType) => {
    const params = { name: workplaceType.name, subtype: workplaceType.subtype, related: workplaceType.related };
    const response = await axios.post('/workplace-types', params);
    return response.status;
};

export const disableWorkplaceType = async (id: string) => {
    try {
        await axios.delete(`/workplace-types/${id}`);
    } catch (err) {
        console.error(`Error disabling workplace type ${err}`);
    }
};

export const getWorkplaceType = async (id: number, setWorkplaceType: (value: WorkplaceType) => void) => {
    try {
        const response = await axios.get(`/workplace-types/${id}`);
        setWorkplaceType(response.data.workplace_type);
    } catch (err) {
        console.error(`Error getting Workplace Type ${err}`);
    }
};

export const editWorkplaceType = async (workplaceType: WorkplaceType) => {
    const params = { name: workplaceType.name, subtype: workplaceType.subtype, related: workplaceType.related };
    const response = await axios.put(`/workplace-types/${workplaceType.id}`, params);
    return response.status;
};

export const createWorkplace = async (workplace: Workplace, contacts: WorkplaceContact[]) => {
    const bodyFormData = new FormData();
    const attachments: Attachment[] = [];
    workplace.attachments?.forEach((element: any) => {
        if (element.upload_data) bodyFormData.append(element.path.replace(/\.[^/.]+$/, ''), element.upload_data);
        if (element.id) {
            attachments.push({ id: element.id, path: element.path.replace(/\.[^/.]+$/, '') });
        } else {
            attachments.push({ path: element.path.replace(/\.[^/.]+$/, '') });
        }
    });
    bodyFormData.append('attachments', JSON.stringify(attachments));
    bodyFormData.append('authority_id', workplace.authority_id?.toString() || '');
    bodyFormData.append('name', workplace.name);
    bodyFormData.append('number_of_children', workplace.number_of_children?.toString()!);
    bodyFormData.append('neighborhood', workplace.neighborhood);
    bodyFormData.append('address', workplace.address);
    bodyFormData.append('symbol', workplace.symbol?.toString()!);
    bodyFormData.append('workplace_type_id', workplace.workplace_type_id.toString());
    bodyFormData.append('working_hour_id', workplace.working_hour_id.toString());
    bodyFormData.append('contacts', JSON.stringify(contacts));
    bodyFormData.append('waze_url', workplace.waze_url);
    bodyFormData.append('map_url', workplace.map_url?.toString() || '');
    bodyFormData.append('age_range_id', workplace.age_range_id?.toString()!);
    bodyFormData.append('notes', workplace.notes?.toString() || '');
    bodyFormData.append('workplace_phone', workplace.workplace_phone.toString());
    bodyFormData.append('amount_of_workers', workplace.amount_of_workers?.toString()!);

    const response = await axios({
        method: 'post',
        url: `/workplaces`,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response;
};

export const getWorkplace = async (id: string, setWorkplace: (val: Workplace) => void, setContacts: (val: WorkplaceContact[]) => void) => {
    try {
        const response = await axios.get(`/workplaces/${id}`);
        setWorkplace(response.data.workplace);
        setContacts(response.data.workplace.contacts);
    } catch (err) {
        console.error(`error in getting workplace ${err}`);
    }
};

export const updateWorkplace = async (workplace: Workplace, contacts: WorkplaceContact[]) => {
    const bodyFormData = new FormData();
    const attachments: Attachment[] = [];
    workplace.attachments?.forEach((element: any) => {
        if (element.upload_data) bodyFormData.append(element.path.replace(/\.[^/.]+$/, ''), element.upload_data);
        if (element.id) {
            attachments.push({ id: element.id, path: element.path.replace(/\.[^/.]+$/, '') });
        } else {
            attachments.push({ path: element.path.replace(/\.[^/.]+$/, '') });
        }
    });
    bodyFormData.append('attachments', JSON.stringify(attachments));
    bodyFormData.append('authority_id', workplace.authority_id?.toString() || '');
    bodyFormData.append('name', workplace.name);
    bodyFormData.append('number_of_children', workplace.number_of_children?.toString()!);
    bodyFormData.append('neighborhood', workplace.neighborhood);
    bodyFormData.append('address', workplace.address);
    bodyFormData.append('symbol', workplace.symbol?.toString()!);
    bodyFormData.append('workplace_type_id', workplace.workplace_type_id.toString());
    bodyFormData.append('working_hour_id', workplace.working_hour_id.toString());
    bodyFormData.append('contacts', JSON.stringify(contacts));
    bodyFormData.append('waze_url', workplace.waze_url);
    bodyFormData.append('map_url', workplace.map_url?.toString() || '');
    bodyFormData.append('age_range_id', workplace.age_range_id?.toString()!);
    bodyFormData.append('notes', workplace.notes?.toString() || '');
    bodyFormData.append('workplace_phone', workplace.workplace_phone.toString());
    bodyFormData.append('amount_of_workers', workplace.amount_of_workers?.toString()!);
    bodyFormData.append('_method', 'PUT');

    const response = await axios({
        method: 'post',
        url: `/workplaces/${workplace.id}`,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response;
};

export const createWorkplacesFromExcel = async (file: File) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const response = await axios({
        method: 'post',
        url: `/workplaces/upload-excel`,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response;
};

export const disableContact = async (id: string) => {
    try {
        await axios.delete(`/workplaces/contact/${id}`);
    } catch (err) {
        console.error(`error in disable user ${err}`);
    }
};
