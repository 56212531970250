import { USER_SIGNED_OUT, STORE_USER_ERROR, USER_EXPIRED, STORE_USER, LOADING_USER, SET_LOGIN } from '../actions';
import { ActionAuth, InitialLoginContextProps } from 'types/auth';

const initialState: InitialLoginContextProps = {
    user: undefined,
    isLoadingUser: false,
    token: ''
};

export default function authReducer(state = initialState, action: ActionAuth) {
    switch (action.type) {
        case STORE_USER:
            return {
                ...state,
                isLoadingUser: false,
                user: action.payload
            };
        case SET_LOGIN:
            return {
                ...state,
                isLoadingUser: false,
                user: action.payload.user,
                token: action.payload.token,
                isLoggedIn: true
            };
        case LOADING_USER:
            return {
                ...state,
                isLoadingUser: true
            };
        case USER_EXPIRED:
        case STORE_USER_ERROR:
        case USER_SIGNED_OUT:
            return {
                ...state,
                user: null,
                isLoadingUser: false
            };
        default:
            return state;
    }
}
