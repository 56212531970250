import { UserManager } from 'oidc-client';
import { storeUserError, storeUser } from './AuthActions';
import { UserData } from '../types/auth';

const config = {
    authority: process.env.REACT_APP_AUTHORITY,
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    response_type: process.env.REACT_APP_RESPONSE_TYPE,
    scope: process.env.REACT_APP_SCOPE,
    post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI
};

const userManager = new UserManager(config);

export const loadUserFromStorage = async (store: any) => {
    try {
        const user = await userManager.getUser();
        if (!user) store.dispatch(storeUserError());
        else store.dispatch(storeUser(user as unknown as UserData));
    } catch (e) {
        console.error(`User not found: ${e}`);
        store.dispatch(storeUserError());
    }
};

export const signinRedirect = () => userManager.signinRedirect();

export const signinRedirectCallback = () => userManager.signinRedirectCallback();

export const signoutRedirect = () => {
    userManager.clearStaleState();
    userManager.removeUser();
    return userManager.signoutRedirect();
};

export const signoutRedirectCallback = () => {
    userManager.clearStaleState();
    userManager.removeUser();
    return userManager.signoutRedirectCallback();
};

export default userManager;
