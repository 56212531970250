import { useState, useRef, useEffect, useMemo } from 'react';
import Table from 'views/common/GlobalTable';
import Action from 'views/common/GlobalAction';
import SubSearchSection from 'views/common/SubSearchSection';
import MainCard from 'ui-component/cards/MainCard';
import { styled, Typography } from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import GlobalDisableDialog from 'views/common/GlobalDisableDialog';
import GlobalDrawer from 'views/common/GlobalDrawer';
import EditMethod from './EditWorkplaceType';
import { FormattedMessage } from 'react-intl';
import NotFound from 'views/common/NotFound';
import { WorkplaceType } from 'types/workplace';
import { getWorkplaceTypes, disableWorkplaceType } from 'services/WorkplaceService';
import { PaginationInfo, RequestParams } from 'types/paginationInfo';
import { debounce } from 'lodash';
import { getWorkplaceTypesList } from 'services/ListService';

export interface editProps {
    name: string;
    parent: string;
    reference: string;
}

const WorkplaceTypes = () => {
    const [searchText, setSearchText] = useState<string>();
    const [status, setStatus] = useState<number>(1);
    const [workplaceTypes, setworkplaceTypes] = useState<WorkplaceType[]>([]);
    const [subtypesList, setSubTypesList] = useState<{ id: string; label: string }[]>([]);
    const [add, setAdd] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [openDisable, setOpenDisable] = useState<boolean>(false);
    const [currentItem, setCurrentItem] = useState<WorkplaceType>();
    const componentRef = useRef<HTMLDivElement>(null);
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>();
    const [requestParams, setRequestParams] = useState<RequestParams>({
        page: 1,
        per_page: 50,
        sort: [{ field: 'name', sort: 'asc' }],
        filters: []
    });
    const [openFilter, setOpenFilter] = useState<boolean>(false);

    const Text = styled('p')({
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center'
    });

    const columns: GridColDef[] = [
        {
            field: 'name',
            width: 239,
            sortable: paginationInfo?.sortable_fields?.includes('name'),
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="type" />
                </Text>
            )
        },
        {
            field: 'subtype',
            width: 239,
            sortable: paginationInfo?.sortable_fields?.includes('Subtype'),
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="subtype" />
                </Text>
            ),
            renderCell: (params) => <Typography>{workplaceTypes.find((obj) => obj.id === params.row.subtype)?.name}</Typography>
        },
        {
            field: 'related',
            width: 239,
            sortable: paginationInfo?.sortable_fields?.includes('Related'),
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="related" />
                </Text>
            )
        },
        {
            field: 'action',
            width: 170,
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="action" />
                </Text>
            ),
            renderCell: (params) => (
                <Action
                    setEdit={() => {
                        setEdit(true);
                        setCurrentItem(params.row);
                    }}
                    setDisable={(value: boolean) => {
                        setOpenDisable(value);
                        setCurrentItem(params.row);
                    }}
                />
            )
        }
    ];

    const handleDisable = async () => {
        setOpenDisable(false);
        setEdit(false);
        setStatus(1);
        if (currentItem) await disableWorkplaceType(currentItem.id.toString());
        await getWorkplaceTypes(setworkplaceTypes, requestParams, setPaginationInfo);
        setStatus(0);
    };

    useEffect(() => {
        if (searchText !== undefined) {
            setRequestParams((r) => ({
                ...r,
                filters: [...(r.filters?.filter((f) => f.name !== 'name') || []), { name: 'name', value: searchText }]
            }));
        }
    }, [searchText]);

    useEffect(() => {
        if (workplaceTypes.length > 0) {
            setStatus(0);
        }
        if (workplaceTypes.length === 0 && paginationInfo) {
            setStatus(2);
        }
    }, [workplaceTypes, paginationInfo]);

    useEffect(() => {
        getWorkplaceTypesList(setSubTypesList);
    }, []);

    useEffect(() => {
        setStatus(1);
        getWorkplaceTypes(setworkplaceTypes, requestParams, setPaginationInfo);
    }, [requestParams]);

    const onPageChange = (newPage: number) => {
        setRequestParams({ ...requestParams, page: newPage });
        setStatus(1);
        getWorkplaceTypes(setworkplaceTypes, { ...requestParams, page: newPage }, setPaginationInfo);
    };

    const onPageSizeChange = (per_page: number) => {
        setRequestParams({ ...requestParams, per_page, page: 1 });
        setStatus(1);
        getWorkplaceTypes(setworkplaceTypes, { ...requestParams, per_page, page: 1 }, setPaginationInfo);
    };

    const debouncedSearchTextChange = useMemo(() => debounce(setSearchText, 300), []);

    const onSortModelChange = (model: GridSortModel) => {
        setRequestParams({ ...requestParams, sort: model });
    };

    const refresh = () => {
        setStatus(1);
        getWorkplaceTypes(setworkplaceTypes, { ...requestParams, filters: [] }, setPaginationInfo);
    };

    console.log(workplaceTypes);

    return (
        <MainCard>
            <SubSearchSection
                filters={requestParams.filters}
                setFilters={(filters) => setRequestParams({ ...requestParams, filters })}
                filter={openFilter}
                setFilter={setOpenFilter}
                availableFilters={paginationInfo?.available_filters}
                setSearchText={debouncedSearchTextChange}
                printRef={componentRef}
                label="סוגי מוסדות חינוך"
                setAdd={(value: boolean) => setAdd(value)}
            />
            {status !== 0 ? (
                <NotFound status={status} columnsLoaded={columns.length} tryAgain={refresh} />
            ) : (
                <>
                    <div ref={componentRef}>
                        <Table
                            onCellClick={(params) => {
                                const item = workplaceTypes.filter((mc) => mc.name === params.row.name)[0];
                                setCurrentItem(item);
                                if (params.field !== 'action') setEdit(true);
                            }}
                            sortModel={requestParams.sort}
                            onSortModelChange={onSortModelChange}
                            onPageSizeChange={onPageSizeChange}
                            onPageChange={onPageChange}
                            paginationInfo={paginationInfo}
                            page={requestParams.page}
                            rows={workplaceTypes}
                            columns={columns}
                        />
                    </div>
                </>
            )}
            <GlobalDisableDialog
                open={openDisable}
                onClose={() => setOpenDisable(false)}
                onDisable={handleDisable}
                coloredText={`${currentItem?.name}`}
                title1="Are you sure you want to disable the"
                title2="Workplace Type?"
            />
            <GlobalDrawer
                open={edit}
                onClose={() => {
                    setEdit(false);
                    refresh();
                }}
                Component={
                    <EditMethod
                        edit
                        onClose={() => {
                            setEdit(false);
                            refresh();
                        }}
                        id={currentItem?.id}
                        setOpenDisable={setOpenDisable}
                    />
                }
            />
            <GlobalDrawer
                open={add}
                onClose={() => {
                    setAdd(false);
                    refresh();
                }}
                Component={
                    <EditMethod
                        edit={false}
                        onClose={() => {
                            setAdd(false);
                            refresh();
                        }}
                    />
                }
            />
        </MainCard>
    );
};

export default WorkplaceTypes;
