import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';

// project imports
import Logo from 'assets/images/logo-partner.svg';
import { useSelector } from 'store';

// ==============================|| PARTNER LOGO ||============================== //

const PartnerLogoSection: React.FC<{ color?: 'black' | 'color' }> = ({ color }) => {
    const { user }: any = useSelector((state) => state.auth);
    console.log('user: ', user);
    return (
        <Link component={RouterLink} to="/messages-dashboard">
            <img
                src={user?.logo_url || Logo}
                style={color === 'black' ? { filter: 'grayscale(100%)' } : undefined}
                alt="Mesayaat"
                height="36"
            />
        </Link>
    );
};

export default PartnerLogoSection;
