import { User, UserDetails } from 'types/auth';
import { PaginationInfo, RequestParams } from 'types/paginationInfo';
import axios from 'axios';

export const getUsers = async (
    setUsers: (val: User[]) => void,
    requestParams: RequestParams,
    setPaginationInfo?: (val: PaginationInfo) => void
) => {
    try {
        const params: any = {
            page: requestParams.page,
            per_page: requestParams.per_page,
            sort: `${requestParams.sort[0].sort === 'desc' ? '-' : ''}${requestParams.sort[0].field}`
        };
        requestParams.filters?.forEach((filter) => {
            params[`filter[${filter.name}]`] = filter.value;
        });
        const response = await axios.get('/users', { params });
        const userData: User[] = [];
        response.data.users.data.forEach((item: User) => {
            userData.push({ ...item, user_name: `${item.first_name} ${item.last_name}` });
        });

        let availableFilters: any = [];
        response.data.users.filters.forEach((filter: any) => {
            availableFilters = [...availableFilters, ...filter.items.options];
        });

        setPaginationInfo?.({
            total: response.data.users.total,
            last_page: response.data.users.last_page,
            per_page: response.data.users.per_page,
            sortable_fields: availableFilters.map((a: any) => a.name),
            available_filters: response.data.users.filters
        });
        setUsers(userData);
    } catch (err) {
        console.error(`error in getting users ${err}`);
    }
};

export const updateUser = async (editedUser: UserDetails) => {
    const body = {
        user: {
            first_name: editedUser.first_name,
            last_name: editedUser.last_name,
            address: editedUser.address,
            phone_number: editedUser.phone_number,
            email: editedUser.email,
            personal_id: editedUser.personal_id,
            role_id: editedUser.role_id,
            authorities: editedUser.authorities.map(({ id }) => id),
            user_groups: editedUser.user_groups.map(({ id }) => id)
        }
    };
    const response = await axios.put(`/users/${editedUser.id}`, body);
    return response.status;
};

export const getUser = async (idenntity: string, setUser: (value: UserDetails) => void) => {
    try {
        const response = await axios.get(`/users/${idenntity}`);
        setUser(response.data.user);
    } catch (err) {
        console.error(`error in get user ${err}`);
    }
};

export const disableUser = async (id: string) => {
    try {
        await axios.delete(`/users/${id}`);
    } catch (err) {
        console.error(`error in disable user ${err}`);
    }
};

export const resetUser = async (email: string) => {
    const body = { email };
    try {
        return await axios.post('/forgot-password', body);
    } catch (err) {
        console.error(`error in reset user ${err}`);
        throw err;
    }
};

export const changePassword = async (email: string, password: string, password_confirmation: string) => {
    const body = { email, password, password_confirmation };
    try {
        return await axios.post('/change-password', body);
    } catch (err) {
        console.error(`error in reset password ${err}`);
        throw err;
    }
};

export const createUser = async (user: UserDetails) => {
    const body = {
        first_name: user.first_name,
        last_name: user.last_name,
        personal_id: user.personal_id,
        address: user.address,
        phone_number: user.phone_number,
        email: user.email,
        role_id: user.role_id,
        authorities: user.authorities.map(({ id }) => id),
        user_groups: user.user_groups.map(({ id }) => id)
    };
    const response = await axios.post('/users', body);
    return response.status;
};

export default getUsers;
