import { Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { WorkplaceContact } from 'types/workplace';
import { DeleteTwoTone } from '@mui/icons-material';

import MainCard from 'ui-component/cards/MainCard';
import GlobalDisableDialog from 'views/common/GlobalDisableDialog';

import { useState } from 'react';

export interface ContactPersonProps {
    index: number;
    handleContactChange?: (field: any, value: any, index: number) => void;
    contact: WorkplaceContact;
    disableContact: any;
    refreshData: () => void;
}

function ContactPerson(props: ContactPersonProps) {
    const { index, handleContactChange, contact, disableContact, refreshData } = props;
    const [openDisable, setOpenDisable] = useState<boolean>(false);

    const handleDisable = async () => {
        setOpenDisable(false);
        console.log('disable FE!');
        await disableContact(contact.id);
        refreshData();
    };

    return (
        <MainCard
            headerSX={{ p: 1 }}
            sx={{ mb: 2 }}
            title={
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1" p={1}>
                        <FormattedMessage id="contact-person" />
                        &nbsp; {index + 1}
                    </Typography>
                    <IconButton onClick={() => setOpenDisable?.(true)}>
                        <DeleteTwoTone fontSize="small" />
                    </IconButton>
                </Box>
            }
        >
            <Grid sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        sx={{ my: 2, pr: 2 }}
                        value={contact?.first_name}
                        onChange={(e) => handleContactChange?.('first_name', e.target.value, index)}
                        label={<FormattedMessage id="first-name" />}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        sx={{ my: 2, pr: 2 }}
                        value={contact?.last_name}
                        onChange={(e) => handleContactChange?.('last_name', e.target.value, index)}
                        label={<FormattedMessage id="last-name" />}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        sx={{ my: 2 }}
                        value={contact?.role}
                        onChange={(e) => handleContactChange?.('role', e.target.value, index)}
                        label={<FormattedMessage id="role" />}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        sx={{ my: 2, pr: 2 }}
                        value={contact?.phone_number}
                        onChange={(e) => handleContactChange?.('phone_number', e.target.value, index)}
                        label={<FormattedMessage id="phone-number" />}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        sx={{ my: 2 }}
                        value={contact?.email}
                        onChange={(e) => handleContactChange?.('email', e.target.value, index)}
                        label={<FormattedMessage id="email" />}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
            <GlobalDisableDialog
                open={openDisable}
                onClose={() => setOpenDisable(false)}
                onDisable={handleDisable}
                coloredText={`${contact.first_name} ${contact.last_name}`}
                // coloredText="hey"
                title1="Are you sure you want to disable "
                title2="?"
                subText="By disabling this contact person he/she will be deleted from the database"
            />
        </MainCard>
    );
}

export default ContactPerson;
