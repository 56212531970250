// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import PartnerLogoSection from '../PartnerLogoSection';
import MerkazShilton from 'ui-component/MerkazShilton';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import Divider from '@mui/material/Divider';

// assets
import { IconMenu2 } from '@tabler/icons';
import { display } from '@mui/system';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    return (
        <Box
            sx={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                borderRadius: '8px',
                padding: '12px 10px 12px 10px',
                margin: 0
            }}
        >
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 380,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    },
                    gap: 2
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 0 }}>
                    <LogoSection />
                </Box>

                {/* {divider vertical} */}
                <Divider
                    orientation="vertical"
                    variant="fullWidth"
                    flexItem
                    sx={{
                        width: 1.5,
                        height: '40px',
                        backgroundColor: '#E7E7E7',
                        display: { xs: 'none', md: 'flex' }
                    }}
                />

                <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                    <MerkazShilton />
                </Box>
                {/* partner-logo */}
                <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                    <PartnerLogoSection />
                </Box>
            </Box>

            {/* header search */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* mega-menu */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <MegaMenuSection />
            </Box> */}

            {/* live customization & localization
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box>
            */}

            <Avatar
                variant="rounded"
                sx={{
                    display: { xs: 'flex', md: 'none' },
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    overflow: 'hidden',
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                    '&:hover': {
                        background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                    }
                }}
                onClick={() => dispatch(openDrawer(!drawerOpen))}
                color="inherit"
            >
                <IconMenu2 stroke={1.5} size="1.3rem" />
            </Avatar>
            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
            {/* profile */}
            <ProfileSection />
        </Box>
    );
};

export default Header;
