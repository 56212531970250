// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// auth provider
import AuthProvider from './auth/AuthProvider';
import { store, useDispatch } from './store/index';
import userManager, { loadUserFromStorage } from './auth/UserService';
import axios from 'axios';
import { useEffect } from 'react';
import { openSnackbar } from 'store/slices/snackbar';
import { useNavigate } from 'react-router-dom';

// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //
const App = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    axios.interceptors.response.use(
        (r) => r,
        (error) => {
            let isShow = true;
            let snackbarContent: React.ReactNode = <></>;

            if (error?.response?.data?.message.includes('users.users_email_unique')) {
                isShow = false;
            }

            if (error.response.data.errors && Object.keys(error.response.data.errors > 0)) {
                snackbarContent = Object.keys(error.response.data.errors).map((err) => (
                    <p style={{ padding: 0, margin: 0 }}> {error.response.data.errors[err]}</p>
                ));
            } else {
                snackbarContent = <p style={{ padding: 0, margin: 0 }}> {error.response.data.message}</p>;
            }
            if (error.response.status === 401) {
                snackbarContent = <p style={{ padding: 0, margin: 0 }}> Unauthorized </p>;
                navigate('login', { replace: true });
            }

            if (isShow) {
                dispatch(
                    openSnackbar({
                        open: true,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        actionButton: false,
                        alertSeverity: 'error',
                        children: snackbarContent
                    })
                );
            }

            throw error;
        }
    );

    useEffect(() => {
        loadUserFromStorage(store);
    }, []);

    return (
        <ThemeCustomization>
            {/* RTL layout */}
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <>
                            <AuthProvider userManager={userManager} store={store}>
                                <Routes />
                            </AuthProvider>
                            <Snackbar />
                        </>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
