import { Drawer } from '@mui/material';
import React from 'react';

export interface DialogProps {
    onClose: () => void;
    open: boolean;
    Component: React.ReactNode;
    width?: string;
    padding?: string;
    height?: string;
}

const GlobalDrawer = (props: DialogProps) => {
    const { onClose, open, Component, width = '430px', padding = '20px', height = `calc(100% - 130px)` } = props;

    const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            onClose();
            return;
        }
        onClose();
    };

    return (
        <div>
            <Drawer
                PaperProps={{ style: { padding, width, height, marginTop: '130px' } }}
                anchor="right"
                open={open}
                onClose={toggleDrawer()}
            >
                {Component}
            </Drawer>
        </div>
    );
};
export default GlobalDrawer;
