import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    styled,
    TextField,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Workplace, WorkplaceContact, Attachment } from 'types/workplace';
import MainCard from 'ui-component/cards/MainCard';
import { createWorkplace, getWorkplace, updateWorkplace, disableContact, disableWorkplace } from '../../../services/WorkplaceService';
import ContactPerson from './ContactPerson';
import {
    getAgeRangesList,
    getAuthoritiesList,
    getWorkingHoursDataList,
    getWorkplaceTypeList,
    getSpecificHoursList
} from 'services/ListService';
import { AddCircleOutline, DeleteTwoTone, PictureAsPdfTwoTone } from '@mui/icons-material';
import GlobalDisableDialog from 'views/common/GlobalDisableDialog';
import { useSelector } from 'store';

export interface AddWorkplaceProps {
    onClose?: () => void;
    refreshData?: () => void;
}

const AddWorkplace = (props: AddWorkplaceProps) => {
    const { onClose, refreshData } = props;
    const { id } = useParams();
    const theme = useTheme();
    const navigate = useNavigate();
    const [workplace, setWorkplace] = useState<Workplace>({
        id: 0,
        authority_id: 0,
        name: '',
        symbol: '',
        neighborhood: '',
        address: '',
        number_of_children: 0,
        amount_of_workers: '',
        workplace_type_id: 1,
        working_hour_id: 1,
        contacts: [],
        waze_url: '',
        age_range_id: -1,
        notes: '',
        workplace_contact_id: 0,
        workplace_phone: '',
        attachments: []
    });
    const [contactError, setContactError] = useState(false);
    const [contacts, setContacts] = useState<WorkplaceContact[]>([]);
    const [workplaceTypeList, setWorkplaceTypeList] = useState<{ id: string; label: string }[]>([]);
    const [authoritiesList, setAuthoritiesList] = useState<{ id: string; label: string }[]>([]);
    const [workingHoursList, setWorkingHoursList] = useState<{ id: string; label: string }[]>([]);
    const [ageRangeList, setAgeRangeList] = useState<{ id: string; label: string }[]>([]);
    const [openDisable, setOpenDisable] = useState<boolean>(false);
    const [openSave, setOpenSave] = useState<boolean>(false);
    const intl = useIntl();
    const { user: userStored }: any = useSelector((state) => state.auth);

    useEffect(() => {
        getWorkplaceTypeList(setWorkplaceTypeList);
        getAuthoritiesList(setAuthoritiesList);
        getWorkingHoursDataList(setWorkingHoursList);
        getAgeRangesList(setAgeRangeList);
        setFirstTwoContacts();
    }, []);

    useEffect(() => {
        if (id) {
            getWorkplace(id, setWorkplace, setContacts);
        }
    }, []);

    useEffect(() => {
        if (workplace.authority_id) {
            console.log('authorityid');
            getSpecificHoursList(workplace.authority_id, setWorkingHoursList);
        }
    }, [workplace.authority_id]);

    const handleSave = async () => {
        if (id) {
            const response = await updateWorkplace(workplace, contacts);
            if (response.status === 200) navigate(`/workplaces`);
        } else {
            const response = await createWorkplace(workplace, contacts);
            if (response.status === 201) navigate(`/workplaces`);
        }

        refreshData?.();
        onClose?.();
    };

    const handleDisable = async () => {
        setOpenDisable(false);
        await disableWorkplace(workplace.id.toString());
        navigate(`/workplaces`);
    };

    const handleContactChange = (field: any, value: any, index: number) => {
        const items = [...contacts];
        const item: any = { ...items[index] };
        item[field] = value;
        items[index] = item;
        setContacts(items);
    };

    const handleAuthorityChange = (e: any) => {
        setWorkplace({ ...workplace, authority_id: Number(e.target.value) });
        getSpecificHoursList(e.target.value, setWorkingHoursList);
    };
    console.log('workingHoursList', workingHoursList);
    const setFirstTwoContacts = () => {
        setContacts([
            ...contacts,
            {
                id: 1,
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                role: ''
            }
            // {
            //     id: 2,
            //     first_name: '',
            //     last_name: '',
            //     email: '',
            //     phone_number: '',
            //     role: ''
            // }
        ]);
    };

    const handleFileChange = (e: any) => {
        const [file] = e.target.files;
        setWorkplace({ ...workplace, attachments: [...workplace.attachments!, { path: file.name || '', upload_data: file }] });
    };

    const handleRemovefile = async (index: number) => {
        const arrFiltered = await workplace.attachments!.filter((a, i) => i !== index);
        setWorkplace({ ...workplace, attachments: arrFiltered });
    };

    const refresh = () => {
        if (id) {
            getWorkplace(id, setWorkplace, setContacts);
        }
    };

    const increaseContactHandler = () => {
        setContacts([
            ...contacts,
            {
                id: -1,
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                role: ''
            }
        ]);
    };

    const LabelStyle = styled(InputLabel)({
        '&:hover': {
            border: 'none'
        },
        border: 'none',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 500,
        color: theme.palette.primary.main,
        paddingBottom: '20px',
        cursor: 'pointer',
        marginRight: '5px'
    });

    return (
        <MainCard
            title={
                id ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <FormattedMessage id="edit-workplace" />
                        <IconButton onClick={() => setOpenDisable?.(true)}>
                            <DeleteTwoTone fontSize="small" />
                        </IconButton>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <FormattedMessage id="add-workplace" />
                        <IconButton onClick={() => setOpenDisable?.(true)}>
                            <DeleteTwoTone fontSize="small" />
                        </IconButton>
                    </Box>
                )
            }
        >
            <Grid container sx={{ display: 'flex', flexWrap: 'wrap', gap: '1em' }}>
                <Grid item xs={6}>
                    <MainCard
                        headerSX={{ p: 1 }}
                        border
                        sx={{ border: `1px solid ${theme.palette.primary[800]}`, p: 0 }}
                        contentSX={{ p: 2, paddingBottom: '0px !important' }}
                        title={
                            <Typography variant="subtitle1" p={1}>
                                <FormattedMessage id="add-workplace" />
                            </Typography>
                        }
                    >
                        <Grid sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Grid item xs={5}>
                                <TextField
                                    sx={{ my: 2, pr: 2 }}
                                    select
                                    fullWidth
                                    label={<FormattedMessage id="local-authority" />}
                                    value={workplace.authority_id}
                                    onChange={(e) => handleAuthorityChange(e)}
                                >
                                    {authoritiesList.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    fullWidth
                                    sx={{ my: 2, pr: 2 }}
                                    value={workplace?.name}
                                    onChange={(e) => setWorkplace({ ...workplace, name: e.target.value })}
                                    label={<FormattedMessage id="workplace-name" />}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    disabled={userStored?.role_id !== 2} // disable if not admin
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    sx={{ my: 2 }}
                                    value={workplace?.symbol}
                                    onChange={(e) => setWorkplace({ ...workplace, symbol: e.target.value })}
                                    label={<FormattedMessage id="symbol" />}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={userStored?.role_id !== 2} // disable if not admin
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    sx={{ my: 2, pr: 2, gridColumn: 'span 2' }}
                                    value={workplace?.neighborhood}
                                    onChange={(e) => setWorkplace({ ...workplace, neighborhood: e.target.value })}
                                    label={<FormattedMessage id="neighborhood" />}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    sx={{ my: 2 }}
                                    value={workplace?.address}
                                    onChange={(e) => setWorkplace({ ...workplace, address: e.target.value })}
                                    label={<FormattedMessage id="institution-address" />}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    sx={{ my: 2, pr: 2 }}
                                    value={workplace?.number_of_children}
                                    onChange={(e) => setWorkplace({ ...workplace, number_of_children: Number(e.target.value) })}
                                    label={<FormattedMessage id="number-of-children-in-kindergarten" />}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    sx={{ my: 2, pr: 0 }}
                                    value={workplace?.workplace_phone}
                                    onChange={(e) => setWorkplace({ ...workplace, workplace_phone: e.target.value })}
                                    label={<FormattedMessage id="workplace-phone" />}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" sx={{ my: 2, ml: 2 }}>
                                        <FormattedMessage id="ages" />
                                    </InputLabel>
                                    <Select
                                        sx={{ my: 2, ml: 2 }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        fullWidth
                                        label={<FormattedMessage id="ages" />}
                                        value={workplace.age_range_id}
                                        onChange={(e) => setWorkplace({ ...workplace, age_range_id: Number(e.target.value) })}
                                    >
                                        {ageRangeList.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3.7}>
                                <TextField
                                    fullWidth
                                    sx={{ my: 2, ml: 4, pr: 2 }}
                                    value={workplace?.amount_of_workers}
                                    onChange={(e) => setWorkplace({ ...workplace, amount_of_workers: e.target.value })}
                                    label={<FormattedMessage id="amount-of-workers" />}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ my: 2 }}>
                                    <FormLabel id="demo-radio-buttons-group-label">
                                        <FormattedMessage id="workplace_types" />
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue={1}
                                        value={workplace?.workplace_type_id}
                                        onChange={(e) => setWorkplace({ ...workplace, workplace_type_id: Number(e.target.value) })}
                                        name="radio-buttons-group"
                                        sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}
                                    >
                                        {workplaceTypeList.map((item) => (
                                            <FormControlLabel value={item.id} control={<Radio />} label={item.label} />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ my: 2 }}>
                                    <FormLabel id="demo-radio-buttons-group-label">
                                        <FormattedMessage id="working-hours" />
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue={1}
                                        value={workplace?.working_hour_id}
                                        onChange={(e) => setWorkplace({ ...workplace, working_hour_id: Number(e.target.value) })}
                                        name="radio-buttons-group"
                                        sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}
                                    >
                                        {/* {workingHoursList.map((item) => ( */}
                                        <FormControlLabel value={1} control={<Radio />} label="בוקר" />
                                        <FormControlLabel value={2} control={<Radio />} label="צהרים" />

                                        {/* ))} */}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                    <FormattedMessage id="Waze url" />
                                </FormLabel>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '1em'
                                    }}
                                >
                                    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M23.5392 8.45328C24.2198 12.478 22.0888 16.3245 18.2343 18.2094C18.8437 19.8078 17.6531 21.5 15.9693 21.5C15.3489 21.4995 14.7523 21.261 14.3026 20.8337C13.8528 20.4063 13.5841 19.8227 13.552 19.2031C13.2501 19.212 10.5426 19.2031 9.97399 19.1731C9.96114 19.4912 9.88573 19.8037 9.75206 20.0926C9.61839 20.3816 9.42908 20.6414 9.19496 20.8571C8.96084 21.0729 8.6865 21.2404 8.38761 21.35C8.08872 21.4597 7.77115 21.5094 7.45305 21.4963C5.86587 21.4325 4.73665 19.8631 5.24993 18.3125C3.50571 17.698 1.84962 16.678 0.580242 14.9938C-0.029133 14.1838 0.557742 13.0344 1.55712 13.0344C3.7279 13.0344 3.06743 10.4952 3.57977 7.86594C4.44368 3.4625 9.05243 0.5 13.5041 0.5C18.3079 0.5 22.7456 3.81266 23.5392 8.45328ZM17.5082 17.2006C19.477 16.3016 21.3206 14.5423 22.0218 12.4128C23.9193 6.64297 19.0148 1.72531 13.5041 1.72531C9.59243 1.72531 5.5204 4.32312 4.78212 8.10031C4.3354 10.393 5.01649 14.2573 1.55946 14.2573C2.72852 15.8094 4.29368 16.7239 5.95305 17.2597C7.10899 16.2378 8.94696 16.5345 9.69509 17.9319C10.3616 17.9788 13.4071 17.9872 13.8154 17.9703C13.9803 17.6483 14.2151 17.3673 14.5028 17.1479C14.7905 16.9285 15.1236 16.7763 15.4778 16.7025C15.8319 16.6287 16.1981 16.6351 16.5495 16.7213C16.9008 16.8075 17.2284 16.9713 17.5082 17.2006ZM9.61493 7.77172C9.61493 6.14328 11.9981 6.14281 11.9981 7.77172C11.9981 9.40063 9.61493 9.40016 9.61493 7.77172ZM15.0791 7.77172C15.0791 6.14328 17.4632 6.14281 17.4632 7.77172C17.4632 9.40063 15.0791 9.40063 15.0791 7.77172ZM9.3318 11.0853C9.17055 10.2913 10.3715 10.0456 10.5327 10.8411L10.5356 10.8542C10.7296 11.8583 11.9348 12.9167 13.5412 12.8731C15.2137 12.8291 16.3185 11.832 16.5463 10.8683C16.7554 10.1159 17.887 10.3827 17.7402 11.1495C17.4951 12.1892 16.2773 14.0558 13.4531 14.098C11.4585 14.098 9.6618 12.793 9.33274 11.0863L9.3318 11.0853Z"
                                            fill="#9E9E9E"
                                        />
                                    </svg>
                                    <TextField
                                        fullWidth
                                        sx={{ my: 2 }}
                                        value={workplace?.waze_url}
                                        onChange={(e) => setWorkplace({ ...workplace, waze_url: e.target.value })}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                    <FormattedMessage id="Map url" />
                                </FormLabel>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '1em'
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        sx={{ my: 2 }}
                                        value={workplace?.map_url}
                                        onChange={(e) => setWorkplace({ ...workplace, map_url: e.target.value })}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                    <FormattedMessage id="notes" />
                                </FormLabel>
                                <TextField
                                    multiline
                                    rows={2}
                                    fullWidth
                                    sx={{ my: 2 }}
                                    value={workplace?.notes}
                                    onChange={(e) => setWorkplace({ ...workplace, notes: e.target.value })}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
                <Grid item xs={5.8}>
                    {contacts.map((item, i) => (
                        <ContactPerson
                            key={i}
                            index={i}
                            handleContactChange={handleContactChange}
                            contact={contacts[i]}
                            disableContact={disableContact}
                            refreshData={refresh}
                        />
                    ))}

                    <Grid item xs={12}>
                        <Button
                            onClick={increaseContactHandler}
                            variant="outlined"
                            sx={{ width: '100%', mt: 3, justifyContent: 'space-between' }}
                            size="large"
                        >
                            <FormattedMessage id="add-contact-person" />
                            <KeyboardArrowDownIcon />
                        </Button>
                    </Grid>
                    <MainCard
                        paddingHeader="0px !important"
                        border
                        borderColor={theme.palette.primary[800]}
                        sx={{ border: 'none', p: 0, width: '100%' }}
                        contentSX={{ p: 0, paddingBottom: '0px !important' }}
                        title={
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '30px', marginTop: '10px' }}>
                                <LabelStyle htmlFor="file-input" onChange={handleFileChange} sx={{ marginBottom: '0px' }}>
                                    <AddCircleOutline fontSize="small" sx={{ position: 'relative', bottom: '-5px', right: '2px' }} />
                                    <FormattedMessage id="add-new-file" />
                                    <Input id="file-input" type="file" sx={{ display: 'none', pl: 2 }} />
                                </LabelStyle>
                            </Box>
                        }
                    >
                        <Grid container>
                            {workplace.attachments!.length > 0 ? (
                                <>
                                    {workplace.attachments!.map((attachment, key) => (
                                        <Grid xs={12} key={`attachments-item${key}`} item sx={{ height: '70px' }}>
                                            <Divider orientation="horizontal" sx={{ borderColor: theme.palette.primary.light }} />

                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <IconButton
                                                        key={`attach-file${key}`}
                                                        onClick={() => window.open(attachment.path, '_blank')}
                                                        sx={{ ml: 2, mt: 1 }}
                                                    >
                                                        <PictureAsPdfTwoTone fontSize="large" />
                                                    </IconButton>
                                                    <Box sx={{ mt: 2 }}>
                                                        <Typography fontWeight="bold">
                                                            {attachment.name ||
                                                                attachment.path.substring(attachment.path.lastIndexOf('/') + 1)}
                                                        </Typography>
                                                        <Typography variant="caption">1.23 MB</Typography>
                                                    </Box>
                                                </Box>
                                                <IconButton sx={{ mr: 1 }} onClick={() => handleRemovefile(key)}>
                                                    <DeleteTwoTone fontSize="medium" />
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                    ))}
                                </>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
            <Grid container sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Grid item xs={6}>
                    <Button
                        onClick={(value: any) => navigate(`/workplaces`)}
                        variant="text"
                        sx={{ width: '20%', my: 2, gap: '1em' }}
                        size="large"
                    >
                        <ArrowBackIcon />
                        <FormattedMessage id="back" />
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        onClick={() => setOpenSave?.(true)}
                        variant="contained"
                        sx={{
                            width: '20%',
                            my: 2,
                            ml: '80%'
                        }}
                        size="large"
                    >
                        <FormattedMessage id="save" />
                    </Button>
                </Grid>
            </Grid>
            <GlobalDisableDialog
                open={openDisable}
                onClose={() => setOpenDisable(false)}
                onDisable={handleDisable}
                coloredText={workplace.name}
                title1="Are you sure you want to disable "
                title2="workplace?"
                subText="By disabling this workplace it will be deleted from the database"
            />
            <GlobalDisableDialog
                open={openSave}
                onClose={() => setOpenSave(false)}
                onDisable={handleSave}
                coloredText={workplace.name}
                title1={intl.formatMessage({ id: 'are_you_sure' })}
                title2="?"
                subText={intl.formatMessage({ id: 'on_click_save' })}
                labelButton="save"
            />
        </MainCard>
    );
};

export default AddWorkplace;
