// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';

export const SET_LOGIN = 'SET_LOGIN';
export const STORE_USER = 'STORE_USER';
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT';
export const USER_EXPIRED = 'USER_EXPIRED';
export const STORE_USER_ERROR = 'STORE_USER_ERROR';
export const LOADING_USER = 'LOADING_USER';

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';
