import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import shilton from 'assets/images/merkaz-shilton.png';

// ==============================|| MAIN LOGO ||============================== //
interface LogoParams {
    height?: number;
    color?: 'black' | 'color' | undefined;
}
const MerkazShilton = ({ height, color }: LogoParams) => (
    <Link component={RouterLink} to="/messages-dashboard" sx={{ display: 'inline-block' }}>
        <img
            src={shilton}
            style={color === 'black' ? { filter: 'grayscale(100%)' } : undefined}
            alt="מרכז השלטון המקומי בישראל"
            height={height || '37'}
        />
        ;
    </Link>
);

export default MerkazShilton;
