import { useState, useEffect } from 'react';
import { Box, MenuItem, Button, Typography, styled, TextField, IconButton } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { getWorkplaceTypesList } from 'services/ListService';
import { createWorkplaceType, editWorkplaceType, getWorkplaceType } from 'services/WorkplaceService';
import { useUnload } from 'hooks/useUnload';
import { DeleteTwoTone } from '@mui/icons-material';
import { WorkplaceType } from 'types/workplace';

export interface editProps {
    id?: number;
    name: string;
    parent_id: string | null;
    reference_id: string;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date | null;
}

export interface editWorkplaceTypeProps {
    id?: number;
    edit: boolean;
    onClose: () => void;
    setOpenDisable?: (val: boolean) => void;
}

const EditWorkplaceType = (props: editWorkplaceTypeProps) => {
    const { onClose, edit, id, setOpenDisable } = props;
    const [workplaceType, setWorkplaceType] = useState<WorkplaceType>({ id: -1, name: '', subtype: null, related: '' });
    const [subtypesList, setSubTypesList] = useState<{ id: string; label: string }[]>([]);
    const [valueStatus, setValueStatus] = useState<number>(0); // 1: Data received, 2: Data changed in local, 0: None received

    useUnload((e: any) => {
        const confirmationMessage = 'Are you sure?/';
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
    }, valueStatus === 2);

    const TitleText = styled(Typography)({
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        textAlign: 'left',
        margin: 0,
        marginTop: '20px',
        marginBottom: '6.5px'
    });

    const ButtonStyle = styled(Button)(({ theme }) => ({
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        width: '390px',
        height: '44px',
        color: `${theme.palette.primary.light} !important`
    }));

    const handleSave = async () => {
        let response = 400;
        if (!edit) response = await createWorkplaceType(workplaceType);
        else if (id) response = await editWorkplaceType(workplaceType);
        if (response === 200 || response === 201) onClose();
    };

    useEffect(() => {
        getWorkplaceTypesList(setSubTypesList);
    }, []);

    useEffect(() => {
        if (edit && id) getWorkplaceType(id, setWorkplaceType);
    }, []);

    useEffect(() => {
        if (workplaceType.id && valueStatus < 1) setValueStatus(1);
        if (valueStatus === 1) setValueStatus(2);
    }, [workplaceType]);

    return (
        <Box>
            <Box display="flex" justifyContent="space-between">
                <TitleText variant="h3">
                    <FormattedMessage id={edit ? 'edit-workplace-type' : 'add-workplace-type'} />
                </TitleText>
                {edit && (
                    <IconButton sx={{ mt: 2 }} onClick={() => setOpenDisable?.(true)}>
                        <DeleteTwoTone fontSize="small" />
                    </IconButton>
                )}
            </Box>
            <TextField
                sx={{ marginTop: '45px', marginBottom: '50px' }}
                id="outlined-select-currency"
                fullWidth
                label={<FormattedMessage id="type-workplace" />}
                value={workplaceType.name}
                onChange={(e) => setWorkplaceType({ ...workplaceType, name: e.target.value })}
            />
            <TextField
                sx={{ marginBottom: '50px' }}
                label={<FormattedMessage id="subtype" />}
                id="subtype-select"
                fullWidth
                InputLabelProps={{ shrink: !!workplaceType.subtype }}
                select
                value={workplaceType.subtype}
                onChange={(e) => setWorkplaceType({ ...workplaceType, subtype: Number(e.target.value) })}
            >
                {subtypesList.map((p: any, index) => (
                    <MenuItem key={index} value={p.id}>
                        {p.label}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                sx={{ marginBottom: '50px' }}
                id="outlined-select-currency"
                fullWidth
                label={<FormattedMessage id="related" />}
                value={workplaceType.related}
                onChange={(e) => setWorkplaceType({ ...workplaceType, related: e.target.value })}
            />
            <ButtonStyle onClick={handleSave} variant="contained">
                <FormattedMessage id="save" />
            </ButtonStyle>
        </Box>
    );
};
export default EditWorkplaceType;
