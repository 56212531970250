import { Typography } from '@mui/material';
import { useState } from 'react';

export interface TextSectionProps {
    label: string | React.ReactElement;
    content?: string | React.ReactNode;
    fontSize?: string;
    status: boolean;
    setHour: React.Dispatch<React.SetStateAction<string>>;
}

const EditTextSection = (props: TextSectionProps) => {
    const { label, content, fontSize = '0.771rem', status, setHour } = props;
    // const [value, setValue] = useState(String(content));
    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setValue(event.target.value);
        setHour(event.target.value);
    };

    return (
        <>
            <Typography sx={{ fontSize }} variant="subtitle1">
                {label}
            </Typography>
            {status ? (
                <Typography sx={{ fontSize }} component="span" variant="body2">
                    {content}
                </Typography>
            ) : (
                <Typography sx={{ fontSize }} component="span" variant="body2">
                    <input type="time" value={String(content)} onChange={handleValueChange} />
                </Typography>
            )}
        </>
    );
};
export default EditTextSection;
