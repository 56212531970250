// material-ui
// import { useTheme } from '@mui/material/styles';

import logo from 'assets/images/logo.svg';

// ==============================|| LOGO SVG ||============================== //

interface LogoParams {
    height?: number;
}
const Logo = ({ height = 34 }: LogoParams) => <img src={logo} alt="Berry" height={height} />;

export default Logo;
