import { Dialog, styled, Button, DialogActions, Typography, DialogContent, DialogContentText, TextField } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

export interface DialogProps {
    onDisable?: (reason: string) => void;
    coloredText: string;
    title1: string;
    title2: string;
    subText?: string;
    labelButton?: string;
    open: boolean;
    onClose: () => void;
}

const DisableWithReasonDialog = (props: DialogProps) => {
    const { labelButton, onClose, onDisable = onClose, open, coloredText, title1, title2, subText = '' } = props;
    const [reason, setReason] = useState<string>('');

    const ButtonStyle = styled(Button)(({ theme }) => ({
        height: '44px',
        width: '111px',
        borderRadius: '4px',
        padding: '12px, 16px, 12px, 16px',
        fontSize: '14px',
        lineHeight: '24px',
        color: `${theme.palette.primary.light}`,
        marginLeft: '25px !important'
    }));

    const TitleStyle = styled(Typography)(({ theme }) => ({
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'left',
        padding: 0
    }));

    const TextColor = styled('span')(({ theme }) => ({
        color: `${theme.palette.secondary.main} !important`
    }));

    return (
        <Dialog
            PaperProps={{ style: { padding: '40px' } }}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <TitleStyle variant="h3" sx={{ paddingLeft: 0, width: '411px' }} id="alert-dialog-title">
                {title1}
                <TextColor>&nbsp;{coloredText}&nbsp;</TextColor>
                {title2}
            </TitleStyle>
            <DialogContent sx={{ padding: 0 }}>
                <TextField
                    onChange={(e) => setReason(e.target.value)}
                    sx={{ mb: 2, mt: 4 }}
                    fullWidth
                    label={<FormattedMessage id="reason" />}
                    value={reason}
                />
                <DialogContentText id="alert-dialog-description">{subText}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ mt: 1 }}>
                <Button onClick={onClose} color="error">
                    <FormattedMessage id="cancel" />
                </Button>
                <ButtonStyle variant="contained" onClick={() => onDisable(reason)}>
                    <FormattedMessage id={labelButton || 'disable'} />
                </ButtonStyle>
            </DialogActions>
        </Dialog>
    );
};
export default DisableWithReasonDialog;
