import { Avatar, Box, Button, Divider, FormLabel, Grid, IconButton, Rating, TextField, Typography } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import Table from '../common/GlobalTable';
import { createRef, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import TextSection from 'views/common/TextSection';
import EditTextSection from 'views/common/EditTextSection';
import { styled, useTheme } from '@mui/styles';
import { GridColDef } from '@mui/x-data-grid';
import { cancelWorker, getJobDetails, markAsProblematic, saveWorkerComment, updateRating, disableJob } from 'services/JobService';
import NotFound from 'views/common/NotFound';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import RatingDialog from './RatingDialog';
import GlobalDisableDialog from 'views/common/GlobalDisableDialog';
import { format, parseISO } from 'date-fns';
import { approveMessage, saveEditedHours } from 'services/MessageService';
import { concat } from 'lodash';

export interface WorkerDetailsProps {
    jobIdProp?: string;
}
const JobDetails = (props: WorkerDetailsProps) => {
    const { jobIdProp } = props;
    const { jobId } = useParams();
    // const { rowId } = useParams();
    const id = jobIdProp || jobId;
    const [job, setJob] = useState<any>();
    const [openDisable, setOpenDisable] = useState<boolean>(false);
    const [ratingValue, setRatingValue] = useState<number>(0);
    const [jobStatus, setJobStatus] = useState<number | any>(null);
    const [problematic, setProblematic] = useState<boolean>(false);
    const [workerComment, setWorkerComment] = useState<string>('');
    const [messages, setMessages] = useState<any[]>([]);
    const [workers, setAllWorkers] = useState<any[]>([]);
    const [status, setStatus] = useState<number>(1); // 0: Ready, 1:Loading, 2: Not found
    const [openRateDialog, setOpenRateDialog] = useState<boolean>(false);
    const [startHour, setStartHour] = useState<string>('');
    const [finishHour, setFinishHour] = useState<string>('');
    const theme = useTheme();
    const navigate = useNavigate();
    const handleApprove = (id2: number) => {
        approveMessage(id2);
        setMessages(messages.filter((item: any) => item.id !== id2));
    };
    const Text = styled('p')({
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center'
    });

    const H3 = styled(Typography)({
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: '0em',
        textAlign: 'left',
        paddingLeft: '4px'
    });
    // Save new hours and set approved flag
    const handleApproveHours = async () => {
        const summary_id = job.summary.id;
        const start_hour = startHour;
        const finish_hour = finishHour;

        await saveEditedHours(summary_id, start_hour, finish_hour);
        if (id) {
            await fetchJobDetails();
        }
    };

    const fetchJobDetails = async () => {
        if (id) {
            await getJobDetails(id, setJob, setStatus, setRatingValue, setProblematic, setJobStatus);
        }
    };

    useEffect(() => {
        if (id) {
            fetchJobDetails();
        }
    }, []);
    console.log('Job: ', job);
    console.log('JobStatus: ', jobStatus);

    useEffect(() => {
        if (job && job.summary && job.summary.comment) {
            setWorkerComment(job.summary.comment);
        }
        if (job && job.summary && job.summary.reported_start_hour) {
            setStartHour(job.summary.final_start_hour);
        }
        if (job && job.summary && job.summary.reported_finish_hour) {
            setFinishHour(job.summary.final_finish_hour);
        }
        if (job && job.summary) {
            if (job.summary.worker)
                setAllWorkers([job.summary.worker].concat(job.summary?.canceled_workers).concat(job.summary?.refused_workers));
            else setAllWorkers(job.summary?.canceled_workers.concat(job.summary?.refused_workers));
        }
    }, [job]);

    const handleDisable = async () => {
        if (job) {
            await disableJob(job.id.toString());
            navigate('/jobs/list');
        }
        setOpenDisable(false);
    };
    const test = (value1: any) => {
        console.log(value1);
        return value1.value.name;
    };
    const setAsProblematic = async () => {
        if (job) {
            const params = { id: job.summary.id };
            await markAsProblematic(params, setProblematic);
        }
    };

    const updateJobRating = async (valueRating: number) => {
        if (job) {
            const params = { id: job.summary.id, rating: valueRating, job_id: job.id };
            await updateRating(params, setRatingValue, setJobStatus);
            setOpenRateDialog(false);
            navigate('/messages-dashboard');
        }
    };

    const cancelJobWorker = async (worker_id: string) => {
        if (job) {
            const params = { job_id: job.id, worker_id };
            await cancelWorker(params, setJobStatus);
            fetchJobDetails();
        }
    };
    const saveComment = async () => {
        const responseStatus = await saveWorkerComment({ id: job.summary.id, comment: workerComment }, setWorkerComment);
        if (responseStatus === 200)
            dispatch(
                openSnackbar({
                    open: true,
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    actionButton: false,
                    alertSeverity: 'sucess',
                    children: <FormattedMessage id="comment-saved" />
                })
            );
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="name" />
                </Text>
            )
        },
        // {
        //     field: 'address_authority',
        //     flex: 1,
        //     sortable: false,
        //     renderHeader: () => (
        //         <Text>
        //             <FormattedMessage id="local-authority" />
        //         </Text>
        //     ),
        //     renderCell: (params) => <div>{test(params)}</div>
        // },
        {
            field: 'address_street',
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="address" />
                </Text>
            )
        },
        {
            field: 'phone_number',
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="phone-number" />
                </Text>
            )
        },
        {
            field: 'rating',
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="approval-status" />
                </Text>
            ),
            renderCell: (params) => (
                <div>
                    {Number(params.row.position) === 2 && (
                        <Text style={{ color: theme.palette.secondary.main }}>
                            <FormattedMessage id="refused" />
                        </Text>
                    )}
                    {Number(params.row.position) === 3 && (
                        <Text style={{ color: theme.palette.secondary.main }}>
                            <FormattedMessage id="canceled" />
                        </Text>
                    )}

                    {jobStatus >= 3 && jobStatus < 8 && Number(params.row.position) === 1 && (
                        <Text style={{ color: theme.palette.secondary.main }}>
                            <FormattedMessage id="approved" />
                        </Text>
                    )}

                    {jobStatus === 8 && (
                        <Rating
                            sx={{
                                '& .MuiRating-iconFilled, .MuiRating-iconEmpty': {
                                    color: '#36BDAA'
                                }
                            }}
                            value={ratingValue}
                            onChange={(event, newValue: any) => {
                                setOpenRateDialog(true);
                            }}
                            size="small"
                            precision={0.1}
                        />
                    )}

                    {jobStatus === 9 && (
                        <Rating
                            sx={{
                                '& .MuiRating-iconFilled, .MuiRating-iconEmpty': {
                                    color: '#BDBDBD'
                                }
                            }}
                            value={ratingValue}
                            size="small"
                            readOnly
                            precision={0.1}
                        />
                    )}
                </div>
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                if (Number(params.row.position) !== 1) return <></>;
                return (
                    <div>
                        {(jobStatus === 3 || jobStatus === 4) && (
                            <Button
                                variant="contained"
                                size="small"
                                style={{ backgroundColor: theme.palette.error.main }}
                                onClick={() => cancelJobWorker(params?.id as string)}
                            >
                                <FormattedMessage id="cancel-application" />
                            </Button>
                        )}

                        {jobStatus >= 8 && problematic === false && (
                            <Button
                                variant="contained"
                                size="small"
                                style={{ backgroundColor: theme.palette.primary.light, color: theme.palette.primary.main }}
                                sx={{ fontSize: '12px' }}
                                onClick={() => setAsProblematic()}
                            >
                                <FormattedMessage id="mark-as-problematic" />
                            </Button>
                        )}

                        {jobStatus >= 8 && problematic === true && (
                            <Box
                                sx={{
                                    display: 'flex'
                                }}
                            >
                                <InfoOutlinedIcon style={{ color: '#EF5350' }} />
                                <Typography color="#EF5350" sx={{ fontSize: '12px', paddingLeft: 1 }}>
                                    <FormattedMessage id="marked-as-problematic" />
                                </Typography>
                            </Box>
                        )}
                    </div>
                );
            }
        }
    ];

    console.log('status', status);

    return (
        <MainCard
            title={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '32px', marginTop: '8px', ml: 2 }}>
                    <Box display="flex">
                        <FormattedMessage id="job" />
                        <Typography
                            variant="subtitle1"
                            sx={{
                                marginBottom: '30px',
                                marginRight: '5px',
                                marginLeft: '5px'
                            }}
                        >
                            #{job?.id}
                        </Typography>
                    </Box>
                    <Box mr={1} sx={{ display: jobIdProp || job?.deleted_at ? 'none' : 'block' }}>
                        <Button onClick={() => setOpenDisable(true)} color="error">
                            <FormattedMessage id="delete-job" />
                        </Button>
                    </Box>
                </Box>
            }
            headerSX={{ padding: '8px !important' }}
        >
            {job && (
                <>
                    <Grid container sx={{ justifyContent: 'space-between' }}>
                        <Grid item xs={2}>
                            <TextSection label={<FormattedMessage id="workplace-name" />} content={job.workplace?.name} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextSection
                                label={<FormattedMessage id="job-offer-date" />}
                                content={format(parseISO(job.job_date as string), 'dd.MM.yyyy')}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextSection
                                label={<FormattedMessage id="beginning-time" />}
                                content={job.workplace.working_hour.starting_hour}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextSection
                                label={<FormattedMessage id="finishing-time" />}
                                content={job.workplace.working_hour.finishing_hour}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextSection label={<FormattedMessage id="payment" />} content={`${job.salary} ₪`} />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ justifyContent: 'space-between', mt: 3 }}>
                        <Grid item xs={2}>
                            <TextSection label={<FormattedMessage id="note" />} content={job.workplace.notes || '-'} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextSection label={<FormattedMessage id="note" />} content={job.workplace.notes} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextSection
                                label={<FormattedMessage id="created_at" />}
                                content={format(parseISO(job.created_at as string), 'dd.MM.yyyy')}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextSection
                                label={<FormattedMessage id="status" />}
                                content={
                                    <Box
                                        bgcolor={jobStatus < 8 ? theme.palette.secondary.light : theme.palette.grey[200]}
                                        maxWidth="200px"
                                        p={1}
                                        borderRadius={1}
                                        display="inline-block"
                                    >
                                        {/* <Typography
                                            color={jobStatus < 8 ? theme.palette.secondary.dark : theme.palette.grey[500]}
                                            sx={{ fontSize: '12px' }}
                                        >
                                            {job.status?.name}
                                        </Typography> */}
                                        <FormattedMessage id={job.status.name} />
                                    </Box>
                                }
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextSection label={<FormattedMessage id="unusual-events" />} content={job.summary?.unusual_events} />
                        </Grid>
                        <Grid item xs={2} />
                    </Grid>
                    <Grid container sx={{ mt: 5 }}>
                        <div>
                            <H3 variant="h3" lineHeight="40px">
                                <FormattedMessage id="accepted-worker" />
                            </H3>
                        </div>
                        {status !== 0 ? (
                            <NotFound status={status} columnsLoaded={columns.length} />
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <Table rows={workers} columns={columns} />
                                </Grid>
                                {job?.summary?.worker ? (
                                    <>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{ display: 'flex', justifyContent: 'left', flexDirection: 'column', alignItems: 'end' }}
                                        >
                                            <Box width="100%">
                                                <Typography variant="subtitle1" mt={3} mb={1}>
                                                    <FormattedMessage id="comment" />
                                                </Typography>
                                                <TextField
                                                    multiline
                                                    rows={2}
                                                    fullWidth
                                                    value={workerComment}
                                                    onChange={(e) => setWorkerComment(e.target.value)}
                                                />
                                            </Box>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                                sx={{ fontSize: '12px', mt: 1 }}
                                                onClick={() => saveComment()}
                                            >
                                                <FormattedMessage id="save" />
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography mt={3} mb={1} ml={2} variant="subtitle1">
                                                <FormattedMessage id="reported-hours" />
                                            </Typography>
                                            <Grid container sx={{ mt: 1, ml: 2 }}>
                                                <Grid item xs={4}>
                                                    <EditTextSection
                                                        label={<FormattedMessage id="reported-start-hour" />}
                                                        content={startHour}
                                                        status={job.summary?.approved}
                                                        setHour={setStartHour}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <EditTextSection
                                                        label={<FormattedMessage id="reported-finish-hour" />}
                                                        content={finishHour}
                                                        status={job.summary?.approved}
                                                        setHour={setFinishHour}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={4}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <TextSection
                                                        label={<FormattedMessage id="approve status" />}
                                                        content={
                                                            job.summary?.approved ? (
                                                                <IconButton>
                                                                    <Check />
                                                                </IconButton>
                                                            ) : (
                                                                // <IconButton>
                                                                //     <Close />
                                                                // </IconButton>
                                                                <Text style={{ color: 'red', marginTop: 0 }}>
                                                                    <FormattedMessage id="not yet approved" />
                                                                </Text>
                                                            )
                                                        }
                                                    />
                                                    {job.summary?.approved ? (
                                                        []
                                                    ) : (
                                                        <Button
                                                            variant="contained"
                                                            size="medium"
                                                            color="primary"
                                                            sx={{
                                                                fontSize: '14px',
                                                                mt: 1,
                                                                ml: 5,
                                                                mr: 5,
                                                                whiteSpace: 'nowrap',
                                                                padding: '5px, 3px',
                                                                minWidth: '75px'
                                                            }}
                                                            onClick={() => handleApproveHours()}
                                                        >
                                                            <FormattedMessage id="approve hours" />
                                                        </Button>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </Grid>
                </>
            )}
            <RatingDialog setOpenDialog={setOpenRateDialog} openDialog={openRateDialog} saveRating={updateJobRating} />
            <GlobalDisableDialog
                open={openDisable}
                onClose={() => setOpenDisable(false)}
                onDisable={handleDisable}
                coloredText=""
                title1="Are you sure you want to disable"
                title2="this job?"
                subText="This job will no longer be available in the system"
            />
        </MainCard>
    );
};

export default JobDetails;
