import { useRef, useState, useEffect, useMemo } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { Box, Typography, TextField, Button, useTheme, Chip } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import Table from '../common/GlobalTable';
import { GridColDef, GridRenderCellParams, GridRenderEditCellParams, GridSortModel, useGridApiContext } from '@mui/x-data-grid';
import GlobalDrawer from 'views/common/GlobalDrawer';
import GlobalFilter from 'views/common/GlobalFilter';
import NotFound from 'views/common/NotFound';
import { PaginationInfo, RequestParams } from 'types/paginationInfo';
import { useNavigate, useParams } from 'react-router-dom';
import { approveMessage, declineMessage, getMessages, getMessageTypeList, saveEditedHours } from 'services/MessageService';
import { format, parseISO } from 'date-fns';
import { CheckBox, Clear } from '@mui/icons-material';
import { getDashboardColor } from 'utils/messages';

export interface rowsProps {
    id: string;
    customerName: string;
    category: string;
    startTime: string;
    entryTime: string;
    estimatedTime: string;
    relativeStatus: string;
    status: string;
}

const MessageDetails = () => {
    const [messages, setMessages] = useState<any[]>([]);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<number>(-1);
    const [status, setStatus] = useState<number>(1); // 0: Ready, 1:Loading, 2: Not found
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>();
    const [requestParams, setRequestParams] = useState<RequestParams>({
        page: 1,
        per_page: 50,
        sort: [{ field: 'created_at', sort: 'desc' }],
        filters: []
    });
    const { typeId } = useParams();
    const componentRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const [typeList, setTypeList] = useState<{ id: string; label: string }[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const theme = useTheme();
    const color = getDashboardColor(Number(typeId) || -1);
    const intl = useIntl();

    const handleApprove = (id: number) => {
        approveMessage(id);
        setMessages(messages.filter((item: any) => item.id !== id));
    };

    const handleDecline = (id: number) => {
        declineMessage(id);
        setMessages(messages.filter((item: any) => item.id !== id));
    };

    useEffect(() => {
        getMessageTypeList(setTypeList);
        if (typeId) {
            setRequestParams({ ...requestParams, filters: [{ name: 'message_type_id', value: typeId }] });

            setSelectedType(Number(typeId) || 0);
        }
    }, []);

    useEffect(() => {
        if (selectedType > 0) {
            setRequestParams({ ...requestParams, filters: [{ name: 'message_type_id', value: selectedType.toString() }] });
        }
    }, [selectedType]);

    useEffect(() => {
        if (selectedType >= 0) {
            setStatus(1);
            getMessages(setMessages, requestParams, setPaginationInfo);
        }
    }, [requestParams.filters, requestParams.sort]);

    const refresh = async () => {
        setStatus(1);
        getMessages(setMessages, requestParams, setPaginationInfo);
    };

    const onPageChange = (newPage: number) => {
        setRequestParams({ ...requestParams, page: newPage });
        setStatus(1);
        getMessages(setMessages, { ...requestParams, page: newPage }, setPaginationInfo);
    };

    const onPageSizeChange = (per_page: number) => {
        setRequestParams({ ...requestParams, per_page, page: 1 });
        setStatus(1);
        getMessages(setMessages, { ...requestParams, per_page, page: 1 }, setPaginationInfo);
    };

    const commonColumns: GridColDef[] = [
        {
            field: 'notification',
            renderHeader: (params) => (
                <Typography fontWeight={500} fontSize="12px">
                    <FormattedMessage id="notification" />
                </Typography>
            ),
            flex: 1,
            renderCell: (params) => (
                <Chip
                    size="small"
                    style={{
                        backgroundColor: color.dark,
                        color: color.light,
                        fontSize: '10px !important'
                    }}
                    label={intl.formatMessage({ id: params.row.content })}
                />
            ),
            sortable: paginationInfo?.sortable_fields?.includes('content')
        }
    ];

    // Start hours edit
    function renderStartHour(params: GridRenderCellParams<number>) {
        return <Typography>{params.value ? params.value : params.row.messageable.summary.reported_start_hour}</Typography>;
    }

    function StartHourEditInput(props: GridRenderEditCellParams) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            apiRef.current.setEditCellValue({ id, field, value: newValue });
        };

        return <input type="time" value={value} onChange={handleValueChange} />;
    }

    const renderStartHourEditInput: GridColDef['renderCell'] = (params) => <StartHourEditInput {...params} />;

    // Finish hours edit
    function renderFinishHour(params: GridRenderCellParams<number>) {
        return <Typography>{params.value ? params.value : params.row.messageable.summary.reported_finish_hour}</Typography>;
    }

    function FinishHourEditInput(props: GridRenderEditCellParams) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            apiRef.current.setEditCellValue({ id, field, value: newValue });
        };

        return <input type="time" value={value} onChange={handleValueChange} />;
    }

    const renderFinishHourEditInput: GridColDef['renderCell'] = (params) => <FinishHourEditInput {...params} />;

    // Save new hours and set approved flag
    function handleApproveHours(row: any) {
        const summary_id = row.messageable.summary.id;
        const start_hour = row.start_hour ? row.start_hour : row.messageable.summary.reported_start_hour;
        const finish_hour = row.finish_hour ? row.finish_hour : row.messageable.summary.reported_finish_hour;
        console.log('row: ', row);

        // saveEditedHours(summary_id, start_hour, finish_hour);
        // handleApprove(row.id);
    }

    useEffect(() => {
        setColumns(commonColumns);
        // --- SPECIFIC COLUMNS ACCORDING MESSAGE TYPES -----
        // WORKER APPROVAL
        if (selectedType === 1) {
            setColumns([
                ...commonColumns,
                {
                    field: 'message_action',
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="message-action" />
                        </Typography>
                    ),
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => (
                        <div>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    // Is descarted from dashboard once is clicked the link
                                    handleApprove(params.row?.id);
                                    navigate('/worker-approvals');
                                }}
                                size="small"
                            >
                                <FormattedMessage id="go-to-approvals" />
                            </Button>
                        </div>
                    )
                }
            ]);
        }
        // CANDIDATE APPROVAL
        else if (selectedType === 2) {
            setColumns([
                ...commonColumns,
                {
                    field: 'message_action',
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="message-action" />
                        </Typography>
                    ),
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => (
                        <div>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    // Is descarted from dashboard once is clicked the link
                                    handleApprove(params.row?.id);
                                    navigate('/jobs/candidates-list');
                                }}
                                size="small"
                            >
                                <FormattedMessage id="go-to-candidates" />
                            </Button>
                        </div>
                    )
                }
            ]);
        }
        // WORKER NOT STARTING WORK DAY
        else if (selectedType === 3) {
            setColumns([
                ...commonColumns,
                {
                    field: 'worker_name',
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="worker-name" />
                        </Typography>
                    ),
                    flex: 1,
                    valueGetter: (params) =>
                        params.row.messageable?.summary ? params.row.messageable?.summary.worker.user.full_name : ' -',
                    sortable: paginationInfo?.sortable_fields?.includes('worker_name')
                },
                {
                    field: 'worker_phone',
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="worker-phone-number" />
                        </Typography>
                    ),
                    flex: 1,
                    valueGetter: (params) =>
                        params.row.messageable?.summary ? params.row.messageable?.summary.worker.user.phone_number : ' -',
                    sortable: paginationInfo?.sortable_fields?.includes('worker_name')
                },
                {
                    field: 'job_date',
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="job-supposed-start" />
                        </Typography>
                    ),
                    flex: 1,
                    renderCell: (params) => (
                        <Box>
                            <Typography>
                                <FormattedMessage id="job-date" />: &nbsp;
                                {params.row.messageable ? format(new Date(params.row.messageable.job_date || ''), 'dd.MM.yyyy') : '-'}
                            </Typography>
                            <Typography>
                                <FormattedMessage id="working-hour-time" />: &nbsp;
                                {params.row.messageable?.workplace ? params.row.messageable.workplace.working_hour.starting_hour : '-'}
                            </Typography>
                        </Box>
                    ),
                    sortable: paginationInfo?.sortable_fields?.includes('worker_name')
                },
                {
                    field: 'action',
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="confirm-cancel" />
                        </Typography>
                    ),
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => (
                        <div>
                            <Button variant="text" onClick={() => handleApprove(params.row?.id)}>
                                <CheckBox style={{ color: '#2B9788' }} />
                            </Button>
                            <Button variant="text" onClick={() => handleDecline(params.row?.id)}>
                                <Clear style={{ color: '#2B9788' }} />
                            </Button>
                        </div>
                    )
                }
            ]);
        }
        // WORKER NOT FINISHED WORK DAY
        else if (selectedType === 4) {
            setColumns([
                ...commonColumns,
                {
                    field: 'worker_name',
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="worker-name" />
                        </Typography>
                    ),
                    flex: 1,
                    valueGetter: (params) =>
                        params.row.messageable?.summary ? params.row.messageable?.summary.worker.user.full_name : ' -',
                    sortable: paginationInfo?.sortable_fields?.includes('worker_name')
                },
                {
                    field: 'worker_phone',
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="worker-phone-number" />
                        </Typography>
                    ),
                    flex: 1,
                    valueGetter: (params) =>
                        params.row.messageable?.summary ? params.row.messageable?.summary.worker.user.phone_number : ' -',
                    sortable: paginationInfo?.sortable_fields?.includes('worker_name')
                },
                {
                    field: 'job_date',
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="job-supposed-end" />
                        </Typography>
                    ),
                    flex: 1,
                    renderCell: (params) => (
                        <Box>
                            <Typography>
                                <FormattedMessage id="job-date" />: &nbsp;
                                {params.row.messageable ? format(new Date(params.row.messageable.job_date || ''), 'dd.MM.yyyy') : '-'}
                            </Typography>
                            <Typography>
                                <FormattedMessage id="working-hour-time" />: &nbsp;
                                {params.row.messageable?.workplace ? params.row.messageable.workplace.working_hour.finishing_hour : '-'}
                            </Typography>
                        </Box>
                    ),
                    sortable: paginationInfo?.sortable_fields?.includes('worker_name')
                },
                {
                    field: 'action',
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="confirm-cancel" />
                        </Typography>
                    ),
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => (
                        <div>
                            <Button variant="text" onClick={() => handleApprove(params.row?.id)}>
                                <CheckBox style={{ color: '#2B9788' }} />
                            </Button>
                            <Button variant="text" onClick={() => handleDecline(params.row?.id)}>
                                <Clear style={{ color: '#2B9788' }} />
                            </Button>
                        </div>
                    )
                }
            ]);
        }
        // WORKER NOT FINISHED WORK DAY
        else if (selectedType === 6) {
            setColumns([
                ...commonColumns,
                {
                    field: 'date',
                    flex: 1,
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="date" />
                        </Typography>
                    ),
                    sortable: false,
                    renderCell: (params) => <Typography>{params.row.messageable.job_date}</Typography>
                },
                {
                    field: 'wokplace-name',
                    renderHeader: (params) => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="workplace-name" />
                        </Typography>
                    ),
                    flex: 1,
                    renderCell: (params) => <Typography>{params.row.messageable.workplace.name}</Typography>,
                    sortable: false
                },
                {
                    field: 'time',
                    renderHeader: (params) => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="time" />
                        </Typography>
                    ),
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => <Typography>{params.row.messageable.workplace.working_hour.hour_interval}</Typography>
                }
            ]);
            // UNAPPROVED WORKING HOURS
        } else if (selectedType === 9) {
            setColumns([
                {
                    field: 'worker_name',
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="worker-name" />
                        </Typography>
                    ),
                    flex: 1,
                    valueGetter: (params) =>
                        params.row.messageable?.summary ? params.row.messageable?.summary.worker.user.full_name : ' -',
                    sortable: paginationInfo?.sortable_fields?.includes('worker_name')
                },
                {
                    field: 'wokplace-name',
                    renderHeader: (params) => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="workplace-name" />
                        </Typography>
                    ),
                    flex: 1,
                    renderCell: (params) => <Typography>{params.row.messageable.workplace.name}</Typography>,
                    sortable: false
                },
                {
                    field: 'date',
                    flex: 1,
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="date" />
                        </Typography>
                    ),
                    sortable: paginationInfo?.sortable_fields?.includes('job_date'),
                    renderCell: (params) => <Typography>{params.row.messageable.job_date}</Typography>
                },
                {
                    field: 'start_hour',
                    flex: 1,
                    // editable: true,
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="start-hour" />
                        </Typography>
                    ),
                    sortable: false,
                    renderCell: (params) => <Typography>{params.row.messageable.summary.reported_start_hour}</Typography>
                    // renderCell: renderStartHour
                    // renderEditCell: renderStartHourEditInput
                },
                {
                    field: 'finish_hour',
                    flex: 1,
                    // editable: true,
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="finish-hour" />
                        </Typography>
                    ),
                    sortable: false,
                    renderCell: (params) => <Typography>{params.row.messageable.summary.reported_finish_hour}</Typography>
                    // renderCell: renderFinishHour
                    // renderEditCell: renderFinishHourEditInput
                },
                // {
                //     field: 'action',
                //     renderHeader: () => (
                //         <Typography fontWeight={500}>
                //             <FormattedMessage id="approve" />
                //         </Typography>
                //     ),
                //     flex: 1,
                //     sortable: false,
                //     renderCell: (params) => (
                //         <div>
                //             <Button variant="text" onClick={() => handleApproveHours(params.row)}>
                //                 <FormattedMessage id="approve" />
                //             </Button>
                //         </div>
                //     )
                // },
                {
                    field: 'details',
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="more details" />
                        </Typography>
                    ),
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => (
                        <div>
                            <Button variant="text" onClick={() => navigate(`/jobs/${params.row.messageable.id}/${params.row.id}`)}>
                                <FormattedMessage id="more details" />
                            </Button>
                        </div>
                    )
                }
            ]);
        } else {
            setColumns([
                ...commonColumns,
                {
                    field: 'worker_name',
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="worker-name" />
                        </Typography>
                    ),
                    flex: 1,
                    valueGetter: (params) =>
                        params.row.messageable?.summary ? params.row.messageable?.summary.worker.user.full_name : ' -',
                    sortable: paginationInfo?.sortable_fields?.includes('worker_name')
                },
                {
                    field: 'phone_number',
                    renderHeader: () => (
                        <Typography fontWeight={500}>
                            <FormattedMessage id="phone-number" />
                        </Typography>
                    ),
                    flex: 1,
                    valueGetter: (params) =>
                        params.row.messageable?.summary ? params.row.messageable?.summary.worker.user.phone_number : ' -',
                    sortable: paginationInfo?.sortable_fields?.includes('phone_number')
                }
            ]);
        }
        if (messages.length > 0) {
            setStatus(0);
        }
        if (messages.length === 0 && paginationInfo) {
            setStatus(2);
        }
    }, [messages, paginationInfo]);

    const onSortModelChange = (model: GridSortModel) => {
        setRequestParams({ ...requestParams, sort: model });
    };

    return (
        <MainCard>
            <Box bgcolor={getDashboardColor(selectedType).light} maxWidth="400px" p={1} borderRadius={2} display="inline-block" mb={2}>
                <Typography color={getDashboardColor(selectedType).dark}>
                    <FormattedMessage
                        id={typeList.find((t) => t.id === typeId)?.label ? typeList.find((t) => t.id === typeId)?.label : 'null'}
                    />
                </Typography>
            </Box>

            {status !== 0 ? (
                <NotFound status={status} tryAgain={refresh} columnsLoaded={columns.length} />
            ) : (
                <>
                    <div ref={componentRef}>
                        <Table
                            // onCellClick={(params) => {
                            //     if (params.field !== 'start_hour' && params.field !== 'finish_hour')
                            //         navigate(`/jobs/${params.row.messageable.id}`);
                            // }}
                            sortModel={requestParams.sort}
                            onSortModelChange={onSortModelChange}
                            onPageSizeChange={onPageSizeChange}
                            onPageChange={onPageChange}
                            paginationInfo={paginationInfo}
                            page={requestParams.page}
                            columns={columns}
                            rows={messages}
                        />
                    </div>
                    <GlobalDrawer
                        width="320px"
                        padding="16px"
                        open={openFilter}
                        onClose={() => setOpenFilter(false)}
                        Component={
                            <GlobalFilter
                                availabeFilters={paginationInfo?.available_filters}
                                filters={requestParams.filters}
                                setFilters={(filters) => {
                                    if (requestParams.filters) setRequestParams({ ...requestParams, filters });
                                }}
                                onClose={() => setOpenFilter(false)}
                            />
                        }
                    />
                </>
            )}
        </MainCard>
    );
};

export default MessageDetails;
