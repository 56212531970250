import { Box, Button, Dialog, DialogContent, DialogTitle, Rating, styled, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { round } from 'lodash';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

export interface ratingDialogProps {
    saveRating: (value: number) => void;
    openDialog: boolean;
    setOpenDialog: (value: boolean) => void;
}

const RatingDialog = (props: ratingDialogProps) => {
    const { openDialog, saveRating, setOpenDialog } = props;
    const theme = useTheme();

    const [ratings, setRatings] = useState<{ question: string; rating: number }[]>([
        {
            question: 'עמידה בלוחות זמנים',
            rating: 0
        },
        {
            question: 'הקפדה על נהלי הגן',
            rating: 0
        },
        {
            question: 'ביצוע המטלות',
            rating: 0
        },
        {
            question: 'שיתוף פעולה עם צוות הגן',
            rating: 0
        },
        {
            question: 'תקשורת עם ילדי הגן',
            rating: 0
        }
    ]);

    const getAverageRating = () => {
        let sum = 0;
        ratings.forEach((r) => {
            sum += r.rating || 0;
        });
        return round(sum / ratings.length, 1);
    };

    return (
        <Dialog
            PaperProps={{ style: { padding: '30px', minWidth: '30vw' } }}
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            maxWidth="lg"
        >
            <DialogTitle sx={{ m: 0, p: 0 }}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="subtitle1">
                        <FormattedMessage id="rating" />
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ padding: 0 }}>
                <Box>
                    {ratings.map((r, key) => (
                        <Box mt={3}>
                            <Typography>{r.question}</Typography>
                            <Rating
                                sx={{
                                    '& .MuiRating-iconFilled, .MuiRating-iconEmpty': {
                                        color: '#36BDAA'
                                    }
                                }}
                                value={r.rating}
                                onChange={(event, newValue: any) => {
                                    const newRatings = [...ratings];
                                    newRatings[key] = { ...newRatings[key], rating: newValue };
                                    setRatings(newRatings);
                                }}
                                size="small"
                            />
                        </Box>
                    ))}
                    <Box sx={{ mt: 3 }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => saveRating(getAverageRating())}
                            sx={{ color: theme.palette.common.white }}
                        >
                            <FormattedMessage id="save" />
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default RatingDialog;
