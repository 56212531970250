// project import
import systemManagement from './management';
import systemManagementSettings from './managementSettings';

// types
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] }[] = [
    {
        items: [systemManagement]
    },
    {
        items: [systemManagementSettings]
    }
];
export default menuItems;
