import { lazy } from 'react';

// project imports
// import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import DataCollecting from '../views/pages/landings/DataCollecting';

// login routing;
const PersonalAreaLogin = Loadable(lazy(() => import('views/pages/auth/PersonalAreaLogin')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginPersonalAreaRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <MinimalLayout />
        </NavMotion>
    ),
    children: [
        {
            path: '/login-personal-area',
            element: <PersonalAreaLogin />
        }
    ]
};

export default LoginPersonalAreaRoutes;
