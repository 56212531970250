import { useState, useRef, useEffect, useMemo } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import Table from '../common/GlobalTable';
import SubSearchSection from '../common/SubSearchSection';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Rating, styled, Typography } from '@mui/material';
import { PaginationInfo, RequestParams } from 'types/paginationInfo';
import NotFound from 'views/common/NotFound';
import { debounce } from 'lodash';
import { format } from 'date-fns';
import { getJobList } from 'services/JobService';
import GlobalDrawer from 'views/common/GlobalDrawer';
import ApproveJob from './ApproveJob';
import { PeopleAltOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import JobDetailExpansion from './JobDetailExpansion';

const JobsList = () => {
    const [jobs, setJobs] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<string>();
    const componentRef = useRef<HTMLDivElement>(null);
    const [status, setStatus] = useState<number>(1); // 0: Ready, 1:Loading, 2: Not found
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>();
    const [requestParams, setRequestParams] = useState<RequestParams>({
        page: 1,
        per_page: 50,
        sort: [{ field: 'status_updated_at', sort: 'desc' }],
        filters: []
    });
    const [currentItem, setCurrentItem] = useState<string>('');
    const [openApproveJob, setOpenApproveJob] = useState<boolean>(false);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const navigate = useNavigate();
    const [clickedIndex, setClickedIndex] = useState<number>(-1);

    const Text = styled('p')({
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center'
    });

    const goToJobDetails = (params: any) => {
        navigate(`/jobs/${params.row.id}`);
    };

    const getStatus = (params: any) => {
        console.log('params.accepted: ', params.accepted);
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ color: '#fffff' }}> {params.accepted} </div>
            </div>
        );
    };
    const getPotential = (params: any) => {
        console.log('params.no_response: ', params);
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ color: '#fffff' }}> {params} </div>
            </div>
        );
    };

    const getRejected = (cellValues: any) => {
        console.log('cellValues', cellValues);
        return <div style={{ display: 'flex' }}>{cellValues.row.rejected_workers}</div>;
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="name" />
                </Text>
            ),
            renderCell: (cellValues) => (
                <Box>
                    <Box height="30px">
                        <Typography>{cellValues.value}</Typography>
                    </Box>
                    <JobDetailExpansion
                        cellId={cellValues.id}
                        clickedIndex={clickedIndex}
                        setClickedIndex={setClickedIndex}
                        row={cellValues.row}
                    />
                </Box>
            )
        },
        {
            field: 'status',
            flex: 1,
            sortable: true,
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="status" />
                </Text>
            ),
            renderCell: (cellValues) => (
                <Box>
                    <FormattedMessage id={cellValues.value} />
                </Box>
            )
        },
        {
            field: 'status_updated_at',
            flex: 1,
            sortable: true,
            valueFormatter: (params) => (params.value ? format(new Date(params.value as string), 'dd.MM.yyyy HH:mm') : '-'),
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="status_updated_at" />
                </Text>
            )
        },
        {
            field: 'job_date',
            flex: 1,
            sortable: true,
            valueFormatter: (params) => (params.value ? format(new Date(params.value as string), 'dd.MM.yyyy') : '-'),
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="job-offer-date" />
                </Text>
            )
        },
        {
            field: 'candidates',
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="approved_job" />
                </Text>
            ),
            renderCell: (params) => <div>{getStatus(params.value)}</div>
        },
        {
            field: 'waiting_apply_workers',
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="potential" />
                </Text>
            ),
            renderCell: (params) => <div>{getPotential(params.value)}</div>
        },
        {
            field: 'rejected_workers',
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="rejected_workers" />
                </Text>
            ),
            renderCell: (cellValues) => <div>{getRejected(cellValues)}</div>
        },
        // {
        //     field: 'rating',
        //     flex: 1,
        //     sortable: false,
        //     renderHeader: () => (
        //         <Text>
        //             <FormattedMessage id="rating" />
        //         </Text>
        //     ),
        //     renderCell: (params) => (
        //         <Rating
        //             sx={{
        //                 '& .MuiRating-iconFilled, .MuiRating-iconEmpty': {
        //                     color: '#36BDAA'
        //                 }
        //             }}
        //             value={params.value}
        //             readOnly
        //             size="small"
        //         />
        //     )
        // },
        {
            field: 'action',
            headerName: 'Jobs Details',
            renderHeader: () => (
                <Text>
                    <FormattedMessage id="job-details" />
                </Text>
            ),
            flex: 0.8,
            sortable: false,
            renderCell: (params) => (
                <Button variant="contained" size="small" style={{ backgroundColor: '#36BDAA' }} onClick={() => goToJobDetails(params)}>
                    <FormattedMessage id="details-page" />
                </Button>
            )
        }
    ];

    const refresh = async () => {
        setStatus(1);
        setRequestParams((r) => ({ ...r, filters: [] }));
    };

    useEffect(() => {
        if (searchText !== undefined) {
            setRequestParams((r) => ({
                ...r,
                filters: [...(r.filters?.filter((f) => f.name !== 'id') || []), { name: 'id', value: searchText }]
            }));
        }
    }, [searchText]);

    useEffect(() => {
        if (jobs.length > 0 && paginationInfo) {
            setStatus(0);
        }
        if (jobs.length === 0 && paginationInfo) {
            setStatus(2);
        }
    }, [jobs, paginationInfo]);

    useEffect(() => {
        setStatus(1);
        getJobList(setJobs, requestParams, setPaginationInfo);
    }, [requestParams]);

    const onPageChange = (newPage: number) => {
        setRequestParams({ ...requestParams, page: newPage });
        setStatus(1);
        getJobList(setJobs, { ...requestParams, page: newPage }, setPaginationInfo);
    };

    const onPageSizeChange = (per_page: number) => {
        setRequestParams({ ...requestParams, per_page, page: 1 });
        setStatus(1);
        getJobList(setJobs, { ...requestParams, per_page, page: 1 }, setPaginationInfo);
    };
    const handleNavigateNewJob = async () => {
        // navigate to /jobs/add
        navigate(`/jobs/add`);
    };

    const debouncedSearchTextChange = useMemo(() => debounce(setSearchText, 300), []);

    const onSortModelChange = (model: GridSortModel) => {
        setRequestParams({ ...requestParams, sort: model });
    };
    return (
        <>
            <GlobalDrawer
                open={openApproveJob}
                onClose={() => setOpenApproveJob(false)}
                Component={
                    <ApproveJob label="job-offers" refreshData={refresh} data={currentItem} onClose={() => setOpenApproveJob(false)} />
                }
            />
            <MainCard sx={{ minHeight: 'calc(100vh - 240px)' }}>
                <SubSearchSection
                    filters={requestParams.filters}
                    setFilters={(filters) => setRequestParams({ ...requestParams, filters })}
                    filter={openFilter}
                    setFilter={setOpenFilter}
                    availableFilters={paginationInfo?.available_filters}
                    setSearchText={debouncedSearchTextChange}
                    printRef={componentRef}
                    label="job-list"
                    columnCount={columns.length - 1} // Print layout needed
                    setAdd={(value: boolean) => handleNavigateNewJob()}
                />
                {status !== 0 ? (
                    <NotFound status={status} columnsLoaded={columns.length} tryAgain={refresh} />
                ) : (
                    <>
                        <div ref={componentRef}>
                            <Table
                                sortModel={requestParams.sort}
                                onSortModelChange={onSortModelChange}
                                onPageSizeChange={onPageSizeChange}
                                page={requestParams.page}
                                paginationInfo={paginationInfo}
                                onPageChange={onPageChange}
                                rows={jobs}
                                columns={columns}
                                onCellClick={(params) => {
                                    if (clickedIndex !== params.id) setClickedIndex(params.id);
                                }}
                                expandedId={clickedIndex}
                            />
                        </div>
                    </>
                )}
            </MainCard>
        </>
    );
};

export default JobsList;
