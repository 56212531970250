import axios from 'axios';
import { WorkplaceContact } from 'types/workplace';

export const getUsersList = async (setUsers: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get('/users/list');
        const userData: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.users)) {
            userData.push({ id: key, label: value as string });
        }
        setUsers(userData);
    } catch (err) {
        console.error(`error in users map -key-value-  ${err}`);
    }
};

export const getWorkplaceTypeList = async (setWorkplaceType: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get('/workplace-types/list');
        const userData: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.workplace_types)) {
            userData.push({ id: key, label: value as string });
        }
        setWorkplaceType(userData);
    } catch (err) {
        console.error(`error in WorkplaceType map -key-value-  ${err}`);
    }
};

export const getWorkplacesList = async (setWorkplace: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get('/workplaces/list');
        const userData: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.workplace_s)) {
            userData.push({ id: key, label: value as string });
        }
        setWorkplace(userData);
    } catch (err) {
        console.error(`error in Workplace map -key-value-  ${err}`);
    }
};

export const getWorkplacesByAuthority = async (setWorkplaces: (value: { id: string; label: string }[]) => void, authority_id: number) => {
    try {
        const response = await axios.get(`/workplaces/list/by-authority/${authority_id}`);
        const userData: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.workplaces)) {
            userData.push({ id: key, label: value as string });
        }
        setWorkplaces(userData);
    } catch (err) {
        console.error(`error Getting workplaces list ${err}`);
    }
};

export const getWorkplaceContacts = async (setContacts: (value: WorkplaceContact[]) => void, id: number) => {
    try {
        const response = await axios.get(`/workplaces/${id}/contacts`);
        setContacts(response.data.workplace_contacts);
    } catch (err) {
        console.error(`error Getting workplace contacts ${err}`);
    }
};

export const getRolesList = async (setRoles: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get('/users/roles/list');
        const rolesData: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.roles)) {
            rolesData.push({ id: key, label: value as string });
        }
        setRoles(rolesData);
    } catch (err) {
        console.error(`error in roles list -key-value-  ${err}`);
    }
};

export const getPermissionsList = async (setPermissions: (value: { id: number; name: string; icon: string }[]) => void) => {
    try {
        const response = await axios.get('/module-permissions/list');
        setPermissions(response.data.module_permissions);
    } catch (err) {
        console.error(`Error getting permissions list ${err}`);
    }
};

export const getWorkerTypesList = async (setWorkerTypes: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get('/worker-types/list');
        const workerTypes: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.worker_types)) {
            workerTypes.push({ id: key, label: value as string });
        }
        setWorkerTypes(workerTypes);
    } catch (err) {
        console.error(`Error getting Worker Types List ${err}`);
    }
};

export const getWorkerFiles = async (
    userId: any,
    setWorkerFiles: (value: { id: Number; file_path: string; file_name: string }[]) => void
) => {
    try {
        const params = { user_id: userId };
        const response = await axios.get('/worker/files', { params });
        setWorkerFiles(response.data.files);
    } catch (error) {
        console.error(`Error getting Worker Files List ${error}`);
    }
};

export const getRegionsList = async (setRegions: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get('/authorities/regions/list');
        const regions: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.regions)) {
            regions.push({ id: key, label: value as string });
        }
        setRegions(regions);
    } catch (err) {
        console.error(`Error getting regions List ${err}`);
    }
};

export const getWorkplaceTypesList = async (setWorkplaceTypes: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get('/workplace-types/list');
        const workplaceTypes: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.workplace_types)) {
            workplaceTypes.push({ id: key, label: value as string });
        }
        setWorkplaceTypes(workplaceTypes);
    } catch (err) {
        console.error(`Error getting Workplace Types List ${err}`);
    }
};

export const getAuthoritiesList = async (setAuthorities: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get('/authorities/list');
        console.log(response);
        const authoritiesData: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.authorities)) {
            authoritiesData.push({ id: key, label: value as string });
        }
        setAuthorities(authoritiesData);
    } catch (err) {
        console.error(`Error getting authorities list ${err}`);
    }
};

export const getSpecificHoursList = async (authId: Number, setWorkingHours: (value: { id: string; label: string }[]) => void) => {
    try {
        let response = await axios.get(`/specific-hours/${authId}`);
        if (response.data.authority_hours) {
            const workingHoursData: { id: string; label: string }[] = [];
            for (const [key, value] of Object.entries(response.data.authority_hours)) {
                workingHoursData.push({ id: key, label: value as string });
            }
            setWorkingHours(workingHoursData);
        } else {
            response = await axios.get('/workplaces/working-hours/list');
            const workingHoursData: { id: string; label: string }[] = [];
            for (const [key, value] of Object.entries(response.data.working_hours)) {
                workingHoursData.push({ id: key, label: value as string });
            }
            setWorkingHours(workingHoursData);
        }
    } catch (err) {
        console.error(`Error getting specific authority hours list ${err}`);
    }
};

export const getWorkingHoursDataList = async (setWorkingHours: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get('/workplaces/working-hours/list');
        const workingHoursData: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.working_hours)) {
            workingHoursData.push({ id: key, label: value as string });
        }
        setWorkingHours(workingHoursData);
    } catch (err) {
        console.error(`Error getting hours list ${err}`);
    }
};

export const getWorkedHours = async (
    setWorkedHours: (value: { amount_hours_bought: Number; amount_worked_hours: Number }) => void,
    authId?: Number
) => {
    try {
        const params: any = {
            authority_id: authId
        };
        const response = await axios.get('/authorities/worked-hours', { params });
        setWorkedHours(response.data);
    } catch (error) {
        console.error(`Error getting worked hours ${error}`);
    }
};

export const getAgeRangesList = async (setAgeRanges: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get('/workplaces/age-ranges/list');
        const ageRangesData: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.age_ranges)) {
            ageRangesData.push({ id: key, label: value as string });
        }
        setAgeRanges(ageRangesData);
    } catch (err) {
        console.error(`Error getting hours list ${err}`);
    }
};

export const getWorkingHourList = async (setWorkingHours: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get('/workplaces/working-hours/list');
        const workingHours: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.working_hours)) {
            workingHours.push({ id: key, label: value as string });
        }
        setWorkingHours(workingHours);
    } catch (err) {
        console.error(`Error getting working hours List ${err}`);
    }
};

export const getAuthorityWorkingHourList = async (setWorkingHours: (value: { id: string; label: string }[]) => void) => {
    try {
        const allWorkingHours: any = {
            morningStart: [],
            morningFinish: [],
            afternoonStart: [],
            afternoonFinish: []
        };

        const response1 = await axios.get('/authority-hours/morning/start');
        for (const item of response1.data.hours) {
            allWorkingHours.morningStart.push({ id: item.id, label: item.hour as string });
        }

        const response2 = await axios.get('/authority-hours/morning/finish');
        for (const item of response2.data.hours) {
            allWorkingHours.morningFinish.push({ id: item.id, label: item.hour as string });
        }
        const response3 = await axios.get('/authority-hours/afternoon/start');
        for (const item of response3.data.hours) {
            allWorkingHours.afternoonStart.push({ id: item.id, label: item.hour as string });
        }
        const response4 = await axios.get('/authority-hours/afternoon/finish');
        for (const item of response4.data.hours) {
            allWorkingHours.afternoonFinish.push({ id: item.id, label: item.hour as string });
        }

        setWorkingHours(allWorkingHours);
    } catch (err) {
        console.error(`Error getting working hours List ${err}`);
    }
};

export const getAgeRangeList = async (setAgeRanges: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get('/workplaces/age-ranges/list');
        const ageRanges: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.age_ranges)) {
            ageRanges.push({ id: key, label: value as string });
        }
        setAgeRanges(ageRanges);
    } catch (err) {
        console.error(`Error getting working hours List ${err}`);
    }
};

export const getRegionAuthoritiesList = async (
    setRegionList: (regions: any[]) => void,
    setAuthoritiesList: (authorities: { id: number; name: string; region_id: number; languages: [] }[]) => void
) => {
    try {
        const response = await axios.get(`/workers/data-completion/authorities/list`);
        const result = response.data.regions;
        const regionsList = result.map((region: { id: number; name: string }) => ({
            id: region.id,
            name: region.name
        }));
        const authoritiesList = result
            .map((region: { id: number; authorities: { id: number; name: string; languages: [] }[] }) =>
                region.authorities.map((authority: { id: number; name: string; languages: [] }) => ({
                    id: authority.id,
                    name: authority.name,
                    region_id: region.id,
                    languages: authority.languages
                }))
            )
            .flat(4);
        setRegionList(regionsList);
        setAuthoritiesList(authoritiesList);
    } catch (err) {
        console.error(`error in roles list -key-value-  ${err}`);
    }
};

const getWorkerStringIDList = async (
    listURLName: string,
    listName: string,
    callbackFn: (callbackType: { id: number; name: string }[]) => void
) => {
    try {
        const response = await axios.get(`/workers/data-completion/${listURLName}/list`);
        const result = response.data[listName];
        const resultList = result.map((ageRangeItem: { id: number; name: string }) => ({
            id: ageRangeItem.id,
            name: ageRangeItem.name
        }));

        callbackFn(resultList);
    } catch (err) {
        console.error(`error in roles list -key-value-  ${err}`);
    }
};

export const getAgeRangeLPList = async (setRegionList: (regions: { id: number; name: string }[]) => void) => {
    getWorkerStringIDList('age-ranges', 'age_ranges', setRegionList);
};

export const getWorkplaceTypeLPList = async (setWorkplaceTypes: (workplaceTypes: { id: number; name: string }[]) => void) => {
    getWorkerStringIDList('workplace-types', 'workplace_types', setWorkplaceTypes);
};

export const getWorkingHoursList = async (
    setWorkingHours: (workingHours: { day: number; name: string; hours: { id: number; name: string }[] }[]) => void
) => {
    try {
        const response = await axios.get(`/workers/data-completion/working-hours/list`);
        const result = response.data.working_hours;

        setWorkingHours(result);
    } catch (err) {
        console.error(`error in roles list -key-value-  ${err}`);
    }
};

export const getOccupationsList = async (setOccupations: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get(`/workers/data-completion/occupation-list`);
        const occupationsData: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.occupations)) {
            occupationsData.push({ id: key, label: value as string });
        }
        setOccupations(occupationsData);
    } catch (err) {
        console.error(`Error getting occupations list ${err}`);
    }
};

export const getStatusesList = async (setStatuses: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get('/workers/statuses/list');
        const statusesData: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.statuses)) {
            statusesData.push({ id: key, label: value as string });
        }
        setStatuses(statusesData);
    } catch (err) {
        console.error(`Error getting statuses list ${err}`);
    }
};

export const getLanguagesList = async (setLanguages: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get('/authorities/languages/list');
        const languages: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.languages)) {
            languages.push({ id: key, label: value as string });
        }
        setLanguages(languages);
    } catch (err) {
        console.error(`Error getting languages List ${err}`);
    }
};

export const getLanguagesListForCompletion = async (setLanguages: (workplaceTypes: { id: number; name: string }[]) => void) => {
    getWorkerStringIDList('languages', 'languages', setLanguages);
};

export const getLatestDeclaration = async (setDeclaration: (declaration: { id: number; name: string; content: string }) => void) => {
    const response = await axios.get(`/workers/data-completion/declaration`);
    const result = response.data.declaration;

    setDeclaration(result);
};

export const getUserGroupList = async (setUserGroups: (value: { id: string; label: string }[]) => void) => {
    try {
        const response = await axios.get('/user_groups/list');
        const groups: { id: string; label: string }[] = [];
        for (const [key, value] of Object.entries(response.data.user_groups)) {
            groups.push({ id: key, label: value as string });
        }
        setUserGroups(groups);
    } catch (err) {
        console.error(`Error getting user groups List ${err}`);
    }
};

export const getAproochSystemOptions = async (
    setOptions: (value: { id: string; name: string }[]) => void,
    setSocials: (value: { id: string; name: string }[]) => void
) => {
    try {
        const response = await axios.get(`/workers/data-completion/aprooch-options-list`);
        const data: { id: string; name: string }[] = [];
        for (const [key, value] of Object.entries(response.data.aprooch_options)) {
            data.push({ id: key, name: value as string });
        }
        setOptions(data);

        const socials: { id: string; name: string }[] = [];
        for (const [key, value] of Object.entries(response.data.social_options)) {
            socials.push({ id: key, name: value as string });
        }
        setSocials(socials);
    } catch (err) {
        console.error(`Error getting aprooch system options list ${err}`);
    }
};

export default getUsersList;
