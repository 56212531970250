import { ArrowBack, CheckBox, PictureAsPdfTwoTone } from '@mui/icons-material';
import { Avatar, Box, Button, Divider, Grid, IconButton, Rating, TextField, Typography } from '@mui/material';
import React, { createRef, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import {
    addWorkerComment,
    disableWorker,
    getWorkerComments,
    getWorkerDetails,
    openFile,
    updateWorkerBackupStatus,
    updateWorkerStatus
} from 'services/WorkerService';
import { Comment, Worker, WorkerAvailability } from 'types/worker';
import MainCard from 'ui-component/cards/MainCard';
import TextSection from 'views/common/TextSection';
import Action from 'views/common/GlobalAction';
import { useTheme } from '@mui/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';
import SubCard from 'ui-component/cards/SubCard';
import GlobalTable from 'views/common/GlobalTable';
import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import DisableWithReasonDialog from 'views/common/GlobalDisableReasonDialog';
import WorkerHistory from './WorkerHistory';
import WorkerFile from './WorkerFile';
import ApprovalStepper from './ApprovalStepper';
import { getStatusesList } from 'services/ListService';
import GlobalDisableDialog from 'views/common/GlobalDisableDialog';
import { toIntl } from 'utils/helpers';
import WorkerEdit from './WorkerEdit';

export interface WorkerDetailsProps {
    workerIdProp?: string;
}
const WorkerDetails = (props: WorkerDetailsProps) => {
    const { workerIdProp } = props;
    const { workerId } = useParams();
    const id = workerIdProp || workerId;
    const [worker, setWorker] = useState<Worker>();
    const [comments, setComments] = useState<Comment[]>([]);
    const [openDisable, setOpenDisable] = useState<boolean>(false);
    const [statuses, setStatuses] = useState<{ id: string; label: string }[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<{ id: string; label: string }>();
    const [openConfirmStatusChange, setOpenConfirmStatusChange] = useState<boolean>(false);
    const [xs, setXs] = useState<boolean>(false);

    const theme = useTheme();
    const commentRef = createRef<HTMLInputElement>();
    const gendersMap: { [key: number]: string } = { 1: 'male', 2: 'female', 3: 'other' };

    const navigate = useNavigate();
    const intl = useIntl();

    useEffect(() => {
        if (id) {
            getWorkerDetails(id, setWorker);
            getWorkerComments(id, setComments);
            getStatusesList(setStatuses);
        }
        if (window.innerWidth <= 600) setXs(true);
    }, []);

    const handleSaveComment = async () => {
        if (commentRef.current !== null && worker) {
            await addWorkerComment(worker.id, commentRef.current.value);
            await getWorkerComments(worker.id.toString(), setComments);
        }
    };

    const handleDisable = async (reason: string) => {
        if (worker) {
            await disableWorker(worker.id.toString(), reason);
            getWorkerDetails(worker?.id.toString(), setWorker);
        }
        setOpenDisable(false);
    };

    const commentColumns: GridColDef[] = [
        {
            field: 'comment',
            sortable: false,
            flex: 1,
            renderHeader: () => (
                <Typography fontWeight={500} fontSize="12px">
                    <FormattedMessage id="comment" />
                </Typography>
            ),
            renderCell: (params) => <div>{params.row.comment}</div>
        },
        {
            field: 'created_at',
            sortable: false,
            flex: 1,
            valueFormatter: (params) => format(new Date(params.value as string), 'dd.MM.yyyy'),
            renderHeader: () => (
                <Typography fontWeight={500} fontSize="12px">
                    <FormattedMessage id="date" />
                </Typography>
            )
        },
        {
            field: 'id',
            sortable: false,
            width: 230,
            renderHeader: () => (
                <Typography fontWeight={500} fontSize="12px">
                    <FormattedMessage id="user-name" />
                </Typography>
            ),
            renderCell: (params) => (
                <div>
                    {params.row.user.first_name} {params.row.user.last_name}
                </div>
            )
        }
    ];

    const getPreferredWorkingHours = (availability: WorkerAvailability[]) => {
        const hours: { id: number; name: string; hour_interval: string }[] = [];
        availability.forEach((a) => hours.push(...a.hours));

        const ids = hours.map((h) => h.id);
        const filtered = hours.filter((hour, index) => !ids.includes(hour.id, index + 1));

        return filtered;
    };

    const handleUpdateStatus = async () => {
        if (id) {
            await updateWorkerStatus(id, Number(selectedStatus?.id));
            if (worker?.status! < 12) {
                updateWorkerBackupStatus(id, Number(worker?.status));
            }
            setOpenConfirmStatusChange(false);
            getWorkerDetails(id, setWorker);
        }
    };

    const handleRevertToOriginalStatus = async () => {
        if (id) {
            await updateWorkerStatus(id, Number(worker?.backup_status));
            getWorkerDetails(id, setWorker);
        }
    };

    return (
        <MainCard
            title={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '32px', marginTop: '8px', ml: 2 }}>
                    <Box>
                        <Typography variant="subtitle1" sx={{ marginBottom: '30px' }}>
                            {worker?.id ? `${worker?.user.first_name} ${worker?.user.last_name}` : 'Loading...'} &nbsp;
                        </Typography>
                    </Box>
                    <Box mr={1} sx={{ display: workerIdProp || worker?.deleted_at ? 'none' : 'block' }}>
                        <Action
                            marginBetween="0px"
                            // setEdit={(e) => {
                            //     console.log('will edit');
                            // }}
                            setDisable={(e) => setOpenDisable(true)}
                        />
                    </Box>
                </Box>
            }
            headerSX={{ padding: '8px !important' }}
        >
            {worker && (
                <>
                    <Grid container spacing={2} sx={{ justifyContent: 'space-between', mb: 3 }}>
                        <Grid item xs={12} md={8}>
                            <Box bgcolor={theme.palette.secondary.light} maxWidth="200px" p={1} borderRadius={2} display="inline-block">
                                <Typography color={theme.palette.secondary.dark}>
                                    <FormattedMessage id="approvals" />
                                </Typography>
                            </Box>
                            <Box mt={3}>
                                <ApprovalStepper
                                    status={worker.status}
                                    statuses={statuses}
                                    deactivateStatus={(statusId: any) => {
                                        handleRevertToOriginalStatus();
                                    }}
                                    onStatusClick={(clickedId) => {
                                        setSelectedStatus(statuses.find((s) => s.id === clickedId));
                                        setOpenConfirmStatusChange(true);
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box bgcolor={theme.palette.secondary.light} maxWidth="200px" p={1} borderRadius={2} display="inline-block">
                                <Typography color={theme.palette.secondary.dark}>
                                    <FormattedMessage id="rating" />
                                </Typography>
                            </Box>
                            <Box>
                                <Rating
                                    sx={{
                                        '& .MuiRating-iconFilled, .MuiRating-iconEmpty': {
                                            color: '#36BDAA'
                                        },
                                        fontSize: '27px',
                                        mt: 2
                                    }}
                                    defaultValue={Number(worker.rating)}
                                    precision={0.1}
                                    readOnly
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{ mt: 3, borderColor: theme.palette.secondary.light }} />
                    {/* ------------------------------ Personal Area ------------------------------ */}
                    <WorkerEdit worker={worker} setWorker={setWorker} workerIdProp={workerIdProp} />
                    {/* ------------------------------ Personal Area ------------------------------ */}
                    {/* <Grid container mt={3} sx={{ justifyContent: 'space-between' }}>
                        <Grid item xs={12} mb={3}>
                            <Box bgcolor={theme.palette.secondary.light} maxWidth="200px" p={1} borderRadius={2} display="inline-block">
                                <Typography color={theme.palette.secondary.dark}>
                                    <FormattedMessage id="personal-information" />
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <TextSection
                                label={<FormattedMessage id="full-name" />}
                                content={`${worker.user.first_name} ${worker.user.last_name}`}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} mt={xs ? 2 : 0}>
                            <TextSection label={<FormattedMessage id="personal-number" />} content={worker.personal_id} />
                        </Grid>
                        <Grid item xs={6} md={2} mt={xs ? 2 : 0} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <TextSection label={<FormattedMessage id="first-name" />} content={`${worker.user.first_name}`} />

                            <TextSection label={<FormattedMessage id="last-name" />} content={`${worker.user.last_name}`} />
                        </Grid>
                        <Grid item xs={6} md={2} mt={xs ? 2 : 0}>
                            <TextSection label={<FormattedMessage id="birth-date" />} content={worker.birth_date} />
                        </Grid>
                        <Grid item xs={6} md={2} mt={xs ? 2 : 0}>
                            <TextSection label={<FormattedMessage id="phone-number" />} content={worker.user.phone_number} />
                        </Grid>
                        <Grid item xs={6} md={2} mt={xs ? 2 : 0}>
                            <TextSection label={<FormattedMessage id="employment" />} content={worker.type.name} />
                        </Grid>
                        {worker.deleted_at && (
                            <Grid item xs={2}>
                                <TextSection
                                    label={<FormattedMessage id="worker-status" />}
                                    content={
                                        <Box bgcolor={theme.palette.error[100]} maxWidth="200px" p={1} borderRadius={2}>
                                            <Typography color={theme.palette.error.dark} fontSize="12px">
                                                <FormattedMessage id="worker-removed-from-system" />
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container sx={{ justifyContent: 'space-between', mt: 3 }}>
                        <Grid item xs={6} md={2} mt={xs ? 2 : 0}>
                            <TextSection
                                label={<FormattedMessage id="gender" />}
                                content={<FormattedMessage id={`gender.${gendersMap[Number(worker.gender)]}`} />}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} mt={xs ? 2 : 0}>
                            <TextSection label={<FormattedMessage id="role" />} content={worker.type.name} />
                        </Grid>
                        <Grid item xs={6} md={2} mt={xs ? 2 : 0}>
                            <TextSection
                                label={<FormattedMessage id="occupation" />}
                                content={worker.occupation ? worker.occupation.name : ''}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} mt={xs ? 2 : 0}>
                            <TextSection label={<FormattedMessage id="notes" />} content={worker.notes} />
                        </Grid>
                        <Grid item xs={worker.deleted_at ? 4 : 2}>
                            {worker.deleted_at && (
                                <TextSection
                                    label={
                                        <Typography color={theme.palette.error.dark} fontSize="12px" variant="body2">
                                            <FormattedMessage id="removal-reason" />
                                        </Typography>
                                    }
                                    content={worker.delete_reason}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Box mt={4}>
                        <Typography fontSize="12px" variant="subtitle1">
                            <FormattedMessage id="address" />
                        </Typography>
                        <Grid
                            container
                            sx={{ justifyContent: 'space-between', ...(xs && { width: '100% !important' }) }}
                            width={worker.deleted_at ? '50%' : '62%'}
                        >
                            <Grid item xs={12} md={4} mt={xs ? 2 : 0}>
                                <TextSection label={<FormattedMessage id="local-authority" />} content={worker?.address_authority?.name} />
                            </Grid>
                            <Grid item xs={12} md={4} mt={xs ? 2 : 0}>
                                <TextSection label={<FormattedMessage id="street" />} content={worker.address_street} />
                            </Grid>
                            <Grid item xs={12} md={4} mt={xs ? 2 : 0}>
                                <TextSection label={<FormattedMessage id="number" />} content={worker.address_home_number} />
                            </Grid>
                            {worker.details?.how_did_you_heard_about_us && (
                                <Grid item xs={4}>
                                    <TextSection
                                        label={<FormattedMessage id="how_did_you_heard_about_us" />}
                                        content={worker.details?.how_did_you_heard_about_us}
                                    />
                                </Grid>
                            )}
                            {worker.details?.referred_by && (
                                <Grid item xs={4}>
                                    <TextSection label={<FormattedMessage id="referred_by" />} content={worker.details?.referred_by} />
                                </Grid>
                            )}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'space-between', mt: 4, ...(xs && { width: '100% !important' }) }}
                            width={worker.deleted_at ? '50%' : '62%'}
                        >
                            <Grid item xs={6} md={4}>
                                <TextSection
                                    label={<FormattedMessage id="kosher-phone" />}
                                    content={
                                        worker.details?.kosher_phone ? intl.formatMessage({ id: 'yes' }) : intl.formatMessage({ id: 'no' })
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <TextSection
                                    label={<FormattedMessage id="receiving-sms" />}
                                    content={
                                        worker.details?.receiving_sms ? intl.formatMessage({ id: 'yes' }) : intl.formatMessage({ id: 'no' })
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider sx={{ mt: 3, borderColor: theme.palette.secondary.light }} />
                    <Grid container spacing={2} sx={{ justifyContent: 'space-between', mt: 1 }}>
                        <Grid item xs={12} md={6} pr={2}>
                            <Box bgcolor={theme.palette.secondary.light} maxWidth="200px" p={1} borderRadius={2} display="inline-block">
                                <Typography color={theme.palette.secondary.dark}>
                                    <FormattedMessage id="work-preferences" />
                                </Typography>
                            </Box>
                            <Grid
                                container
                                sx={{ justifyContent: 'space-between' }}
                                borderRadius={2}
                                p={1}
                                bgcolor={theme.palette.grey[100]}
                                mt={1}
                            >
                                <Grid item xs={12} md={4} mt={xs ? 2 : 0}>
                                    <TextSection
                                        label={<FormattedMessage id="preferred-working-area" />}
                                        content={
                                            <Box display="flex" sx={{ flexWrap: 'wrap' }}>
                                                {worker.regions.map((region) => (
                                                    <Typography fontSize="12px" key={`region-${region.id}`} mr="4px">
                                                        <CheckBox
                                                            color="secondary"
                                                            sx={{ width: '10.5px', height: '10.5px', mb: '-1px', mr: '2px' }}
                                                        />
                                                        {region.name}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} mt={xs ? 2 : 0}>
                                    <TextSection
                                        label={<FormattedMessage id="workplace-types" />}
                                        content={
                                            <Box display="flex" sx={{ flexWrap: 'wrap' }}>
                                                {worker.workplace_types.map((type) => (
                                                    <Typography fontSize="12px" key={`wt-${type.id}`} mr="4px">
                                                        <CheckBox
                                                            color="secondary"
                                                            sx={{ width: '10.5px', height: '10.5px', mb: '-1px', mr: '2px' }}
                                                        />
                                                        {type.name}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} mt={xs ? 2 : 0}>
                                    <TextSection
                                        label={<FormattedMessage id="preferred-working-days" />}
                                        content={
                                            <Box display="flex" sx={{ flexWrap: 'wrap' }}>
                                                {worker.availability
                                                    .filter((av) => av.hours.length > 0)
                                                    .map((av) => (
                                                        <Typography fontSize="12px" key={`day-${av.day}`} mr="4px">
                                                            <CheckBox
                                                                color="secondary"
                                                                sx={{ width: '10.5px', height: '10.5px', mb: '-1px', mr: '2px' }}
                                                            />
                                                            <FormattedMessage id={av.name} />
                                                        </Typography>
                                                    ))}
                                            </Box>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} mt={xs ? 2 : 0}>
                                    <TextSection
                                        label={<FormattedMessage id="details.preferred-local-authority" />}
                                        content={
                                            <Box display="flex" sx={{ flexWrap: 'wrap' }}>
                                                {worker.regions.map((region) =>
                                                    region.authorities.map((auth) => (
                                                        <Typography fontSize="12px" key={`auhtority-${auth.id}`}>
                                                            <CheckBox
                                                                color="secondary"
                                                                sx={{ width: '10.5px', height: '10.5px', mb: '-1px', mr: '2px' }}
                                                            />
                                                            {auth.name}
                                                        </Typography>
                                                    ))
                                                )}
                                            </Box>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} mt={xs ? 2 : 0}>
                                    <TextSection
                                        label={<FormattedMessage id="preferred-age-ranges" />}
                                        content={
                                            <Box display="flex" sx={{ flexWrap: 'wrap' }}>
                                                {worker.age_ranges.map((range) => (
                                                    <Typography fontSize="12px" key={`age-range-${range.id}`} mr="4px">
                                                        <CheckBox
                                                            color="secondary"
                                                            sx={{ width: '10.5px', height: '10.5px', mb: '-1px', mr: '2px' }}
                                                        />
                                                        {range.interval_label}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} mt={xs ? 2 : 0}>
                                    <TextSection
                                        label={<FormattedMessage id="preferred-working-hours" />}
                                        content={
                                            <Box display="flex" sx={{ flexWrap: 'wrap' }}>
                                                {getPreferredWorkingHours(worker.availability).map((hour) => (
                                                    <Typography fontSize="12px" key={`age-range-${hour.id}`} mr="4px">
                                                        <CheckBox
                                                            color="secondary"
                                                            sx={{ width: '10.5px', height: '10.5px', mb: '-1px', mr: '2px' }}
                                                        />
                                                        {`${intl.formatMessage({ id: toIntl(hour.name) })} ${hour.hour_interval}`}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} mt={xs ? 2 : 0}>
                                    <TextSection
                                        label={<FormattedMessage id="languages" />}
                                        content={worker.languages.map((l) => l.name).join(', ')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} mt={xs ? 2 : 0}>
                                    <TextSection
                                        label={<FormattedMessage id="weekend-preference" />}
                                        content={
                                            <Typography fontSize="12px">
                                                <FormattedMessage id="rest_day" />
                                                :&nbsp;{worker.details?.rest_day || '-'}
                                            </Typography>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box bgcolor={theme.palette.secondary.light} maxWidth="400px" p={1} borderRadius={2} display="inline-block">
                                <Typography color={theme.palette.secondary.dark}>
                                    <FormattedMessage id="education-and-working-experience" />
                                </Typography>
                            </Box>
                            <Box mt={1}>
                                <Grid container sx={{ justifyContent: 'space-between' }}>
                                    {worker.details?.highschool_graduated ? (
                                        <Grid item xs={12} md={6} mt={xs ? 2 : 0}>
                                            <Typography fontSize="12px" variant="subtitle1">
                                                <FormattedMessage id="education" />
                                            </Typography>
                                            <Typography fontSize="12px" variant="subtitle1" mt={1}>
                                                <FormattedMessage id="graduated_highschool" />
                                            </Typography>
                                            <Typography fontSize="12px" component="span" variant="body2">
                                                {worker.details.highschool_graduated
                                                    ? intl.formatMessage({ id: 'yes' })
                                                    : intl.formatMessage({ id: 'no' })}
                                            </Typography>
                                            <Typography fontSize="12px" variant="subtitle1" mt={1}>
                                                <FormattedMessage id="graduated_in_israel" />
                                            </Typography>
                                            <Typography fontSize="12px" component="span" variant="body2">
                                                {worker.details.graduated_in_israel
                                                    ? intl.formatMessage({ id: 'yes' })
                                                    : intl.formatMessage({ id: 'no' })}
                                            </Typography>
                                            <Typography fontSize="12px" variant="subtitle1" mt={1}>
                                                <FormattedMessage id="highschool-name" />
                                            </Typography>
                                            <Typography fontSize="12px" component="span" variant="body2">
                                                {worker.details.highschool_name}
                                            </Typography>

                                            <Typography fontSize="12px" variant="subtitle1" mt={1}>
                                                <FormattedMessage id="highschool-city" />
                                            </Typography>
                                            <Typography fontSize="12px" component="span" variant="body2">
                                                {worker.details.highschool_city}
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                    {worker.details?.previous_experience ? (
                                        <Grid item xs={12} md={6} mt={xs ? 2 : 0}>
                                            <Typography fontSize="12px" variant="subtitle1">
                                                <FormattedMessage id="working-experience" />
                                            </Typography>
                                            <Typography fontSize="12px" component="span" variant="body2">
                                                {worker.details.previous_experience
                                                    ? intl.formatMessage({ id: 'yes' })
                                                    : intl.formatMessage({ id: 'no' })}
                                            </Typography>
                                            <Typography fontSize="12px" variant="subtitle1" mt={1}>
                                                <FormattedMessage id="previous_experience_with_children" />
                                            </Typography>
                                            <Typography fontSize="12px" variant="body2">
                                                {worker.details.previous_experience_story
                                                    ? intl.formatMessage({ id: 'yes' })
                                                    : intl.formatMessage({ id: 'no' })}
                                            </Typography>
                                            <Typography fontSize="12px" variant="subtitle1" mt={1}>
                                                <FormattedMessage id="previous_experience_with_children_desc" />
                                            </Typography>
                                            <Typography fontSize="12px" component="span" variant="body2">
                                                {worker.details.previous_experience_story}
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid> */}
                    <Divider sx={{ mt: 3, borderColor: theme.palette.secondary.light }} />
                    {!workerIdProp && (
                        <Grid container spacing={2} sx={{ justifyContent: 'space-between', mt: 2 }}>
                            <Grid item xs={12} md={6}>
                                <Box bgcolor={theme.palette.secondary.light} maxWidth="200px" p={1} borderRadius={2} display="inline-block">
                                    <Typography color={theme.palette.secondary.dark}>
                                        <FormattedMessage id="job-history" />
                                    </Typography>
                                </Box>
                                <WorkerHistory jobHistory={worker.job_history} />
                            </Grid>
                        </Grid>
                    )}
                    <Divider sx={{ mt: 3, borderColor: theme.palette.secondary.light }} />
                    <Grid container sx={{ justifyContent: 'space-between', mt: 3 }}>
                        <Grid item xs={12} md={7.5}>
                            <Box bgcolor={theme.palette.secondary.light} maxWidth="200px" p={1} borderRadius={2} display="inline-block">
                                <Typography color={theme.palette.secondary.dark}>
                                    <FormattedMessage id="comments" />
                                </Typography>
                            </Box>
                            <Grid container spacing={2} alignItems="flex-start" mt={1}>
                                <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                                    <Avatar sx={{ mt: 0.75, fontSize: '12px', color: theme.palette.grey[900] }} alt="User 1">
                                        AK
                                    </Avatar>
                                </Grid>
                                {/* here to add required comment */}
                                <Grid item xs zeroMinWidth>
                                    <TextField fullWidth name="name" label="Write a comment..." inputRef={commentRef} />
                                </Grid>
                                <Grid item>
                                    <AnimateButton>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSaveComment}
                                            sx={{ mt: 0.5 }}
                                        >
                                            <FormattedMessage id="add" />
                                        </Button>
                                    </AnimateButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <SubCard
                                        sx={{ borderColor: theme.palette.secondary.light }}
                                        contentSX={{ padding: '5px 10px 10px 10px !important', maxHeight: '475px', overflow: 'auto' }}
                                    >
                                        <GlobalTable
                                            borderColor={theme.palette.secondary.light}
                                            rows={comments}
                                            columns={commentColumns}
                                            checkbox={false}
                                            heightRow={80}
                                        />
                                    </SubCard>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} mt={xs ? 2 : 0}>
                            <Box bgcolor={theme.palette.secondary.light} p={1} borderRadius={2} display="inline-block">
                                <Typography color={theme.palette.secondary.dark}>
                                    <FormattedMessage id="files" />
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" mt={3}>
                                <WorkerFile worker={worker} type="optional" />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ marginTop: '50px', display: workerIdProp ? 'none' : 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => navigate(`/workers`)} variant="outlined" startIcon={<ArrowBack />}>
                            <FormattedMessage id="back" />
                        </Button>
                    </Box>
                </>
            )}
            <DisableWithReasonDialog
                open={openDisable}
                onClose={() => setOpenDisable(false)}
                onDisable={handleDisable}
                coloredText=""
                title1="Are you sure you want to update the employee status?"
                title2=""
                labelButton="confirm"
            />
            <GlobalDisableDialog
                open={openConfirmStatusChange}
                onClose={() => setOpenConfirmStatusChange(false)}
                onDisable={handleUpdateStatus}
                coloredText=" "
                title1="Are you sure you want to update the employee status?"
                title2=" "
                labelButton="confirm"
            />
        </MainCard>
    );
};

export default WorkerDetails;
