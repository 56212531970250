import { UserGroup, UserGroupDetails } from 'types/user-group';
// import axiosServices from 'utils/axios';
import axios from 'axios';
import { PaginationInfo, RequestParams } from 'types/paginationInfo';

export const getUserGroups = async (
    setUserGroups: (val: UserGroup[]) => void,
    requestParams: RequestParams,
    setPaginationInfo: (val: PaginationInfo) => void
) => {
    try {
        const params: any = {
            page: requestParams.page,
            per_page: requestParams.per_page,
            sort: `${requestParams.sort[0].sort === 'desc' ? '-' : ''}${requestParams.sort[0].field}`
        };
        requestParams.filters?.forEach((filter) => {
            params[`filter[${filter.name}]`] = filter.value;
        });
        const response = await axios.get('/user_groups', { params });
        const userGroupData: UserGroup[] = [];
        response.data.user_groups.data.forEach((item: any) => {
            userGroupData.push({ id: item.id, name: item.name, total_users: item.total_users, created_at: item.created_at });
        });

        let availableFilters: any = [];
        response.data.user_groups.filters.forEach((filter: any) => {
            availableFilters = [...availableFilters, ...filter.items.options];
        });
        setPaginationInfo({
            total: response.data.user_groups.total,
            last_page: response.data.user_groups.last_page,
            per_page: response.data.user_groups.per_page,
            sortable_fields: availableFilters.map((a: any) => a.name),
            available_filters: response.data.user_groups.filters
        });
        setUserGroups(userGroupData);
    } catch (err) {
        setUserGroups([]);
        console.error(`error in getting user groups ${err}`);
    }
};

export const addUserGroup = async (
    name: string,
    permissionsMap: Map<string, number>,
    users: string[],
    setSuccess: (val: boolean) => void
) => {
    const body = {
        user_group: { name },
        added_users: users,
        permissions: Object.assign({}, ...Array.from(permissionsMap.entries()).map(([k, v]) => ({ [k]: v })))
    };
    try {
        await axios.post('/user_groups', body);
        setSuccess(true);
    } catch (err) {
        console.error(`error in add user groups ${err}`);
        setSuccess(false);
    }
};

export const editUserGroup = async (
    id: string,
    name: string,
    permissionsMap: Map<string, number>,
    users: string[],
    deletedUsers: string[] | null,
    setSuccess: (val: boolean) => void
) => {
    const body = {
        user_group: { name },
        added_users: users,
        removed_users: deletedUsers,
        permissions: Object.assign({}, ...Array.from(permissionsMap.entries()).map(([k, v]) => ({ [k]: v })))
    };

    try {
        await axios.put(`/user_groups/${id}`, body);
        setSuccess(true);
    } catch (err) {
        console.error(`error in edit user groups ${err}`);
        setSuccess(false);
    }
};

export const getUserGroup = async (id: string, setUserGroup: (value: UserGroupDetails) => void) => {
    try {
        const response = await axios.get(`/user_groups/${id}`);
        setUserGroup(response.data.user_group);
    } catch (err) {
        console.error(`error in getting user group ${err}`);
    }
};

export const disableUserGroup = async (id: string) => {
    try {
        await axios.delete(`/user_groups/${id}`);
    } catch (err) {
        console.error(`error in disable user group ${err}`);
    }
};

export const getUserGroupsList = async (setUserGroupsList: (object: { [key: string]: string }) => void) => {
    try {
        const response = await axios.get('/user_groups/list');
        setUserGroupsList(response.data.user_groups);
    } catch (err) {
        console.error(`Error getting user groups list ${err}`);
    }
};

export default getUserGroups;
